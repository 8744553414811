.block-frontpage-usps {
  background: #f5f5f5;

  .block__content {
    padding: 25px 0;

    @include breakpoint($tab) {
      padding: 0;
    }
  }

  .container {
    text-align: center;
    font-size: 0;
  }

  .icon {
    font-size: 14px;
  }

  .usp:before {
    color: white;
    background: $brand-primary;
    border-radius: 50%;
    width: 46px;
    text-align: center;
    height: 46px;
    line-height: 46px;
    @extend %font-icon;
    display: block;
    font-size: 28px;
    float: left;
    position: relative;
    top: 33px;
    transform: translateY(-50%);
    margin: 0 auto 20px auto;

    @include breakpoint($tab) {
      margin: 0 auto 15px auto;
      float: none;
      position: static;
      transform: none;
    }
  }
  .usp-points:before {
    @extend %font-icon-basket;
    padding-top: 1px;
    content: '';
    // background-color: transparent;
    background-image: url('/sites/all/themes/ns/images/tclub.svg');
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .usp-transport:before {
    @extend %font-icon-transport;
  }

  .usp-installments:before {
    @extend %font-icon-calculator;
    padding-left: 1px;
  }

  .usp-delivery:before {
    @extend %font-icon-delivery;
  }

  .usp-express-delivery:before {
    @extend %font-icon-express;
    padding-left: 1px;
  }

  a.usp {
    @include clearfix;
    color: $text-color;
    font-weight: normal;
    margin: 0 auto;
    width: 300px;
    display: block;
    position: relative;
    text-decoration: none;

    @include breakpoint($tab) {
      padding-top: 40px;
      width: 190px;
      height: 190px;
      display: inline-block;
      vertical-align: top;
      margin: 0 22px;
    }

    &.usp-points {
      @include breakpoint($tab) {
        width: 140px;

        @include breakpoint($desk) {
          width: 190px;
        }
      }

      .usp-text .bold:after {
        @extend %font-icon;
        @extend %font-icon-arrow-right-2;
        font-size: 11.5px;
        vertical-align: top;
        line-height: 21.5px;
        margin-left: 7px;
      }
    }

    .usp-text {
      display: block;
      position: relative;
      top: 33px;
      transform: translateY(-50%);

      @include breakpoint($tab) {
        position: static;
        transform: none;
      }

      span {
        display: block;
        margin: 0 auto;
        text-align: left;
        padding-left: 63px;

        @include breakpoint($tab) {
          text-align: center;
          padding: 0;
        }
      }
    }

    .usp-hover {
      transform: translate(-999999px, 100%);
      opacity: 0;
      transition: opacity 0.2s ease 0.1s, transform 0s ease 0.31s;
      @include single-box-shadow(0, 30px, 70px, 0, rgba(0, 1, 1, 0.35));
      position: absolute;
      left: 50%;
      bottom: 35px;
      font-size: 14px;
      letter-spacing: -0.03em;
      line-height: 20px;
      background: $brand-primary;
      width: 240px;
      padding: 0 28px 25px 28px;
      color: white;
      z-index: 10;

      &:before {
        content: '';
        display: block;
        width: 0px;
        height: 0px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -10px);
        border-color: transparent transparent $brand-primary;
        border-width: 0 10px 10px;
        border-style: solid;
      }

      .bold {
        color: white;
        font-size: 14px;
      }

      .text {
        display: block;
        position: relative;
        padding-top: 25px;

      }

      .more {
        display: block;
        font-weight: 500;
        letter-spacing: 0.1em;

        &:after {
          @extend %font-icon;
          @extend %font-icon-arrow-right-2;
          padding-left: 12px;
          margin-top: 25px;
        }
      }
    }

    &:hover {
      text-decoration: none;

      .usp-hover {
        @include breakpoint($desk) {
          transform: translate(-50%, 100%);
          opacity: 1;
          transition: opacity 0.2s ease 0.1s, transform 0s ease 0s;
        }
      }
    }
  }

  .bold {
    color: $brand-primary;
    font-size: 16px;
    font-weight: 500;
  }

  .node-type-product & {
    background: none;

    .container {
      padding: 0 !important;
      width: auto !important;
    }

    a.usp {
      .usp-hover {
        bottom: 15px;

        &:before {
          top: auto;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 10px);
          border-color: $brand-primary transparent transparent;
          border-width: 10px 10px 0;
        }

        .bold {
          color: white;
          font-size: 14px;
        }

        .text {
          display: block;
          position: relative;
          padding-top: 25px;
        }

        .more {
          display: block;
          font-weight: 500;
          letter-spacing: 0.1em;

          &:after {
            @extend %font-icon;

            @extend %font-icon-arrow-right-2;

            padding-left: 12px;
            margin-top: 25px;
          }
        }
      }

      &:hover {
        .usp-hover {
          transform: translate(-50%, -100%);
        }
      }
    }
  }
}
