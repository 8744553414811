.node--webform {
  .node__content {
    background-color: $well-bg;
    padding: 30px;
  }
}

select {
  @include opacity(0.001);
}

div.selector {
  display: inline-block;
  height: 100%!important;
  padding: 0 1em;
  position: relative;
  width: 100%!important;
  z-index: 0;
  white-space: nowrap;


  span {
    display: block;
    text-align: left;
    position: absolute;
    top: 50%;
    width: 100%!important;
    margin-left: -2em;
    padding-left: 2em;
    @include transform(translateY(-50%));

    &:after {
      @include vertical-align;
      right: 0;
      display: block;
      width: 15px;
      height: 10px;
      padding-left: 9px;
      z-index: 99;
      @extend %font-icon;
      @extend %font-icon-arrow-down-thick;
      color: $brand-primary;
      font-size: 10px;
    }
  }

  select {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @include opacity(0);
    // visibility: hidden;
    border: none;
    -webkit-appearance: none;
    -moz-appearence: none;
    cursor: pointer;
    display: block;
    z-index: 100;
    font-size: 16px;
  }

  &.disabled {
    width: 100% !important;
    span {
      padding-left: 0.8em;
      font-size: $font-size-small;
      font-weight: normal;
      height: 22px;
      margin-top: 10px;
      color: #aaa;
      border-bottom: 1px solid #aaa;
      cursor: not-allowed;
    }

    &:after {
      display: none;
    }

    select {
      border: none;
      @include opacity(0);
      visibility: hidden;
    }
  }

  .floating-label & {
    span {
      top: 0;
      left: 0;
      @include transform(none);
      line-height: 1.2em;
      padding: 1.5em 1em 0.5em 3em;

      &:after {
        @include transform(none);
        padding-left: 2em;
      }
    }

    & > label {
      font-weight: 500;
    }
  }
}

.custom-multicountry-selector-form {
  div.selector {
    padding-top: 4px;
    margin-top: -4px;
    width: 150px !important;

    @include breakpoint($tab) {
      width: 200px !important;
    }
  }
}

.form-type-radio,
.form-type-checkbox {
  .page-checkout & {
    position: relative;
  }

  .form-radio,
  .form-checkbox {
    opacity: 0;
    position: absolute;
    left: -9999999px;

    .page-checkout & {
      width: 100%;
      height: 100%;
      margin: 0;
      margin-left: -15px;
    }
  }

  .form-radio, .form-checkbox, label.option {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }

  label.option {
    position: relative;
  }

  label.option:before {
    content: '';
    background: #fff;
    border: 3px solid $default-border-color;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
    border-radius: 50%;
    text-decoration: none;

    .page-checkout-billing & {
      margin-top: -3px;
    }
  }

  .form-radio:hover ~ label.option,
  .form-radio:hover ~ div ~ label.option,
  .form-checkbox:hover ~ label.option,
  .form-checkbox:hover ~ div ~ label.option {
    text-decoration: underline;

    // &:before {
    //   border-color: #bbb;
    // }
  }

  .form-radio:checked ~ label.option,
  .form-radio:checked ~ div ~ label.option,
  .form-checkbox:checked ~ label.option,
  .form-checkbox:checked ~ div ~ label.option {
    // text-decoration: none;

    &:before{
      @extend %font-icon;
      @extend %font-icon-tick;
      color: $brand-primary;
      border-color: $brand-primary;
      line-height: 7px;
      font-size: 10px;
      text-decoration: none;
    }
  }

  // .form-checkbox.valid ~ label.option,
  // .form-checkbox.valid ~ div ~ label.option,
  // .form-checkbox.valid ~ label ~ label.option {
  //   // text-decoration: none;

  //   &:before{
  //     @extend %font-icon;
  //     @extend %font-icon-tick;
  //     color: $brand-primary;
  //     border-color: $brand-primary;
  //     line-height: 7px;
  //     font-size: 10px;
  //     text-decoration: none;
  //   }
  // }

  .form-radio:focus ~ label.option,
  .form-checkbox ~ label.option {
    outline: 1px solid #ddd; /* focus style */

    .page-checkout & {
      outline: none;
    }
  }
}

.webform-component-textarea {
  textarea {
    border: 1px solid $default-border-color;
    border-radius: 0px;
    margin-bottom: 10px;
    padding: 12px;
    outline: none;

    &:focus {
      border: 1px solid $brand-primary;
    }
  }
}

input {
  margin: 0;
  border: 1px solid $default-border-color;
  border-radius: 0px;
  line-height: 44px;
  margin-bottom: 11px;
  padding-left: 12px;
  color: #a8a8a8;
  width: 100%;
  outline: none;

  &:focus {
    border: 1px solid $brand-primary;
  }

  .block--feature-blog-ask-the-experts-block &.error {
    border: 1px solid red;
  }
}

.form-type-checkbox {
  label.option:before {
    border-radius: 5px;
  }
}

.filled input {
  color: $text-color;
}

.form-item.form-item-quantity,
.views-field-edit-quantity .form-item {
  display: inline-block;
  position: relative;
  border: 1px solid $default-border-color;
  border-radius: 2px;
  font-size: $font-size-small;
  overflow: hidden;

  @include breakpoint($tab) {
    font-size: $font-size-base;
  }

  label.error {
    display: none!important;
  }

  input {
    border-radius: 0;
    border: none;
    border-right: 1px solid $default-border-color;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
    width: 40px;
    margin-right: 32px;
    line-height: 31px;
    height: 31px;

    @include breakpoint($tab) {
      width: 50px;
      margin-right: 38px;
      line-height: 37px;
      height: 37px;
    }
  }

  span.field-suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -23px;
    @include breakpoint($tab) {
      right: -35px;
    }

    a:hover {
      color: darken(#666666, 15%);
      text-decoration: none;
    }
  }

  span.commerce-quantity-plusminus-link {
    position: absolute;
    right: 0;
    margin: 0;
    padding: 0;
    background: #fff;

    &.commerce-quantity-plusminus-link-increase {
      top: 0;
      border-bottom: 1px solid $default-border-color;

      a.button {
        &:before {
          @extend %font-icon-arrow-up-thick;
        }
      }
    }
    &.commerce-quantity-plusminus-link-decrease {
      bottom: 0;

      a.button {
        &:before {
          @extend %font-icon-arrow-down-thick;
        }
      }
    }


    a.button {
      display: block;
      border-radius: 0;
      color: $text-color;
      padding: 0;
      margin: 0;
      font-size: 0;
      text-decoration: none;
      width: 32px;
      height: 15px;

      @include breakpoint($tab) {
        width: 38px;
        height: 18px;
      }

      &:before {
        @extend %font-icon;
        font-size: 10px;
        font-weight: normal;
        color: #666666;
        text-align: center;
        padding-top: 4px;

        @include breakpoint($tab) {
          padding-top: 5px;
        }
      }

      &:hover {
        color: $text-color;
        border-radius: 0;
        text-decoration: none;
      }
    }
  }
}

button.delete-line-item {
  padding: 0;
  margin: 0;
  border: none;
  width: 20px;
  height: 20px;
  font-size: 0;
  background: none;
  transition: color 0.2s;

  &:before {
    @extend %font-icon;
    @extend %font-icon-delete;
    font-size: 15px;
    margin-left: 10px;

    @include breakpoint($tab) {
      font-size: 20px;
      margin-left: 0;
    }
  }
  &:hover {
    color: darken(#666666, 15%);
  }
  &:focus {
    outline: none;
  }
}

label {
  font-weight: 500;
  color: $text-color;

  &.option {
    color: inherit;
  }
}

label.error {
  color: red;
  padding-bottom: 10px;
}

.floating-label-container {
  height: 3.6em;
  line-height: 3.6em;
  border: 1px solid $default-border-color;
  border-radius: 3px;
  padding-left: 1em;
}

.form-type-select {
  @extend .floating-label-container;
  padding-left: 0;
}

.form-type-textfield {
  margin-bottom: 10px;
}

%btn-back {
  .page-checkout-complete & {
    width: 100%;
    letter-spacing: 1px;
    padding: 0 10px;

    @include breakpoint($tab) {
      letter-spacing: 2px;
      padding: 0 18px;
      width: 325px;
    }
  }
}

.form-actions {
  height: 65px;

  button.webform-submit {
    @extend %btn-submit;
    font-weight: 500;
    float: right;
  }

  button {
    .page-user-password .l-main & {
      @extend %btn-lock;
      max-width: none;
    }
  }
}

// Removes outline on every add to cart form on click
form.commerce-add-to-cart {
  .form-submit {
    outline: 0;
  }
}

.progress-disabled {
  float: none;
  display: inline;
}

.floating-label > label {
  .page-checkout-shipping & {
    color: #cccccc;
  }
}

@include placeholder {
  color: #adadad;
}


.view-checkout-shopping-cart-form {
  .views-field-edit-quantity {
    .field-suffix {
      display: none;
    }
  }
}
