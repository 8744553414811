// Messages
.l-messages,
.l-messages-checkout {
  margin: 50px auto 0;

  div.messages {
    margin-bottom: 15px;
    background-color: rgba(79, 138, 16, 0.8);
    color: #fff;
    padding: 10px;
    text-align: center;
    line-height: 30px;

    &.messages--error {
      background-color: rgba(237, 27, 36, 0.8);
    }

    > a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: lightgray;
      }
    }

    button {
      color: #fff;
      opacity: 0.8;
      line-height: 30px;
      text-shadow: none;

      &:hover {
        opacity: 1;
        color: #fff;
      }
    }

    .krumo-root {
      text-align: left;
      color: black;
    }

    li {
      list-style: none;
    }
  }
}

.messages--status {
  .page-checkout-shipping & {
    display: none;
  }
}

// Cookie Popup.
.cookie-consent {
  display: block;
  z-index: 1000;
  background: $brand-primary;
  position: fixed;
  padding: 8px 32px 0;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.11px;
  width: 100%;
  bottom: 0;
  color: #fff;

  p {
    color: #fff;

    max-height: 36px;
    overflow: hidden;
    max-width: 253px;
    white-space: nowrap;

    @include breakpoint($desk) {
      max-height: auto;
      max-width: auto;
      max-height: initial;
      max-width: initial;
      white-space: normal;
    }
  }

  @include breakpoint($tab) {
    right: 30px;
    bottom: 30px;
    width: 440px;
  }

  body.drupal-visitor-cookie-consent & {
    display: none;
  }


  .cookie-consent-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin: 20px 0;
  }

  .cookie-consent-close-x {
    font-size: 12px;
    position: absolute;
    top: 16px;
    font-weight: 900;
    right: 20px;
    cursor: pointer;

    &:after {
      @extend %font-icon;
      @extend %font-icon-close;
    }
  }

  h2,
  .h2 {
    color: white;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 14px;
    letter-spacing: -0.4px;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1.4px;
    display: block;
    margin: 0;
    text-align: center;

    &:after {
      @extend %font-icon;
      @extend %font-icon-arrow-right-2;
      font-size: 13px;
      margin-left: 4px;
      display: none;
    }

    @include breakpoint(500px) {
      display: inline-block;
      text-align: left;

      &:after {
        display: inline-block;
      }
    }
  }

  button {
    border: 2px solid white;
    background: transparent;
    font-size: 12px;
    font-weight: bold;
    padding: 11px;
    letter-spacing: 1.3px;
    display: block;
    margin: 0;
    outline: none;

    &:before {
      @extend %font-icon;
      @extend %font-icon-tick-availability;
      font-size: 18px;
      position: relative;
      left: -11px;
      top: 3px;
      display: none;
    }

    @include breakpoint(500px) {
      display: inline-block;
      padding: 13px 30px 13px 31px;

      &:before {
        display: inline-block;
      }
    }
  }
}
