.container {
  padding-left: 10px;
  padding-right: 10px;

  @include breakpoint($tab) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// Main region
.l-main {
  @extend .container;
  clear: both;
  @include pie-clearfix;

  .front &,
  .page-stores &,
  .section-brand & {
    margin: 0;
    width: 100%;
    padding: 0;
  }

  .page-cart & {
    padding-top: 50px;
    padding-bottom: 90px;
  }

  .page-checkout & {
    padding-top: 50px;
  }

  .page-user & {
    padding-bottom: 60px;
  }
}

// Content region
.l-content {
  .page-blog &,
  .page-events &,
  .page-ask-the-experts &,
  .node-type-article &,
  .node-type-video-post &,
  .node-type-events &,
  .node-type-ask-the-experts &,
  .page-taxonomy-term & {
    display: inline-block;
    width: 100%;

    @include breakpoint($desk) {
      width: 570px;
    }
    @include breakpoint($wide) {
      width: 750px;
    }
  }

  .node-type-page &,
  .node-type-webform & {
    margin-bottom: 50px;
    width: 100%;

    @include breakpoint($desk) {
      display: inline-block;
      width: 640px;

      @include breakpoint($wide) {
        width: 750px;
      }
    }
  }
  .front & {
    display: block;
    margin: 0 auto;
    width: auto;
  }
}

#sticky-header > .container {
  transform: translateY(0);
  overflow: visible;
  padding-left: 0px;
  padding-right: 0px;
  @include breakpoint(306px) {
    padding-left: 3px;
    padding-right: 3px;
    @include breakpoint(310px) {
      padding-left: 5px;
      padding-right: 5px;
      @include breakpoint(320px) {
        padding-left: 10px;
        padding-right: 10px;
        @include breakpoint($tab) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}

.container.promotion-skin-container {
  overflow: visible;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
  opacity: 1;
  transition: opacity 0.2s ease 0.4s;
  .mobile-menu-active &,
  .mobile-filter-active &,
  .findastic-overlay-search--open & {
    opacity: 0;
    transition: opacity 0.2s ease 0s;
  }
}

#left-skin,
#right-skin {
  display: inline-block;
  position: fixed;
  z-index: 999999;
  top: 50%;
  transform: translateY(-50%);

  .skin {
    height: 100%;
    backface-visibility: hidden;

    img {
      height: auto;
      width: auto;
      max-width: none;
    }
  }
}

#right-skin {
  left: 100%;
}

#left-skin {
  right: 100%;
}
