@charset 'utf-8';
/* See https://sass-guidelin.es/#syntax--formatting
 * for the charset declaration
*/

/* For file architecture,
 * we roughly follow the one in
 * https://sass-guidelin.es/#architecture
*/

// Import variables before contrib so the modules can use our values
// Change default content at the beginning of a project.
@import "abstractions/variables.scss";

// Import contrib
@import "compass";
@import "breakpoint";
// @import "bootstrap";
// @import "font-awesome";

// Import abstractions.
// Change default content at the beginning of a project.
// @import "abstractions/functions.scss";
@import "abstractions/mixins.scss";
@import "abstractions/icons.scss";
@import "abstractions/placeholders.scss";

// Import contrib overrides
// @import "vendor_overrides/_bootstrap_overrides.scss";

// Import base styling
@import "base/reset.scss";
@import "base/typography.scss";

@import 'layout/footer.scss';
@import 'layout/grid.scss';
@import 'layout/header.scss';
@import 'layout/messages.scss';
@import 'layout/mega_menu.scss';
@import 'layout/navigation.scss';
@import 'layout/forms.scss';
@import 'layout/findastic.scss';

@import "components/breadcrumb.scss";
@import "components/buttons.scss";
@import "components/addtocart.scss";
@import "components/shipping_goal.scss";
@import "components/tabs.scss";
@import "components/throbber.scss";
@import "components/wishlist.scss";
@import "components/mm-farmakop.scss";
@import "components/ckeditor.scss";
@import "components/facets.scss";
@import "components/product_teaser.scss";
