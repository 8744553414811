%mobile-menu-user-actions {
  display: block;
  width: 96%;
  padding: 10px 10px 10px 0;
  margin-left: 10px;
  height: 50px;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: $default-border-color;
}

// Navigation
.l-region--navigation {
  position: relative;

  @include breakpoint($desk) {
    display: block;
  }


  .l-mobile-menu & {
    display: block;
  }
}

body.mobile-menu-active {
  height: 100%;
  overflow: hidden;
  position: relative;

  .l-pusher {
    .l-container {
      position: static;
    }
  }
}

.l-pusher {
  height: 100%;

  // overflow: hidden;
  overflow: visible;
  width: 100%;

  .l-container {
    height: 100%;
    z-index: 0;
    transition: transform 0.5s;

    // -----------------------------------------
    // In the HTML namespace, any value other than none for the transform
    // results in the creation of both a stacking context and a containing block.
    // The object acts as a containing block for fixed positioned descendants.
    //
    transform: none;

    //  ----------------------------------------

    .main-nav {
      transform: none !important;
      z-index: 12;
    }

    body.mobile-filter-active & {
      @include transform(translateX(-260px));

      @include breakpoint($desk) {
        @include transform(translateX(0));

        .l-filter-menu {
          display: none;
        }
      }


      .l-filter-menu {
        display: block;
      }
    }

    .l-filter-menu {
      display: none;
      position: absolute;
      z-index: 499;
      top: 0;
      right: -260px;
      width: 260px;
      height: 100%;
      background: #fff;
      overflow: auto;
      box-shadow: none;

      @include breakpoint($desk) {
        display: none;
      }


      .mobile-filter-close {
        &:before {
          @extend %font-icon;

          @extend %font-icon-close;

          color: $brand-secondary;
          font-size: 15px;
          font-weight: bold;
          padding: 20px 20px 0;
          text-decoration: none;
        }
      }

      // .block--views-section-block {
      //   display: block;
      //   margin-top: 35px;
      // }

    }

    .l-mobile-overlay {
      @include opacity(0);

      position: absolute;
      top: 0;
      left: 0;
      width: 0px;
      height: 0px;
      z-index: 498;
      background: rgba(0, 0, 0, 0.5);
      -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
      -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
      transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;

      body.mobile-filter-active & {
        @include opacity(1);

        width: 100%;
        height: 100%;
        box-shadow: -30px 0 60px -30px rgba(0, 0, 0, 0.5) inset;
        -webkit-transition: opacity 0.3s;
        -moz-transition: opacity 0.3s;
        transition: opacity 0.3s;
      }

      @include breakpoint($desk) {
        display: none;
      }
    }

    .l-region--navigation {
      @include breakpoint(0 $desk) {
        background-color: #fff;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 320px;
        transform: translateX(100%);
        transition: transform 0.2s ease;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        backface-visibility: hidden;

        body.mobile-menu-active & {
          // Use transform: none instead of transform: translateX(0)
          // to avoid creating containing block that ruins fixed positioned sub elements.
          transform: none;
        }
      }
    }

    #block-system-main-menu {
      @include breakpoint($desk) {
        position: relative;
        z-index: 497;
      }
      ul.menu {
        @include breakpoint(0 $desk) {
          padding: 0 10px;
        }

        ul.menu {
          @include breakpoint(0 $desk) {
            position: fixed;
            background: #fff;
            top: 0;
            bottom: 0;
            right: 0;
            width: 320px;
            transform: translateX(100%);
            transition: transform 0.2s ease;
            z-index: 3;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            backface-visibility: hidden;
          }
        }

        li {
          @include breakpoint(0 $desk) {
            border-bottom: 1px solid #d9d9d9;
            overflow: hidden;
            color: inherit;
            line-height: 20px;
            display: block;
            margin: 0;
            padding: 15px 0;
            white-space: normal;
          }

          .level-1-wrapper {
            @include breakpoint(0 $desk) {
              min-height: auto !important;
            }
          }

          h2 {
            display: none;

            @include breakpoint($desk) {
              display: block;
            }
          }

          @include breakpoint(0 $desk) {
            a {
              color: #333;
              font-weight: 500;
              font-size: 14px;
              padding-right: 54px;
              display: block;

              &.subopen {
                position: absolute;
                width: 54px;
                box-shadow: -5px 0 0 -4px #eee;
                padding: 0;
                right: 0;
                top: -15px;
                bottom: -15px;
                z-index: 2;

                &:before,
                &:after {
                  content: '';
                  display: block;
                  background-color: $brand-primary;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                }

                &:before {
                  width: 10px;
                  height: 2px;
                  margin-left: -5px;
                  margin-top: -1px;
                }

                &:after {
                  width: 2px;
                  height: 10px;
                  margin-left: -1px;
                  margin-top: -5px;
                }

                @include breakpoint($desk) {
                  display: none;
                }
              }
            }
          }

          .menu-item-title {
            display: block;
            position: relative;
          }

          &.parent-link {
            position: relative;
            height: 82px;
            padding: 5px 75px 5px 10px !important;
            background: #f5f5f5;
            border-bottom: none;
            margin-left: -10px;
            margin-right: -10px;

            a {
              position: absolute;
              top: 50%;
              left: 10px;
              transform: translateY(-50%);
              padding: 0 75px 0 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 100%;

              &.subclose {
                position: absolute;
                display: inline-block;
                width: 60px;
                top: 0;
                bottom: 0;
                right: 0;
                left: auto;
                text-align: center;
                text-decoration: none;
                transform: none;
                padding-right: 54px;

                &:before {
                  content: '';
                  color: #fff;
                  width: 15px;
                  height: 15px;
                  border-color: $brand-primary;
                  border-style: solid;
                  border-width: 0 0 2px 2px;
                  position: absolute;
                  top: 32px;
                  right: 26px;
                  transform: rotate(45deg);
                }
              }
            }

            @include breakpoint($desk) {
              display: none !important;
            }
          }

          &.open {
            > ul.menu,
            > .menu-wrapper > div > ul.menu {
              // Use transform: none instead of transform: translateX(0)
              // to avoid creating containing block that ruins fixed positioned sub elements.
              transform: none;
            }
          }
        }
      }
    }


    .l-region--blockgroup-above-header-block {
      @include pie-clearfix;

      @include breakpoint($desk) {
        position: absolute;
        top: -130px;
        left: 0;
        width: 100%;
        text-align: right;
        font-size: 0;

        > .block {
          display: inline-block;
          vertical-align: middle;
          margin: 0 5px;

          @include breakpoint($wide) {
            margin: 0 14px;
          }
        }
      }

      @include breakpoint($wide) {
        top: -140px;
      }


      .flash-offer {
        display: none;
        font-size: 13px;
        font-weight: 500;
        border-radius: 20px;
        line-height: 25px;
        background-color: $brand-secondary;
        color: #fff;
        padding: 0 15px;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0;
        min-width: 230px;
        text-align: center;

        @include breakpoint($desk) {
          display: block;
        }


        p, a {
          color: #fff;
          margin: 0;
        }
      }

      .block-about-us {
        display: none;

        @include breakpoint($wide) {
          display: inline-block;
        }

        p {
          margin: 0;
        }
      }

      .block--feature-blog-header-track-link {
        @include breakpoint(0 $desk) {
          width: 100%;
          padding: 0 10px;
          position: relative;

          .block__content {
            border-bottom: 1px solid #d9d9d9;
          }
        }
      }

      .header-track-link {
        @include breakpoint(0 $desk) {
          display: block;
          line-height: 50px;
          height: 50px;
          background-color: transparent;
          color: $brand-primary;
          font-size: 16px;
          font-weight: 500;

          &:before {
            @extend .font-icon;
            @extend .font-icon-track;
            display: block;
            position: absolute;
            top: 10px;
            right: 23px;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            text-align: center;
            color: #fff;
            line-height: 30px;
            background: $brand-primary;
            backface-visibility: hidden;
          }
        }

        @include breakpoint($desk) {
          display: inline-block;
          text-decoration: none;
          position: relative;
          font-size: 13px;
          padding: 0 10px 0 30px;
          background-color: $brand-primary;
          color: #fff;
          border-radius: 20px;
          line-height: 25px;
          font-weight: 500;

          &:before {
            @extend .font-icon;
            @extend .font-icon-track;
            position: absolute;
            width: 20px;
            display: block;
            top: 0;
            left: 4px;
            line-height: 25px;
          }
        }
      }

      .header-blog-link {
        @include breakpoint(0 $desk) {
          position: relative;
          width: 100%;
          padding: 0 10px;
          display: block;
          text-transform: uppercase;
          font-weight: 500;
          color: #f48477;
          line-height: 51px;

          &, small {
            font-size: 14px;
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 0;
            height: 1px;
            background-color: #d9d9d9;
          }
        }

        @include breakpoint($desk) {
          background-color: #f48477;
          border-radius: 0 0 7px 7px;
          vertical-align: top;
          padding: 1px 7px 5px;
          text-decoration: none;
          margin-right: 0;

          span {
            display: block;
            text-align: center;
            color: #fff;
            line-height: 1.1;
            font-size: 18px;

            small {
              font-size: 13px;
            }
          }
        }
      }

      .block--feature-wishlist-wishlist-link {
        @include breakpoint(0 $desk) {
          width: 100%;
          padding: 0 10px;

          .block__content {
            border-bottom: 1px solid #d9d9d9;

            a {
              height: 50px;
              line-height: 50px;
              font-weight: 500;
              font-size: 16px;
              color: $brand-primary;

              &:before {
                @extend .font-icon;
                @extend .font-icon-wishlist-heart;
                color: white;
                background: $brand-primary;
                border-radius: 50%;
                display: block;
                position: absolute;
                right: 23px;
                top: 6px;
                text-align: center;
                z-index: -1;
                font-size: 16px;
                height: 30px;
                line-height: 30px;
                width: 30px;
                padding-top: 1px;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }

    @include breakpoint($desk) {
      .l-region--blockgroup-above-header-block,
      .l-region--blockgroup-header-block {
        background: #f5f5f5;

        body.mega-menu-active & {
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            opacity: 0.5;
            z-index: 499;
          }
        }
      }
    }

    .l-region--blockgroup-header-block {
      @include pie-clearfix;

      @include breakpoint($desk) {
        text-align: right;
        position: absolute;
        top: -84px;
        right: -15px;
        left: 270px;
        // this is important otherwise the minicart gets hidden under the menu.
        z-index: 498;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }


      @include breakpoint($wide) {
        left: 300px;
      }


      .block {
        font-size: 14px;
        vertical-align: middle;
        display: inline-block;
      }
    }

    // body.mobile-menu-active & {
    //   @include transform(translateX(-260px));
    //   transition-delay: 0.15s;
    //   @include breakpoint($desk) {
    //     @include transform(translateX(0));
    //   }
    // }

    // body.mobile-menu-active.mobile-submenu-active.depth-2 & {
    //   @include transform(translateX(-280px));
    //   @include breakpoint($desk) {
    //     @include transform(translateX(0));
    //   }
    // }

    // body.mobile-menu-active.mobile-submenu-active.depth-3 & {
    //   @include transform(translateX(-300px));
    //   @include breakpoint($desk) {
    //     @include transform(translateX(0));
    //   }
    // }

    // .l-mobile-menu {
    //   background: #fff;
    //   box-shadow: none;
    //   display: block;
    //   height: 100%;
    //   overflow: auto;
    //   position: absolute;
    //   right: -310px;
    //   top: 0;
    //   width: 310px;
    //   z-index: 499;

    //   body.depth-2 &,
    //   body.depth-3 & {
    //     overflow: hidden;
    //   }

    //   .depth-2 {
    //     .submenu-close {
    //       left: 3px;
    //     }
    //   }

    //   .depth-3 {
    //     .submenu-close {
    //       left: 6px;
    //     }
    //   }

    //   h2 {
    //     display: none;
    //   }

    //   body.mobile-submenu-active & #block-system-main-menu > ul.menu > li > a {
    //     pointer-events: none;
    //   }

    //   @include breakpoint($desk) {
    //     display: none;
    //   }
    // }

    // .l-mobile-overlay {
    //   background: rgba(0, 0, 0, 0.5);
    //   height: 0px;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 0px;
    //   z-index: 498;
    //   @include opacity(0);
    //   -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    //   -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    //   transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;

    //   body.mobile-menu-active & {
    //     // box-shadow: -30px 0 60px -30px rgba(0, 0, 0, 0.5) inset;
    //     height: 100%;
    //     width: 100%;
    //     @include opacity(1);
    //     -webkit-transition: opacity 0.3s;
    //     -moz-transition: opacity 0.3s;
    //     transition: opacity 0.3s;
    //     box-shadow: -30px 0 60px -30px rgba(0,0,0,0.5) inset;
    //   }

    //   @include breakpoint($desk) {
    //     display: none;
    //   }
    // }

  }
}

.mobile-menu-overlay {
  background: black;
  z-index: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-999999px);
  display: none;

  @include breakpoint(0 $desk) {
    display: block;
  }

  body.mobile-menu-active & {
    pointer-events: all;
    transform: translateX(0);
    opacity: 0.5;
  }
}

.l-pusher .l-container .l-mobile-menu {
  position: relative;
  width: 100%;
  height: 82px;
  padding: 5px 75px 5px 10px !important;
  background: #f5f5f5;
  display: block;

  @include breakpoint($desk) {
    display: none;
  }


  .menu-title {
    display: inline-block;
    position: absolute;
    z-index: 1;
    text-transform: uppercase;
    top: 27px;
    left: 10px;
    font-weight: 500;
    color: #333333;
  }

  .block--system-main-menu li.expanded.over {
    background-color: transparent;
  }

  .l-region--navigation {
    position: relative;
  }

  .block-about-us {
    display: none;

    @include breakpoint($desk) {
      display: block;
    }
  }

  .submenu-close,
  .mobile-menu-close {
    color: $brand-secondary;
    display: inline-block;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 7px;
    right: 0;
    text-align: center;
    text-decoration: none;
    width: 60px;

    &:before {
      @extend %font-icon;

      text-decoration: none;
    }
  }

  .mobile-menu-close {
    z-index: 1;

    &:before {
      @extend %font-icon-close;

      background-color: $brand-secondary;
      color: #fff;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      line-height: 36px;
      font-size: 11px;
      font-weight: bold;
      right: 20px;
      top: 10px;
      position: absolute;

      // z-index: 1;

    }
  }

  .submenu-close {
    // z-index: 2;
    &:before {
      @extend %font-icon-pager-left;

      position: absolute;
      top: 50%;

      // right: 58%;
      // left: 20%;
      transform: translate(-50%, -50%);
    }
  }

  .block--system-main-menu {
    padding: 0;
    border: 0px;

    .depth-1 > li {
      > a {
        font-size: 14px;

        &:after {
          @extend %font-icon;

          @extend %font-icon-plus;

          color: $brand-primary;
          position: absolute;
          right: 20px;

          @include vertical-align;
        }
      }
    }
  }

  li {
    @include pie-clearfix;

    display: block;
    padding: 0;
    margin-left: 10px;

    a {
      color: $text-color;
      display: block;
      line-height: 22px;
      padding: 0;
      height: auto;
      text-decoration: none;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      padding: 14px 0px 14px 0;

      span {
        top: auto;
        transform: translateY(0);
        max-width: 230px;
      }

      em {
        font-style: normal;
      }
    }

    border-color: #d9d9d9;
    border-style: solid;
    border-width: 0 0 1px 0;

    & + li {
      border-top: none;
    }
  }

  .mobile-submenu {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: #fff;
    bottom: 0;
    box-shadow: 0 0px 30px rgba(0, 0, 0, 0.25);
    left: 23px;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    padding: 65px 10px 20px 10px;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(290px);
    transition: transform 0.3s ease;
    width: 290px;

    li {
      position: relative !important;
      left: auto !important;
      top: auto !important;
      width: 100% !important;

      &.grid-item {
        a {
          border-bottom: 0;
          margin-bottom: 0;

          em {
            display: inline;
          }
        }
      }

      a {
        font-weight: normal;
      }
    }

    &.open {
      z-index: 3;
      transform: translateX(0);
    }

    &.depth-3 {
      left: 23px;
      padding-right: 50px;
      transform: translateX(300px);
      width: 300px;

      // min-height: 1000px;

      & ul.depth-3 {
        min-height: 100%;

        li.last {
          margin-bottom: 30px;
        }

        li {
          width: 235px !important;
        }
      }

      &.open {
        transform: translateX(0px);
        z-index: 5;

        // min-height: 10000px;

      }
    }
  }

  .submenu-title {
    border-bottom: 1px solid $default-border-color;
    border-top: 1px solid $default-border-color;
    margin-left: 10px;
    padding: 14px 5px 14px 0;
    cursor: pointer;
    display: block;
    font-size: 18px;
    font-weight: 500 !important;
    width: 240px;
  }

  .depth-3 .submenu-title {
    border-bottom: 1px solid $default-border-color !important;
  }
}

.l-mobile-menu {
  .block-search {
    height: 151px;
    display: block;
    padding: 0;
    margin: 0;
    padding-top: 82px;
    background: #f5f5f5;
    top: 0;
    right: 0;
    width: auto;

    .block__content {
      height: 71px;
      background: #fff;
      position: relative;
      z-index: 0;

      p:first-child {
        width: 253px;
        padding: 10px 10px 10px 0;
        margin-left: 10px;
        height: 71px;
      }

      a.findastic-overlay-search {
        font-size: 0px;
        display: block;
        background: transparent;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
        color: #999999;
        cursor: text;
        height: 50px;
        position: relative;
        text-align: left;
        text-decoration: none;
        z-index: 2;
        border-radius: 3px;
        letter-spacing: -0.3px;

        // margin-right: 10px;

        &:before {
          color: $brand-primary;
          background-color: transparent;
          margin-top: 7px;
          height: 36px;
          width: 36px;
          line-height: 36px;
          font-size: 18px;
        }
      }

      span {
        display: block;
        position: absolute;
        top: 27px;
        left: 40px;
        font-size: 14px;
        color: #999999;
        z-index: -1;

        &.bold {
          display: none;
        }
      }
    }
  }

  .block--feature-commerce {
    @extend %mobile-menu-user-actions;

    #commerce-cart-wrapper {
      height: auto;

      a.shopping-cart-link {
        span.title {
          float: left;
          display: inline-block;
          font-size: 14px;
          color: $brand-primary;
          font-weight: 500;
          line-height: 30px;
          min-width: 204px;

          small {
            float: right;
            font-size: 16px;
            color: $text-color;
            line-height: 30px;
            text-align: right;
            min-width: 90px;
          }
        }

        span.icon {
          float: right;
          margin: 0 0 0 0;
          width: 40px;
          height: auto;
          top: -6px;

          strong {
            font-size: 10px;
            height: 18px;
            line-height: 19px;
            width: 18px;
          }

          &:after {
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            width: 30px;
            right: 10px;
            top: 6px;
          }
        }
      }
    }
  }

  .block--feature-default-profile {
    @extend %mobile-menu-user-actions;

    &:after {
      display: none;
    }

    .block__content {
      position: relative;
      border: 0;
      width: 100%;
      text-align: left;

      .profile-link {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: $brand-primary;
        padding: 0;

        &:after {
          content: '';
          display: inline-block;
          background-image: url(../gravatar.svg);
          position: absolute;
          right: 1px;
          height: 30px;
          border-radius: 50%;
          width: 30px;
          top: 0px;
          background-position: 50% 50%;
        }

        strong {
          display: none;
        }
      }

      .user-picture {
        display: none;
        position: absolute;
      }
    }
  }

  .block--feature-wishlist {
    @extend %mobile-menu-user-actions;

    .block__content {
      a {
        font-size: 14px;
        line-height: 30px;
        height: 30px;
        font-weight: 500;
        color: $brand-primary;

        &:before {
          @extend %font-icon;
          @extend %font-icon-wishlist-heart;
          position: absolute;
          top: 10px;
          right: 11px;
          font-size: 14px;
          line-height: 30px;
          color: #fff;
          background: $brand-primary;
          width: 30px;
          height: 30px;
          text-align: center;
          border-radius: 50%;
          padding: 0;
        }
      }
    }
  }

  .block--lang-dropdown {
    @extend %mobile-menu-user-actions;

    margin-left: 10px !important;

    .block__content {
      // width: 270px;
      width: 100%;

      .selector {
        span {
          &:before {
            left: 0;
            color: $brand-primary;
            font-size: 14px;
            font-weight: 500;
          }

          &:after {
            content: '';
            width: 30px;
            height: 30px;
            border: 2px solid $brand-primary;
            border-radius: 50%;
            position: absolute;
            top: 0px;
            right: 1px;
          }
        }

        select {
          width: 100% !important;
        }
      }

      .language-icon {
        width: 20px;
        height: 12px;
        right: 6px;
      }
    }
  }
}

body.depth-3 .l-pusher .l-container .l-mobile-menu {
  .depth-2 {
    overflow-y: hidden;
  }
}

body.mobile-menu-active .l-filter-menu {
  transform: translateX(450px);
}

body.mobile-menu-inactive .l-filter-menu {
  transition: 0.5s ease;
}

body.mobile-menu-active.mobile-filter-active .l-pusher .l-container {
  transform: translateX(-260px);

  @include breakpoint($desk) {
    transform: translateX(0);
  }
}

body.mobile-filter-active .l-pusher {
  overflow: hidden !important;
}

body.mobile-menu-active .l-pusher {
  overflow: hidden !important;
}

body.mobile-menu-active {
  .block--views-popular-brands-block {
    display: none;
  }
}

.mmenu-mm-list-path-weekly-offers {
  display: none !important;
}

// .sticky-header {
// transition: transform 0.5s;
//   body.mobile-menu-active & {
//     @include transform(translateX(-260px));
//     transition-delay: 0.15s;
//   }

//   body.mobile-menu-active.mobile-submenu-active.depth-2 & {
//     @include transform(translateX(-280px));
//   }

//   body.mobile-menu-active.mobile-submenu-active.depth-3 & {
//     @include transform(translateX(-300px));    }

//   .l-mobile-overlay {
//   background: rgba(0, 0, 0, 0.5);
//   height: 0px;
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 0px;
//   z-index: 498;
//   @include opacity(0);
//   -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
//   -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
//   transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;

//   body.mobile-menu-active & {
//     // box-shadow: -30px 0 60px -30px rgba(0, 0, 0, 0.5) inset;
//     height: 100%;
//     width: 100%;
//     @include opacity(1);
//     -webkit-transition: opacity 0.3s;
//     -moz-transition: opacity 0.3s;
//     transition: opacity 0.3s;
//   }

//   @include breakpoint($desk) {
//     display: none;
//   }
// }
// }


@include breakpoint($desk) {
  a.subopen {
    display: none !important;
  }
}
