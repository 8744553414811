.ajax-progress .throbber {
  background: transparent url('../images/loader-blue.gif') no-repeat;
  background-size: 15px 15px;
  position: absolute;
  float: none;
  height: 20px;
  margin: 2px;
  display: none;

  #block-system-main-menu & {
    display: none;
  }

  .findastic-overlay-search-results &,
  #confirmation-box-wrapper &,
  .section-list-page #findastic-wrapper & {
    top: -4px;
    right: -20px;
  }

  #confirmation-box-wrapper & {

  }

  .form-item-customer-profile-shipping-addressbook & {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
}

tr .ajax-progress .throbber {
  margin: 0 2px;
}

.ajax-progress-bar {
  width: 16em;
}

.ajax-progress {
  float: none;
  display: inline;

  .message {
    display: none;
  }
  .throbber {
    .page-checkout-billing .form-type-radio &,
    .page-checkout-shipping .form-type-radio & {
      position: absolute;
      right: 10px;
      top: 14px;
    }

    .page-checkout-billing .netstudio_coupon & {
      position: absolute;
      left: 57%;
      top: 17px;
    }

    .page-checkout-billing .form-type-radio  input[value="commerce_piraeusbank_redirect|commerce_payment_commerce_piraeusbank_redirect"] + & {
      right: 135px;
    }

    .feature-stores-search-form & {
      position: absolute;
      top: 10px;
      right: 15%;
    }

    .form-item-commerce-shipping-shipping-service & {
      right: 7px!important;
    }

    .form-item-commerce-shipping-service-details-search &,
    .form-item-customer-profile-shipping-commerce-customer-address-und-0-postal-code &,
    .form-item-customer-profile-billing-commerce-customer-address-und-0-postal-code & {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
    }
  }
}


.node--product.node-teaser {
  .commerce-add-to-cart {
    button {
      &.ajax-loading {
        @extend %loading-spinner-white;
        background-position: 10px 50%;
        background-size: 40px;

        &:before {
          visibility: hidden;
        }

        &:hover {
          @extend %loading-spinner-white;
          background-position: 10px 50%;
          background-size: 40px;
        }
      }
    }
  }
}


.node--product {
  .commerce-add-to-cart {
    button {
      &.ajax-loading {
        @extend %loading-spinner-white;
        background-position: 10px 50%;
        background-size: 40px;

        &:before {
          visibility: hidden;
        }

        &:hover {
          @extend %loading-spinner-white;
          background-position: 10px 50%;
          background-size: 40px;
        }
      }
    }
  }
}

input.ajax-loading {
  ~label {
    @extend %loading-spinner;
    background-position: -1px 10px;
    background-size: 23px;

    &:before {
      visibility: hidden;
    }
  }
}

.form-item-order-gift-wrap-gift-wrap input.ajax-loading {
  ~label {
    @extend %loading-spinner;
    background-position: 0px 0px;
    background-size: 20px;

    &:before {
      visibility: hidden;
    }
  }
}


.attribute-widgets {
  .form-radios.ajax-loading {
    @extend %loading-spinner;
    background-position: 50% 50%;
    background-size: 40px;
    height: 40px;

    .form-item {
      visibility: hidden;
    }
  }

  .selector.ajax-loading {
    @extend %loading-spinner;
    background-position: 50% 50%;
    background-size: 40px;
    height: 40px;

    span {
      visibility: hidden
    }
  }
}

.form-item-commerce-shipping-service-details-search {
  input.ajax-loading ~ label {
    background: none;
  }
  .ajax-progress {
    @extend %loading-spinner;
    position: absolute;
    right: 20px;
    top: 5px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    z-index: 200;
  }
}
