// Base variables
$border-radius-base: 3px;
$brand-primary: #28bdb3;
$brand-secondary: #8cc53f;
$text-color: #333333;
$default-border-color: #e6e6e6;
$darker-border-color: #d9d9d9;
$contrast-color: #f05b71;

// Font variables
// $font-family:  'FuturaNeu', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family:  'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-large: 20px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-h1: 26px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 14px;
$font-size-h6: 12px;


// Links
$link-color: $brand-primary;
$link-disabled-color: #d9d9d9;

$line-height-base: 1.25;
$headings-font-weight: bold;
$headings-line-height: 1.1;
$headings-color: $brand-primary;

// Color for h4 or smaller
$subheadings-color: $text-color;

// Breakpoint variables
$screen-xs: 320px;
$screen-sm: 768px;
$screen-md: 960px;
$screen-lg: 1280px;
$mob: $screen-xs;
$tab: $screen-sm;
$desk: $screen-md;
$wide: $screen-lg;

// Grid variables
$grid-gutter-width: 40px;
$container-tablet: 768px;
$container-desktop: 960px;
$container-large-desktop: 1220px;

// Breadcrumb variables
$breadcrumb-color:              $text-color;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $brand-primary;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          " / ";

// Button variables
$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;
$btn-border-radius-base: $border-radius-base;

// Well Variables
$well-bg: #f5f5f5;
$well-border: #d9d9d9;


// Fonts for bootstrap
$font-family-sans-serif: $font-family;
$font-family-serif: $font-family;

$icomoon-font-path: "../fonts" !default;

$font-icon-track: \e939;
$font-icon-microphone: \e929;
$font-icon-all: \e900;
$font-icon-arrow-down-photo: \e901;
$font-icon-arrow-down-thick: \e902;
$font-icon-arrow-down: \e903;
$font-icon-arrow-left-photo: \e904;
$font-icon-arrow-left: \e905;
$font-icon-arrow-right-2: \e906;
$font-icon-arrow-right-photo: \e907;
$font-icon-arrow-right: \e908;
$font-icon-arrow-up-photo: \e909;
$font-icon-arrow-up-thick: \e90a;
$font-icon-arrow-up: \e90b;
$font-icon-basket-thin: \e90c;
$font-icon-basket: \e90d;
$font-icon-brands: \e90e;
$font-icon-bubble: \e90f;
$font-icon-burger: \e910;
$font-icon-calculator: \e911;
$font-icon-cart-circle: \e912;
$font-icon-cart-stripped: \e913;
$font-icon-cart: \e914;
$font-icon-close: \e915;
$font-icon-cup: \e916;
$font-icon-delete: \e917;
$font-icon-delivery: \e918;
$font-icon-description: \e919;
$font-icon-express: \e91a;
$font-icon-facebook: \e91b;
$font-icon-google-plus: \e91c;
$font-icon-info: \e91d;
$font-icon-instagram: \e91e;
$font-icon-instructions: \e91f;
$font-icon-lock: \e920;
$font-icon-mail: \e921;
$font-icon-minus: \e922;
$font-icon-netstudio-star: \e923;
$font-icon-offers: \e924;
$font-icon-pager-left: \e925;
$font-icon-pager-right: \e926;
$font-icon-phone-fill: \e927;
$font-icon-phone: \e928;
$font-icon-plus: \e92a;
$font-icon-print: \e92b;
$font-icon-profile: \e92c;
$font-icon-search: \e92d;
$font-icon-share: \e92e;
$font-icon-star: \e92f;
$font-icon-stores: \e930;
$font-icon-tick-availability: \e931;
$font-icon-tick: \e932;
$font-icon-transport: \e933;
$font-icon-truck: \e934;
$font-icon-twitter: \e935;
$font-icon-wishlist-heart: \e936;
$font-icon-youtube: \e937;
$font-icon-zoom: \e938;
