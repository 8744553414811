.block--front-page-promo-products,
.block--quicktabs-mega-offers-block {
  margin-bottom: 35px;
  margin-top: 0;
  @extend .container;
  position: relative;
  background: $well-bg;

  @include breakpoint($tab) {
    margin-bottom: 50px;
    border: 4px solid $brand-primary;
    width: 728px;

    @include breakpoint($desk) {
      margin-bottom: 65px;
      width: 915px;
      @include breakpoint($wide) {
        width: 1200px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  .view-content {
    & > div {
      display: none;
    }

    &.loaded > div {
      display: block;
    }
  }

  .view-footer {
    text-align: center;

    .promotion-type-view-all {
      display: inline-block;
      padding: 10px 33px 10px 16px;
      border: 2px solid $brand-primary;
      font-size: 14px;
      margin: 0px 0 20px;
      background: #fff;
      font-weight: 500;
      position: relative;
      text-transform: uppercase;
      text-decoration: none;

      &:after {
        @extend %font-icon;
        @extend %font-icon-arrow-right-2;
        position: absolute;
        right: 7px;
        top: 50%;
        margin-top: -6px;
        font-size: 12px;
      }
    }
  }

  .view-empty {
    margin-bottom: 50px;
  }

  .block__title {
    color: $text-color;
    font-weight: bold;
    text-align: center;
    @include letter-spacing(-25);
    margin-top: 50px;
    font-size: 20px;
    @include breakpoint($tab) {
      margin-top: 40px;
      font-size: 30px;

    }
    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background: #dbdbdb;
      margin: 20px auto 18px auto;
    }
  }

  .block__subtitle {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 22px;
    color: $text-color;

    font-weight: 500;
    text-align: center;

    @include letter-spacing(-25);
    @include breakpoint($tab) {
      font-size: 18px;
      margin-bottom: 30px;
      line-height: normal;
    }
  }

  .quicktabs_main {
    min-height: 200px;
    @extend %loading-spinner;

    .quicktabs-tabpage {
      background: #f5f5f5;
    }
  }

  .quicktabs-tabs {
    position: relative;
    text-align: center;
    padding: 0;
    z-index: 9;
    display: block;
    margin-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;
  }

  .quicktabs-tabs li {
    display: inline-block;
    border: none;
    outline: 0;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    transition: color 0.2s;
    cursor: pointer;
    color: $text-color;
    padding: 0;
    margin-right: -5px;

    &:after {
      content: '';
      vertical-align: middle;
      width: 1px;
      height: 10px;
      background: #d9d9d9;
      margin: 0;
      display: none;
      @include breakpoint($tab) {
        display: inline-block;
      }
    }

    a {
      border-bottom: 4px solid transparent;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      padding: 19px 10px 15px 10px;
      width: auto;
      margin: 0;
      text-decoration: none;
      color: #333333;

      @include breakpoint($tab) {
      width: 195px;
      margin: 0 15px;

        @include breakpoint($desk) {
          width: 252px;

          @include breakpoint($wide) {
            margin: 0 30px;
          }
        }
      }

      &:hover {
        text-decoration: none;
        color: $brand-primary;
      }
    }

    &:last-child:after {
      display: none;
    }

    &.active {
      a {
        color: $brand-primary;
        border-color: $brand-primary;
      }
    }
  }
}

.view-category-products-promotion,
.view-category-products-promotion-mega-offers {
  .view-content {
    max-width: 310px!important;
    margin: 0 auto;

     @include breakpoint($tab) {
      max-width: 590px!important;

      @include breakpoint($desk) {
        max-width: 885px !important;
        @include breakpoint($wide) {
          max-width: none !important;
        }
      }
    }
  }

  div.selector {
    > span {
      padding-top: 18px;
    }
  }

  .view-filters {
    width: 100%;
    margin-bottom: 20px;
  }

  .views-exposed-form {
    position: relative;

    .form-type-radios {
      .description {
        display: none;

        body.front & {
          display: block;
          transform: translateX(-9999999px) translateZ(0);
          backface-visibility: hidden;
          background: transparent;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          font-size: 0;
        }
      }

      &.loading {
        .description {
          body.front & {
            transform: translateX(0) translateZ(0);
          }
        }
      }
    }


    .views-exposed-widgets {
      text-align: center;
      margin-bottom: 0;
    }

    .views-exposed-widget {
      display: none;
    }
  }

  .view-empty {
    text-align: center;
  }

  > .view-content {
    @include pie-clearfix;

    max-height: 360px;
    overflow: hidden;

    &.owl-carousel {
      max-height: auto;
      overflow: initial;
    }


    .views-row {
      display: inline-block;
      text-align: left;
      width: 295px;
      border-width: 0 1px;

      + .views-row {
        border-left: none;
      }
    }
  }

  .owl-carousel-front {
    max-height: 360px;
    overflow: hidden;

    &.owl-theme {
      max-height: auto;
      overflow: initial;
    }
  }

  .owl-controls {
    margin: 0;
    padding-bottom: 20px;

    .owl-pagination {
      font-size: 0;
    }

    .owl-buttons {
      text-align: left;

      div {
        position: absolute;
        top: 50%;
        transform: translateY(-35px);
        opacity: 1;
        font-size: 0;
        background: none;
        border-radius: 50%;
        margin: 0;
        padding: 0;
        z-index: 1;
        width: 40px;
        height: 40px;
        display: none;

        @include breakpoint($desk) {
          width: 50px;
          height: 50px;
          display: block;
        }


        &:before {
          @extend %font-icon;

          font-size: 40px;
          text-align: center;
          line-height: 36px;
          padding-left: 11px;
          color: $brand-primary;

          @include breakpoint($tab) {
            line-height: 47px;
            padding-left: 15px;
          }
        }

        &.owl-prev {
          left: 6px;

          @include breakpoint($tab) {
            left: -67px;
          }

          &:before {
            width: 16px;

            @extend %font-icon-arrow-left-photo;
          }

          &:hover {
            color: darken(#00854a, 5%);
          }
        }

        &.owl-next {
          right: 6px;

          @include breakpoint($tab) {
            right: -67px;
          }

          &:before {
            width: 11px;
            padding-left: 14px;

            @extend %font-icon-arrow-right-photo;

            @include breakpoint($tab) {
              padding-left: 18px;
            }
          }

          &:hover {
            color: darken(#00854a, 5%);
          }
        }
      }
    }

    .owl-page {
      span {
        width: 14px;
        height: 14px;
        border: 2px solid #e5e5e5;
      }

      &.active, &:hover {
        span {
          background: white;
          border-color: $brand-primary;
        }
      }
    }
  }

  ul.pager {
    margin: 0;
    padding: 0;
    height: 0;
  }

  .pager-previous,
  .pager-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    opacity: 1;
    font-size: 0;
    background-color: #fff;
    border: 2px solid #00854a;
    border-radius: 50%;
    color: #00854a;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 0 5px #fff;
    z-index: 1;

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      border: none;
      border-radius: 50%;
      padding: 0;
      margin : 0;
      background: transparent;

      &:hover {
        background: transparent;
      }
    }

    // .ajax-progress .throbber {
    //   height: 0;
    //   width: 0;
    //   margin: 0;
    // }

    &:after {
      @extend %font-icon;
      font-size: 23px;
      text-align: center;
      line-height: 47px;
      z-index: 1;
    }
  }

  .pager-previous {
    left: -25px;

    &:after {
      @extend %font-icon-pager-left;
      width: 15px;
    }
  }

  .pager-next {
    right: -25px;

    &:after {
      @extend %font-icon-pager-right;
      width: 9px;
    }
  }

  .pager-current {
    font-size: 0;
  }

  .fa-refresh {
    margin-top: 20px;
    margin-bottom: 9px;
    font-size: 51px;
  }
}

.promotion-filters-wrapper {
  @include pie-clearfix;
  margin-top: 50px;
  border-bottom: 1px solid #d9d9d9;

  .promotion-filters {
    position: relative;
    text-align: center;
    z-index: 9;
    display: block;
  }

  .block__title {
    color: $text-color;
    @include letter-spacing(-25);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 40px;

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background: #dbdbdb;
      margin: 20px auto -3px auto;
    }
  }

  .block__subtitle {
    color: $text-color;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }

  .promotion-filter {
    display: inline-block;
    border: none;
    outline: 0;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    transition: color 0.2s;
    cursor: pointer;
    color: $text-color;

    span {
      border-bottom: 4px solid transparent;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      padding: 19px 10px 15px 10px;
      width: auto;
      margin: 0;

      @include breakpoint($tab) {
      width: 195px;
      margin: 0 15px;

        @include breakpoint($desk) {
          width: 250px;
          // margin: 0 25px;

          @include breakpoint($wide) {
            margin: 0 30px;
          }
        }
      }
    }

    &:after {
      content: '';
      vertical-align: middle;
      width: 1px;
      height: 10px;
      background: #d9d9d9;
      margin: 0;
      display: none;
      @include breakpoint($tab) {
        display: inline-block;
      }
    }

    &:last-child:after {
      display: none;
    }

    // body.front & {
    //   width: 130px;
    //   height: 130px;
    //   padding: 10px;
    //   color: #adadad;
    //   overflow: hidden;
    //   transform: translateZ(0);

    //   & > i {
    //     display: inline-block;
    //     font-size: 0;
    //     margin-top: 20px;
    //     border-radius: 50%;
    //     width: 56px;
    //     height: 56px;
    //     position: relative;
    //     line-height: 1;

    //     &:before {
    //       @extend %font-icon;
    //       font-size: 51px;
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       transform: translate(-50%, -50%);
    //     }

    //     &.front-filter-all:before { @extend %font-icon-all;}
    //     &.front-filter-pliroforiki:before { @extend %font-icon-pliroforiki;}
    //     &.front-filter-eikona-ixos:before { @extend %font-icon-audiovisual;}
    //     &.front-filter-oikiakes-syskeves:before { @extend %font-icon-appliances;}
    //     &.eikona-ihos:before { @extend %font-icon-audiovisual;}
    //     &.pliroforiki:before { @extend %font-icon-pliroforiki;}
    //     &.tilefonia:before { @extend %font-icon-phone-filter;}
    //     &.klimatismos-thermansi:before { @extend %font-icon-aircondition;}
    //     &.oikiakes-syskeyes:before { @extend %font-icon-appliances;}
    //     &.exoplismos-spitioy:before { @extend %font-icon-home-equipment;}
    //     &.gadgets-gaming-hobbies:before { @extend %font-icon-gadgets;}
    //   }

    //   span {
    //     display: block;
    //     margin-top: 5px;
    //     position: absolute;
    //     width: 100%;
    //     left: 0;
    //     top: 90px;
    //     padding: 0 10px;
    //   }

    //   &:hover {
    //     color: #333;
    //     text-decoration: none;
    //   }
    // }

    // &.loading {
    //   & > i {
    //     background-image: url("/sites/all/themes/ns/images/loader-blue.gif") !important;
    //     background-repeat: no-repeat;
    //     background-color: transparent !important;
    //     background-size: 100%;
    //     color: transparent !important;
    //   }
    // }

    &.selected {
      span {
        color: $brand-primary;
        border-color: $brand-primary;
      }

      // body.front & {
      //   border: none;
      //   color: #005baa;
      //   box-shadow: 0 5px 0 0 #005baa, 0px 8px 20px 0px rgba(0, 1, 1, 0.15);

      //   & > i {
      //     background-color: #005baa;
      //     color: #fff;
      //   }
      // }
    }
  }
}

.block--front-page-promo-products {
  display: none;

  @include breakpoint($desk) {
    display: block;
  }
}
