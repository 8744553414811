a.unflag-action:focus {
	text-decoration: none;
}

.page-user-wishlist,
.page-wishlist {
	table.views-table {
		margin: 0 auto;
		width: 100%;

		thead {
			border-bottom: 1px solid #e6e6e6;
		    border-top: 1px solid #e6e6e6;
		    display: none;
		    font-size: 14px;
		    text-transform: uppercase;

			@include breakpoint($tab) {
				display: table-header-group;
			}
		}

		tbody {
			text-align: center;

			@include breakpoint($tab) {
				text-align: left;
			}
		}

		tr {
			border-bottom: 1px solid #e6e6e6;

			th {
		    color: #666666;
		    font-size: 12px;
		    padding-top: 18px;
		    padding-bottom: 18px;
		    text-align: center;
		    font-weight: 500;

		    &.views-field-title {
		    	text-align: left;
		    }
			}
		}

		td {
			display: inline-block;
			padding: 10px 0;

			&.views-field-commerce-price {
				text-align: center;
			}

			@include breakpoint($tab) {
				display: table-cell;
			}

		}

		td.views-field-add-to-cart-form {
			padding: 0;
		    padding-left: 15px;
		    position: relative;
		    text-align: right;
		    top: -20px;

			@include breakpoint($tab) {
		    padding: 10px 0;
				padding-left: 20px;
				vertical-align: middle;
			}

			button {
				background: none;
				border: none;
				color: $brand-primary;
				font-size: $font-size-h6;
				font-weight: bold;
				padding-top: 43px;

				&:before {
					@extend %font-icon;
					@extend %font-icon-basket;
					font-size: 15px;
					padding-right: 10px;
					position: relative;
					top: 0px;
				}
			}
		}

		td.views-field-title {
			display: table;
			line-height: 0.8;
			margin-top: 20px;
			padding-left: 10px;
			width: 100%;

			@include breakpoint($tab) {
				display: table-cell;
				margin-top: 0;
				width: 50%;
			}

			p {
				line-height: 1.5;

				@include breakpoint($tab) {
					line-height: 1.2;
				}
			}

			@include breakpoint($tab) {
				// width: 100%;
				display: table-cell;
				padding-right: 40px;
				line-height: 1.25;
				@include breakpoint($desk) {
					width: 536px;
				}
			}

			span.cart-image,
			span.cart-product-details {
				display: inline-block;
			}
			span.cart-product-details {
				font-weight: 500;
				max-width: 400px;
				padding-left: 30px;
				text-align: left;
				vertical-align: middle;

				@include breakpoint ($tab) {
					padding-left: 30px;
					max-width: 300px;
					@include breakpoint($desk) {
						max-width: 400px;
					}
				}
				p {
					font-size: $font-size-small;
					margin-bottom: 0px;

					@include breakpoint($tab) {
						font-size: $font-size-base;
						margin-bottom: 10px;
					}
				}
				.cart-sku p {
					font-size: 12px;
				}
			}
		}
		td.views-field-commerce-price {
			color: $brand-primary;
			font-weight: bold;
			padding-right: 20px;

			@include breakpoint($tab) {
				padding-right: 0px;
				width: 10%;
			}
		}

		td.views-field-ops {
			@include breakpoint($tab) {
				width: 10%;
			}
		}
	}

	span.flag-wishlist {
		float: right;
		font-size: 0;

		a {
			&:after {
				bottom: 0px;
				color: #999999 ;
				@extend %font-icon;
        @extend %font-icon-wishlist-heart;
				font-size: 20px;
				position: relative;

				@include breakpoint($tab) {
					left: -10px;
					padding-left: 10px;

					@include breakpoint($desk) {
						left: -5.5px;
					}
				}
			}
			&:hover {
				text-decoration: none;

				&:after {
					color: $brand-primary;
				}
			}
			&.unflag-action {
				&:after {
					color: $brand-primary !important;
				}
			}
		}
	}
}

.page-wishlist .view-wishlist table.views-table {
	margin: 20px auto 60px ;

	th.views-field-add-to-cart-form {
		display: none;
	}
}

.page-wishlist,
.page-user-wishlist {

 .view-empty p {
	margin-top: 30px;
	text-align: center;
	}
}

.page-user-wishlist .l-content,
.page-wishlist .l-content {
		min-height: 400px;
}
