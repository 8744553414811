.l-front-blog {
  display: none;

  .front & {
    display: block;
    position: relative;
    z-index: 1;
  }
}

.front-blog-above {
  padding: 48px 0 40px;
  background: #fff;

  @include breakpoint($tab) {
    padding-top: 83px;
  }
  @include breakpoint($desk) {
    padding: 68px 0 60px;
  }

  // Css for block of articles in front page.
  #block-views-blog-block-1 {
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: top;
    width: 100%;

    @include breakpoint($tab) {
      width: 354px;
    }

    @include breakpoint($desk) {
    width: 408px;

      @include breakpoint($wide) {
        margin-left: 162px;
      }
    }

    .block__content {
      border-top: solid 1px #d9d9d9;

      .views-row {
        margin: 0;
        padding: 20px 0 19px;

        @include breakpoint($tab) {
          padding: 15px 0;
          height: 94px;
          @include breakpoint($desk) {
            padding: 20px 0 19px;
            height: auto;
          }
        }

        // It takes some of the styling from the blog.scss.
        .views-field-created-2 {
          margin-right: 16px;

          .date {
            border-top: solid 1px $well-border;
            padding: 5px 0 4px;

            .day-month {
              padding-bottom: 4px;
            }
          }
        }

        .views-field-nothing {
          display: inline-block;
          height: 60px;
          min-width: 200px;
          position: relative;
          vertical-align: top;
          width: 75%;

          @include breakpoint($tab) {
            width: auto;
          }

          h2 {
            margin: 2px 0 0;
            line-height: 19px;
            max-height: 40px;
            overflow: hidden;

            @include breakpoint($tab) {
              max-width: 275px;
              max-height: auto;
              max-height: initial;
              overflow: visible;
            }

            @include breakpoint($desk) {
              max-width: 330px;
              max-height: auto;
              max-height: initial;
              overflow: visible;
            }
          }

          // Read more link in each article.
          .read-more {
            a {
              @include letter-spacing(100);
              display: block;
              font-size: 12px;
              font-weight: 500;
              line-height: 9px;
              position: absolute;
              bottom: 0;
              @include breakpoint($tab) {
                font-size: 10px;
              }

              &:hover {
                color: darken($brand-primary, 7%);
              }

              &:after {
                @extend %font-icon;
                @extend %font-icon-arrow-right-2;
                font-size: 8px;
                font-weight: bold;
                margin-top: 0px;
                padding-left: 4px;
                vertical-align: top;
              }
            }
          }
        }
      }
    }

    h2 {
      color: $text-color;
      font-weight: 500;
      font-size: $font-size-base;
      line-height: $font-size-base;

      a {
        color: $text-color;
      }

      &.block__title {
        font-size: $font-size-h2;
        font-weight: bold;
        line-height: 24px;
        margin: 0 0 0 74px;
        max-width: 260px;
        padding-bottom: 17px;
        width: auto;

        @include breakpoint($tab) {
          padding-bottom: 45px;
          width: 408px;
          line-height: 20px;

          @include breakpoint($desk) {
            padding-bottom: 25px;
          }

          @include breakpoint($wide) {
            padding-bottom: 30px;
            margin: 0;
          }

        }
      }
    }

    // More link to the blog page.
    .more-link {
      line-height: 12px;
      margin-top: 20px;

      @include breakpoint($tab) {
        margin-top: 0;
      }

      a {
        &:after {
          @extend %font-icon;
          @extend %font-icon-arrow-right-2;
        }
        @include breakpoint(0 $tab) {
          @include letter-spacing(100);
          background-color: white;
          border-radius: 0;
          border: solid 2px #cdcdcd;
          color: $brand-primary;
          display: block;
          padding: 17px;
          font-size: 12px;
          line-height: 1em;
          margin-bottom: 37px;
          text-align: center;
          text-transform: uppercase;
          transition: background-color 0.1s linear;
          font-weight: bold;

          &:focus {
            text-decoration: none;
          }

          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }

          &:after {
            padding-left: 7px;
          }
        }

        @include breakpoint($tab) {
          @include letter-spacing(100);
          display: block;
          font-size: 12px;
          font-weight: 500;
          margin-top: 20px;

          @include breakpoint($desk) {
            margin-top: 45px;
          }

          &:hover {
            color: darken($brand-primary, 7%);
          }

          &:after {
            font-size: 10px;
            font-weight: 500;
            margin-top: 1px;
            padding-left: 5px;
            vertical-align: top;
          }
        }
      }
    }

    &:before {
      background-image: url(../images/pencil.svg);
      background-repeat: no-repeat;
      background-size: 40px;
      content: ' ';
      display: inline-block;
      height: 40px;
      position: absolute;
      top: -8px;
      left: 0;
      width: 40px;

      @include breakpoint($tab) {
        background-size: 60px;
        height: 60px;
        width: 60px;
      }

      @include breakpoint($wide) {
        left: -81px;
      }
    }
  }

  .front-page-ask-the-experts-block {
    display: block;
    margin: 0;
    position: relative;
    width: 100%;

    @include breakpoint($tab) {
      display: inline-block;
      float: right;
      vertical-align: top;
      width: 354px;
    }
    @include breakpoint($desk) {
      width: 430px;
      @include breakpoint($wide) {
        margin-left: 162px;
      }
    }

    .view-header h3 {
      @include letter-spacing(-25);
      color: #333;
      font-size: $font-size-h4;
      font-weight: 500;
      line-height: 20px;
      margin: 14px 0 20px 0;
      @include breakpoint($tab) {
        margin: 15px 0;

        @include breakpoint($desk) {
          margin: 20px 0 13px 0;
        }
      }
    }

    .view-content {
      border-top: solid 1px #d9d9d9;

      .views-row-1 {
        @include breakpoint($tab) {
          height: 110px;
          @include breakpoint($desk) {
            height: 120px;
            @include breakpoint($wide) {
              // height: 120px;
            }
          }
        }
      }

      .views-row-2 {
        padding-bottom: 29px !important;
        @include breakpoint($tab) {
          height: 101px;
          @include breakpoint($desk) {
            height: 111px;
            @include breakpoint($wide) {
            }
          }
        }
      }

      .views-row {
        margin: 0;
        padding: 20px 0 19px;
        @include breakpoint($tab) {
          padding: 10px 0;
          @include  breakpoint($desk) {
            padding: 20px 0 19px;
            @include breakpoint($wide) {
            }
          }
        }
        .views-field-field-from {
          @include letter-spacing(-25);
          display: block;
          margin-bottom: 10px;
          font-size: 13px;
          font-weight: 500;
          @include breakpoint($tab) {
            font-size: 12px;
            @include  breakpoint($desk) {
              font-size: 13px;
              margin-bottom: 6px;
              @include breakpoint($wide) {
              }
            }
          }

          .field-content {
            color: $brand-primary;
          }
        }

        .views-field-field-question-et {
          @include letter-spacing(-25);
          font-size: 13px;
          font-weight: 500;
          max-width: 430px;
          margin-bottom: 15px;
          @include breakpoint($tab) {
            margin-bottom: 20px;
            font-size: 12px;
            @include  breakpoint($desk) {
              font-size: 13px;
              min-height: 30px;
              @include breakpoint($wide) {
              }
            }
          }
          h2 {
            font-weight: normal;

            a {
              font-size: 13px;
              margin: 0;
            }
          }
        }

        // Read more link in each question.
        .views-field-view-node {
          a {
            @include letter-spacing(100);
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 9px;
            text-align: left;

            @include breakpoint($tab) {
              font-size: 10px;
            }

            &:hover {
              color: darken($brand-primary, 7%);
            }

            &:after {
              @extend %font-icon;
              @extend %font-icon-arrow-right-2;
              font-size: 8px;
              font-weight: bold;
              margin-top: 0px;
              padding-left: 4px;
              vertical-align: top;
            }
          }
        }
      }
    }

    h2 {
      color: $text-color;
      font-weight: 500;

      a {
        color: $text-color;
        font-size: $font-size-base;
        line-height: $font-size-base;
      }

      &.block__title {
        font-size: $font-size-h2;
        font-weight: bold;
        line-height: 25px;
        margin: -12px 0 0 59px;
        max-width: 250px;
        padding-bottom: 50px;
        position: relative;

        @include breakpoint($tab) {
          margin: 10px 0 0 74px;
          padding-bottom: 30px;
          width: 408px;
          @include breakpoint($wide) {
            padding-bottom: 35px;
          }
        }

        @include breakpoint($wide) {
          margin: 10px 0 0;
        }

        &:after {
          background-color: $brand-primary;
          content: ' ';
          display: block;
          height: 2px;
          position: absolute;
          top: 72px;
          left: -60px;
          width: 40px;

          @include breakpoint($tab) {
            left: -74px;
            bottom: 0;
            display: none;
          }

          @include breakpoint($wide) {
            display: block;
            left: 0;
          }
        }
      }
    }

    // More link to the ask the experts page.
    .more-link {
      a {
        @extend %btn-submit;
        @include letter-spacing(100);
        background-color: white;
        border: solid 2px #cdcdcd;
        border-radius: 0;
        color: $brand-primary;
        font-size: 12px;
        font-weight: bold;
        line-height: 1em;
        margin-top: 15px;
        padding: 17px 30px 17px 40px;
        width: 100%;
        margin-top: 15px;

        @include breakpoint($tab) {
          background-color: $brand-primary;
          border: none;
          padding: 19px 30px 19px 40px;
          color: white;
          margin-top: 21px;
          float: right;
          width: auto;
          font-size: 12px;

          @include  breakpoint($desk) {
            margin-top: 26px;
          }

        }

        &:after {
          padding-left: 11px;
        }

        &:hover {
          background-color: white;

          @include breakpoint($tab) {
            background-color: darken($brand-primary, 5%);
            text-decoration: none;
          }
        }
      }
    }

    &:before {
      background-image: url(../images/messages.svg);
      background-repeat: no-repeat;
      background-size: 40px;
      content: ' ';
      display: inline-block;
      height: 40px;
      position: absolute;
      top: 3px;
      left: 0;
      width: 40px;

      @include breakpoint($tab) {
        top: -8px;
        background-size: 60px;
        height: 60px;
        width: 60px;
      }

      @include breakpoint($wide) {
        left: -81px;
      }
    }
  }

  // Removes margin bottom from view-blog in front blog.
  .view-blog {
    margin-bottom: 0;
  }
}

.front-blog-events {
  background-color: $brand-primary;
  margin-bottom: 10px;

  .container {
    background-repeat: no-repeat;
    background-position: 30% 0;
    min-height: 290px;
    padding: 41px 10px 20px;

    @include breakpoint($tab) {
      background-image: none;
      padding: 0 20px;
    }

    @include breakpoint($desk) {
      background-position: 100% 40%;
      padding: 56px 20px 51px;
    }

    .back-image {
      @include breakpoint($tab $desk - 1) {
        background-position: 134% 54%;
        background-repeat: no-repeat;
        min-height: 290px;
        padding: 56px 20px 51px;
        width: 862px;
      }
    }
  }

  #block-views-blog-block-2 {
    color: white;
    margin-left: 0;
    position: relative;

    @include breakpoint($tab) {
      margin-left: 43px;
      width: 665px;
    }

    @include breakpoint($desk) {
      margin-left: 162px;
      width: auto;
    }

    &:before {
      @extend %font-icon;
      @extend %font-icon-cup;
      font-size: 30px;
      position: absolute;
      left: 8px;
      top: -2px;

      @include breakpoint($tab) {
        font-size: 36px;
        left: -63px;
      }
    }

    .views-row {
      border: 0;
      margin: 0;
      padding: 0;
    }

    .views-field-title {
      margin: 0;
    }

    h2.block__title {
      @include letter-spacing(-25);
      color: white;
      font-size: 30px;
      margin: 0 0 86px;
      padding-bottom: 10px;
      padding-left: 60px;
      position: relative;
      width: 350px;

      &:after {
        background-color: white;
        content: ' ';
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
      }

      @include breakpoint ($tab) {
        margin: 0 0 27px;
        padding-bottom: 24px;
        padding-left: 0;
      }
    }

    .block__content {
      width: 100%;

      @include breakpoint($tab) {
        width: 350px;
      }
    }

    .view-id-blog {
      margin: 0;
    }

    .views-field-created {
      font-size: 14px;
      font-weight: 500;
      line-height: 11px;
    }

    .views-field-title a {
      color: white;
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin: 22px 0;

      @include breakpoint($tab) {
        width: 410px;
        margin: 14px 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .views-field-view-node {
      text-align: left;

      a {
        @include letter-spacing(100);
        color: white;
        display: block;
        font-size: 12px;
        font-weight: bold;
        line-height: 11px;
        margin-bottom: 43px;
        transition: text-decoration 0.2s;

        @include  breakpoint($tab) {
         font-size: 10px;
         margin-bottom: 0;
        }

        &:after {
          font-size: 7px;
          margin: 0;
          padding: 2px 0 0 4px;
        }

        &:hover {
          text-decoration: underline;

          &:after {
            text-decoration: none;
          }
        }
      }
    }

    .more-link a {
      @extend %btn-submit;
      background: transparent;
      border-radius: 0;
      border: solid 2px white;
      color: white;
      font-size: 12px;
      line-height: 10px;
      padding: 17px 33px;
      width: 100%;

      @include breakpoint($tab) {
        @include vertical-align;
        position: absolute;
        right: 0;
        transition: background 0.1s;
        width: auto;
        font-size: 12px;
        white-space: nowrap;

        &:hover {
          background: rgba(40, 189, 179, 0.7);
        }
      }

      &:after {
        padding-left: 10px;
      }
    }
  }
}
