.flexslider .slides img {
  width: auto;
  max-width: 100%;
}

.ck-text {
  margin-bottom: 20px;
  text-align: center;

  @include breakpoint($desk) {
    text-align: left;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  p {
    font-size: $font-size-base;
    margin-bottom: 20px;
  }

  .youtube-embed-wrapper {
    width: 320px;
    margin: 0 auto;
    height: 230px;
    padding: 0;

    @include breakpoint($tab) {
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      width: 100%;
      margin: 0;
    }
  }

  &.whole {
    text-align: center;

    p.centered {

      @include breakpoint($tab) {
        width: 590px;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }

    .image {
      margin-bottom: 20px;

      .youtube-embed-wrapper {
        padding: 0;

        @include breakpoint($tab) {
          height: 413px;
          width: 100%;
        }

        @include breakpoint($desk) {
          height: 500px;
        }

        @include breakpoint($wide) {
          height: 600px;
        }
      }
    }
  }

  &.inline {
    h2, p {
      text-align: center;

      @include breakpoint($desk) {
        text-align: left;
      }
    }

    .middle-align {
      display: inline-block;

      @include breakpoint($desk) {
        vertical-align: middle;
        width: 425px;
        padding: 0 30px;
      }
    }
    .image {
      display: block;

      @include breakpoint($desk) {
        width: 490px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
      }

      @include breakpoint($wide) {
        width: 750px;
      }
    }
  }

  &.left-banner {
    transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);

    @include breakpoint($desk) {
      transform: scaleY(1);
      -ms-transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }

    .image,
    .middle-align {
      transform: scaleY(-1);
      -ms-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);

      @include breakpoint($desk) {
        transform: scaleY(1);
        -ms-transform: scaleY(1);
        -webkit-transform: scaleY(1);
      }
    }
  }
}

.flexslider .field-content {
  @include breakpoint(max-width $desk - 1) {
    min-height: 215px;
    background: $brand-primary;

    @include breakpoint(max-width $tab - 1) {
      min-height: 150px;
    }
  }
}

.slideshow-wrapper {
  @extend .container;
  padding-right: 0;
  padding-left: 0;

  // transform: translateY(100%);
  // padding: 0;
  // margin: 0;
  // width: 100%;
  // height: 260px;



  @include breakpoint(max-width $desk - 1) {
    width: 100%;
    padding: 0 !important;
  }

  @include breakpoint($desk) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 7.5%;
    // padding-top: 110px;
    transform: none;

    @include breakpoint($wide) {
      // code
    }
  }

  .cke_editable & {
    position: static;
    transform: translateY(0);
    background-color: #00984b;
    padding: 30px;
  }

  .link-more {
    padding-left: 40px;
    margin-top: 25px;
    display: none;
    color: white;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1em;
    transition: opacity 0.1s ease;

    @include breakpoint($wide) {
      display: block;
    }

    &:hover {
      opacity: 0.5;
      text-decoration: none;
    }

    &:after {
      @extend %font-icon;
      @extend %font-icon-arrow-right-2;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .details-wrapper {
    font-size: 25px;
    @include pie-clearfix;
    position: relative;
    @include letter-spacing(-25);
    background: $brand-primary;
    padding: 10px 0;
    width: 100%;
    line-height: 1;
    overflow: hidden;

    @include breakpoint(max-width $desk - 1) {
      height: 100%;
      overflow: hidden;
    }


    @include breakpoint($tab) {
      padding: 20px 10px;

      @include breakpoint($desk) {
        background: rgba($brand-primary, 0.4);
        border: 2px solid white;
        width: 435px;
        height: 335px;
        padding: 20px;

        @include breakpoint($wide) {
          padding: 30px;
          width: 555px;
          height: 400px;
        }
      }
    }

    // img {
    //   // max-height: 50px;
    //   // padding-left: 20px;
    //   display: inline-block;

    //   @include breakpoint($tab) {
    //     // margin-bottom: 25px;
    //   }
    // }

    .logo {
      display: none;

      @include breakpoint($desk) {
        display: inline-block;
        position: static;
        width: auto;
        max-width: 100%;
        max-height: 30px;
        padding: 0;

        @include breakpoint($wide) {
          max-height: 50px;
        }
      }
    }

    h2 {
      color: white;
      margin: 0;
      @include letter-spacing(-25);
      font-size: 0.7em;
      padding: 10px;

      @include breakpoint($tab) {
        width: 100%;
        font-size: 1.36em;
        padding: 15px;

        @include breakpoint($desk) {
        font-size: 1.4em;

          @include breakpoint($wide) {
            font-size: 1.7em;
          }
        }
      }
    }

    .subtitle {
      color: white;
      font-size: 1.2em;

      @include breakpoint($tab) {
        font-size: 1.24em;
        padding: 15px;

        @include breakpoint($desk) {
          // width: 900px;
          font-size: 1.8em;

          @include breakpoint($wide) {
            font-size: 2.2em;
            // width: 1000px;
          }
        }
      }
    }

    .info {
      font-size: 0.6em;
      color: white;
      font-weight: 500;

      @include breakpoint($tab) {
        padding: 15xp;
        font-size: 1.2em;
        max-height: 160px;

        @include breakpoint($desk) {
          font-size: 0.8em;

          @include breakpoint($wide) {
            font-size: 1em;
          }
        }
      }
    }

    .title,
    .subtitle,
    .info {
      @include breakpoint($desk) {
        position: static;
        width: auto;
        max-width: 100%;
        max-height: initial;
        padding: 0;
        margin-top: 20px;
      }
    }

    hr {
      display: none;
      color: white;
      width: 40px;
      border: 1px solid white;
      margin: 17px 0 16px;

      @include breakpoint($tab) {
        margin-left: 30px;

        @include breakpoint($desk) {
          // display: block;
        }
      }
    }

    // .title,
    // .subtitle {
    //   width: 60%;
    //   float: left;
    // }

    // .brand-img,
    // .info {
    //   width: 40%;
    //   float: right;
    // }

    // .title,
    // .subtitle,
    // .brand-img,
    // .info {
    //   margin: 0;
    //   padding: 10px;
    //   line-height: 1.3;

    //   @include breakpoint($tab) {

    //     @include breakpoint($desk) {
    //       width: auto;
    //       float: none;
    //     }
    //   }
    // }

    // .brand-img {
    //   text-align: right;
    //   max-height: 70px;

    //   @include breakpoint($desk) {
    //     text-align: left;
    //   }
    // }
  }
}

.ck-template-content {
  margin: 0 auto;
  margin-top: 35px;
  width: 100%;
  text-align: center;
  padding-bottom: 37px;
  position: relative;
  @include breakpoint($tab) {
    padding-bottom: 0;
    width: 728px;
    @include breakpoint($desk) {
      width: 960px;
      @include breakpoint($wide) {
        width: 1181px;
      }
    }
  }
}

.ck-template-top-info {
  @include pie-clearfix;
  margin-bottom: 25px;

  @include breakpoint($tab) {
    margin-bottom: 50px;
    @include breakpoint($desk) {
      margin: 0 20px 30px 20px;
    }
  }
  .info {

    h3 {
      font-size: 26px;
      line-height: 33px;
      font-weight: bold;
      color: #333333;
      @include letter-spacing(-25);
      text-align: left;
      margin: 0;
    }

    hr {
      width: 40px;
      height: 2px;
      background-color: #dedede;
      margin: 20px 0 14px 0;
      border: none;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      font-weight: 500;
      text-align: left;
      margin-bottom: 0;
      margin-right: 10px;
      @include letter-spacing(-25);
    }

    @include breakpoint($tab) {

      float: left;
      width: 490px;

      h3 {
        font-size: 30px;
        line-height: 1em;
        margin-left: 20px;
      }

      hr {
        margin: 20px 0 15px 20px;
      }

      p {
        font-size: 18px;
        line-height: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }

      @include breakpoint($desk) {
        float: left;

        h3 {
          margin-left: 0;
        }

        hr {
          margin: 17px 0;
        }

        p {
          margin-left: 0;
          margin-right: 0;
        }


        @include breakpoint($wide) {
          width: auto;
          h3 {
            margin-left: 0;
          }

          hr {
            margin-left: 0;
          }

          p {
            margin-left: 0;
          }
        }
      }
    }
  }

  .show-all {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;

    p.button {
      width: 100%;
      margin: 0;

      a {
        padding: 15px 28px;
        border: 2px solid #cdcdcd;
        display: block;
        text-decoration: none;
        font-size: 12px;
        color: $brand-primary;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5px;
        transition: border 0.2s ease;
        &:after {
          @extend %font-icon;
          @extend %font-icon-arrow-right-2;
          margin-left: 9px;
          font-size: 11px;
          font-weight: bold;
          position: relative;
          top: -0.5px;
        }

        &:hover {
          border: 2px solid $brand-primary;
        }

      }
    }
    @include breakpoint($tab) {
      position: static;
      float: right;
      p.button {
        margin-top: 38px;
        margin-right: 0px;
        width: 200px;
        a {
          letter-spacing: 1.5px;
          color: #333333;
          border: 2px solid $brand-primary;
          &:hover {
            border: 2px solid $brand-primary;
            color: $brand-primary;
          }
        }
      }
      @include breakpoint($desk) {
        p.button {
          margin-top: 30px;
        }
        @include breakpoint($wide) {
          p.button {
            // margin-right: 20px;
          }
        }
      }
    }
  }

}

.ck-template-banners {

  clear: both;
  @include pie-clearfix;
  margin-bottom: 60px;

    span {
      display: block;
    }

    @include breakpoint($desk) {
      a:hover {
        img {
          outline-color: #fff;
          cursor: pointer;
          box-shadow: 0 0 60px 0px rgba(0,0,0, 0.5);
          z-index: 20;
          transition: outline 0.4s ease, box-shadow 0.4s ease, z-index 0.4s ease;
        }
      }
    }

    img {
      position: relative;
      width: calc(100% + 20px);
      margin-left: -10px;
      max-width: none;
      @include breakpoint($tab) {
        max-width: 100%;
        margin-left: 0;
        width: auto;
        @include breakpoint($desk) {
          max-width: none;
          transition: outline 0.4s ease, box-shadow 0.4s ease, z-index 0.4s ease;
          outline: 4px solid transparent;
        }
      }
    }

    @include breakpoint($tab) {
      margin-bottom: 65px;
    }
}

.ck-template-1-banner {
  .ck-1-banner {
    @include breakpoint($tab) {
      img {
        width: 100%;
      }
    }
  }
}

.ck-template-2-banners {

  .ck-1-banner {
    margin-bottom: 4px;
  }

  @include breakpoint($tab) {
    .ck-1-banner {
      float: left;
      margin-bottom: 0px;

      &:first-child {
        margin-right: 4px;
      }
    }
    img {
      width: 360px;
    }
    @include breakpoint($desk) {
      img {
        width: 474px;
      }
      @include breakpoint($wide) {
        img {
          width: auto;
        }
      }
    }
  }
}

.ck-template-3-banners {

  .ck-1-banner {
    margin-bottom: 4px;
  }

  .ck-2-banners {
    img:first-child {
      margin-bottom: 4px;
    }
  }
  @include breakpoint($tab) {
    .ck-1-banner {
      float: left;
      margin-bottom: 0;
      img {
        width: 364px;
        margin-right: 4px;
      }
    }

    .ck-2-banners {
      img {
        width: 360px;
      }
    }
    @include breakpoint($desk) {
      .ck-1-banner {
        margin-right: 4px;
        margin-bottom: 0px;
        img {
          width: 472px;
          margin-right: 0;
        }
      }

      .ck-2-banners {
        float: left;
        img {
          width: 472px;
          display: block;
        }
      }

      @include breakpoint(980px) {
        .ck-1-banner {
          img {
            width: 478px;
          }
        }

        .ck-2-banners {
          img {
            width: 478px;
          }
        }

        @include breakpoint($wide) {
          .ck-1-banner {
            img {
              width: 100%;
            }
          }

          .ck-2-banners {
            float: left;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.ck-template-4-banners {
  .ck-2-banners {

    img {
      display: block;
      margin-bottom: 4px;
    }
  }
  @include breakpoint($tab) {
    .ck-2-banners {

      img {
        margin: 4px auto;
      }
    }
    @include breakpoint($desk) {

      img {
        width: 470px;
      }
      .ck-2-banners {
        float: left;

        &:first-child {
          margin-right: 4px;
        }
        img {
          margin: 0;
        }
        .left-image {
          margin-bottom: 4px;
        }
      }

      @include breakpoint(980px) {
        img {
          width: 478px;
        }
        @include breakpoint($wide) {
          img {
            width: auto;
          }
        }
      }
    }
  }
}

.ck-template-5-banners {

  span {
    display: inline-block !important;
  }
  .ck-2-banners {
    .left-image {
      margin-bottom: 4px
    }
  }
  img {
    margin-bottom: 4px;
  }
  @include breakpoint($desk) {
    img {
      margin-bottom: 0;
    }
    .ck-3-banners {
      .first-image {
        margin-right: 2px;
      }
      .second-image {
        margin: 0px 0 0 2px;
      }
      .third-image {
        margin-top: 4px;
      }
    }

    .ck-2-banners {
      .left-image {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }

    @include breakpoint($wide) {
      .ck-3-banners {
        margin-bottom: 4px;
        .first-image {
          margin-right: 2px;
        }
        .second-image {
          margin: 0px 2px;
        }
        .third-image {
          margin-left: 2px;
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .ck-2-banners {
        .left-image {
          margin-right: 2px;
          margin-top: 0;
          margin-bottom: 0;
        }
        .right-image {
          margin-left: 2px;
        }
      }
    }
  }
}

.ck-template-6-banners {
  .ck-2-banners {
    float: left;
    img {
      margin-top: 4px;
      vertical-align: top;
      @include breakpoint($tab) {
        display: inline-block;
        @include breakpoint($wide) {
          display: block;
        }
      }
      &.top-image {
        @include breakpoint($tab) {
          width: 360px;
          margin-right: 4px;
          @include breakpoint($desk) {
            display: block;
            width: 312px;
            @include breakpoint(980px) {
              width: 317px;
              @include breakpoint($wide) {
                width: auto;
              }
            }
          }
        }
      }

      &.bottom-image {

        @include breakpoint($tab) {
          width: 360px;
          @include breakpoint($desk) {
            width: 312px;
            display: block;
            margin-top: 4px;
            @include breakpoint(980px) {
              width: 317px;
              @include breakpoint($wide) {
                width: auto;
              }
            }
          }
        }
      }
    }

    &.first-column {
      @include breakpoint($wide) {
        margin: 0;
      }
    }

    &.second-column {
      @include breakpoint($tab) {
        .bottom-image {
          margin-top: -87px;
          @include breakpoint($desk) {
            margin-top: 4px;
          }
        }
      }
    }

    &.third-column {
      margin-right: 0;
      @include breakpoint($desk) {
        img {
          margin: 4px 0 0 0;
        }
        img.bottom-image {
          margin-top: 4px;
        }
      }
    }
  }
}

.banners-home-page-promo {
  padding: 0 10px;
  margin-top: 40px;
}
