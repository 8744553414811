.findastic-overlay-search--open {
  .findastic-overlay-pager {
    display: none;
  }
}

.findastic-overlay-search-container {
  @include pie-clearfix;
  padding: 0;
  width: 285px;

  @include breakpoint($tab) {
    width: 95%;
  }

  @include breakpoint($desk) {
    width: 920px;

    @include breakpoint($wide) {
      width: 1180px;
    }
  }

  .findastic-overlay-search-inner-container {
    padding-top: 19px;
    text-align: center;

    @include breakpoint ($desk) {
      text-align: left;
    }

    .logo {
      display: none;

      @include breakpoint($desk) {
        display: block;
        float: left;
        height: 80px;
        margin-left: -5px;
        width: 240px;
      }

      @include breakpoint($wide) {
        height: 88px;
        width: 265px;
      }
    }

    .input-container {
      display: block;
      margin: 0 auto;
      padding: 0 40px 0 0;
      width: 100%;

      @include breakpoint($desk) {
        max-width: none;
        margin: 15px 0 0 295px;
        padding: 0;
        width: 540px;

        @include breakpoint($wide) {
          width: 680px;
        }
      }
    }

    .findastic-overlay-search-searchbox {
      border: 0;
      display: block;
      margin: 0;
      overflow: hidden;
      position: relative;
      width: 100%;

      &:after {
        @extend %font-icon;
        @extend %font-icon-search;

        color: $brand-primary;
        font-size: 24px;
        padding-bottom: 7px;
        padding-left: 6px;
        position: absolute;
        right: 0;
        top: 23px;
        z-index: 10;
      }

      input {
        border-bottom: 2px solid $brand-primary;
        border-radius: 0;
        color: $text-color;
        display: inline-block;
        font-size: 16px;
        line-height: 50px;
        margin: 0;
        padding: 10px 65px 0px 5px;
        width: 100%;

        @include breakpoint($tab) {
          font-size: 24px;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .findastic-overlay-search-close {
      background: none;
      color: $brand-primary;
      height: 18px;
      position: absolute;
      right: 0;
      top: 39px;
      transition: color 0.1s;
      width: 18px;
      padding: 0;
      z-index: 5;

      &:before {
        @extend %font-icon;
        @extend %font-icon-close;
        font-size: 18px;
        font-weight: bold;
        height: 18px;
        width: 18px;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: darken($brand-primary, 5%);
      }

      span {
        display: none;

        @include breakpoint($tab) {
          display: inline-block;
          font-size: 13px;
          position: absolute;
          top: 25px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      @include breakpoint($desk) {
        top: 54px;
      }
    }
  }
}

.findastic-overlay-search-results {
  @include pie-clearfix;
  font-size: 0;
  margin: 0 auto;
  padding: 15px 0;

  .findastic-item {
    display: block;
    position: relative;
    z-index: 1;
    transition: box-shadow 0.2s ease;
    margin: 0 auto 45px auto;
    padding: 0 20px;
    width: 100%;

    @include breakpoint($tab) {
      float: left;
      width: 50%;
      border-right: solid 1px #d9d9d9;
      &:nth-child(2n) { border: none; }
      &:nth-child(2n+1) { clear: both; }

      @include breakpoint($wide) {
        width: 25%;
        &:nth-child(2n) { border-right: solid 1px #d9d9d9; }
        &:nth-child(2n+1) { clear: none; }
        &:nth-child(4n) { border: none; }
        &:nth-child(4n+1) { clear: both; }
      }
    }

    .findastic-facets-results-wrapper & {
      border-bottom: none;

      @include breakpoint($desk) {
        min-height: 352px;
      }

      @include breakpoint($wide) {
        width: (100% / 3);
        border-right: solid 1px #d9d9d9;
        clear: none;
        &:nth-child(3n) { border: none; }
      }
    }
  }

}

// Fix scrollbar for findastic search
.findastic-overlay-search--open {
  overflow: hidden;
}

.findastic-overlay-search-wrapper {

  &:after {
    content: '';
    display: block;
    position: fixed;
    opacity: 0;
    transition: opacity 0.1s ease;
    pointer-events: none;
    background: rgba(#fff, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  &.loading {
    &:after {
      pointer-events: all;
      opacity: 1;
    }
  }

  overflow: auto;
  background: white;
  position: absolute;

  body.findastic-overlay-search--open & {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  body.findastic-overlay-search--opening &,
  body.findastic-overlay-search--closing & {
    overflow-y: hidden!important;
  }

  @include breakpoint($desk) {

  }

  .findastic-facets-results-wrapper {
    @include pie-clearfix;

    .findastic-facets-results-tabs {
      @include pie-clearfix;
      margin: 10px 0;

      @include breakpoint($desk) {
        display: none;
      }

      a {
        display: block;
        width: 50%;
        float: left;
        text-align: center;
        position: relative;
        background-color: $brand-primary;
        color: #fff;
        text-decoration: none;
        z-index: 1;
        font-size: 12px;
        font-weight: 500;
        line-height: 50px;
        margin: 0;
        cursor: pointer;

        &.active {
          background-color: darken($brand-primary, 5%);

          &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-width: 0 6px 5px;
            border-style: solid;
            border-color: transparent transparent #fff;

            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -6px;
          }
        }
      }
    }

    @include breakpoint(0 $desk) {
      &.findastic-filters-active {
        .findastic-overlay-search-results {
          display: none;
        }
      }

      &.findastic-results-active {
        .findastic-facets {
          display: none;
        }
      }
    }

    @include breakpoint($desk) {
      .findastic-facets,
      .findastic-overlay-search-results {
        display: block;
      }
      .findastic-facets {
        width: (100%/3);
        float: left;
      }

      .findastic-overlay-search-results {
        width: (100%/3) * 2;
        float: right;
      }
    }

    @include breakpoint($wide) {

      .findastic-facets {
        width: 25%;
      }

      .findastic-overlay-search-results {
        width: 75%;
      }
    }

    .block--findastic {
      &.active {
        .block__content {
          max-height: 300px;
          overflow-y: auto;
        }
      }
    }
  }

}

body.findastic-overlay-search--opening,
body.findastic-overlay-search--closing {
  overflow: scroll!important;
}

form#search-form {
  .page-search & {
    display: none;
  }
}

div.findastic-suggestions {
  margin: 0 auto 15px;
  width: 100%;
  font-size: 0;
  position: relative;
  z-index: 0;
  height: 65px;
  overflow: hidden;
  display: none;

  @include breakpoint($tab) {
    display: block;
    margin-top: 5px;
  }

  @include breakpoint($desk) {
    display: block;
    margin: 0 0 0 295px;
    width: 540px;

    @include breakpoint($wide) {
      width: 680px;
    }
  }

  h3 {
    color: #333333;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    position: absolute;
    @include letter-spacing(-25);
    z-index: 0;
    top: 0;
    left: 0;
    display: none;

    @include breakpoint($tab) {
      display: block;
    }
  }

  ul {
    display: block;
    width: 100%;
    list-style: none;
    font-size: 0;
    padding: 0;
    margin: 0 -5px;

    @include breakpoint($tab) {
      margin: 0;
      padding: 0 0 0 145px;
    }

    li {
      font-size: 14px;
      font-weight: 500;
      list-style: none;
      color: white;
      background: #28bdb3;
      line-height: 30px;
      padding: 0 10px;
      margin: 0 5px 5px;
      @include border-radius(3px);
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      max-width: 95%;
      overflow: hidden;
      text-overflow: elipsis;

      &:hover {
        background: #13958c;
      }

      &:after {
        @extend %font-icon;
        @extend %font-icon-arrow-right;
        font-size: 10px;
        color: white;
        margin: 0 0 0 10px;
        display: none;

        @include breakpoint($tab) {
          display: inline-block;
        }
      }
    }
  }
}

.findastic-no-results-text {
  text-align: center;
  font-size: 18px;
  margin-top: 100px;
}

body.findastic-overlay-search--open {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.findastic-speech-recognition-button {
  display: none;
  width: 40px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  font-size: 25px;
  position: absolute;
  right: 30px;
  top: 16px;
  text-decoration: none;
  outline: none;
  transition: color 0.2s ease;

  body.findastic-speech-enabled & {
    display: inline-block;
  }

  &:focus,
  &:active {
    text-decoration: none;
    color: $brand-primary;
  }

  &:hover {
    text-decoration: none;
    color: darken($brand-primary, 10%);
  }

  &:before {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: '';
    display: none;
    width: 48px;
    height: 48px;
    background: #efefef;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: -4px;
    border-radius: 100%;
    animation: blink 0.7s infinite;
    -webkit-animation: blink 0.7s infinite;
  }

  &.listening {
    color: #af3800;

    &:after {
      display: block;
    }
  }
}

.findastic-overlay-search--open .findastic-item.taxonomy_term {
  position: relative;
  z-index: 0;

  min-height: 345px;

  @include breakpoint($tab) {
    min-height: 398px;
  }

  @include breakpoint($desk) {
    min-height: 352px;
  }

  .brand-target {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .taxonomy-term--brand {
    padding: 10px 20px 0 20px;
    width: 100%;
    min-height: 376px;
  }
  .field--name-field-image {
    text-align: center;
  }

  .term-name {
    color: $text-color;
    padding: 17px 10px 5px 10px;
    font-size: 12px;
    max-height: 84px;
    overflow: hidden;
    line-height: 16px;
    color: #333333;
    float: left;
    font-size: 12px;
    font-weight: bold;
    height: 65px;
    text-align: left;
  }

  .link-to-taxonomy-term {
    text-align: center;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-bottom: 4px solid #28bdb3;
    height: 50px;
    a {
      @include letter-spacing(-25);
      display: block;
      background: transparent;
      border: none;
      color: $brand-primary;
      font-size: $font-size-small;
      font-weight: bold;
      line-height: 46px;
      padding: 0;
      position: relative;
      text-transform: uppercase;
      width: 100%;
      text-decoration: none;
      border-top: 1px solid #d9d9d9;
      transition: color 0.1s,  background 0.1s, border-top 0.1s;

      // &:before {
      //   @extend %font-icon;
      //   position: absolute;
      // }

      // &:before {
      //   @extend %font-icon-basket;
      //   font-size: 25px;
      //   left: 22px;
      //   top: 9px;
      // }

      &:hover {
        background: $brand-primary;
        color: #fff;
        border-top: 1px solid $brand-primary;
      }
    }
  }

  .products-of-category {
    font-size: 16px;
    color: $brand-primary;
    position: absolute;
    bottom: 55px;
    left: 10px;
    right: 10px;
    text-align: center;
    span {
      font-weight: bold;
    }
  }

  .brand-no-image {
    color: $brand-primary;
    padding: 82px 10px 5px 10px;
    font-size: 22px;
    text-align: center !important;
  }
}

@keyframes blink {
  0% { transform: scale(0.8); }
  50% { transform: scale(1); }
  100% { transform: scale(0.8); }
}

@-webkit-keyframes blink {
  0% { -webkit-transform: scale(0.8) }
  50% { -webkit-transform: scale(1) }
  100% { -webkit-transform: scale(0.8) }
}
