.main-header {
  position: relative;
  z-index: 12;

  body.mega-menu-active & {
    z-index: 9;
  }
}

.main-nav {
  @include pie-clearfix;
  background: white;
  border-top: 2px solid $brand-primary;
  margin: 0 10px;
  position: relative;
  z-index: 11;

  @include breakpoint($tab) {
    border-top: 4px solid $brand-primary;
    margin: 0 auto;

    @include breakpoint($desk) {
      width: $container-desktop;

      @include breakpoint($wide) {
         width: $container-large-desktop;
      }
    }
  }
}

.block--system-main-menu {
  position: relative;
  z-index: 499;

  // @include breakpoint($desk) {
  //   &:before,
  //   &:after {
  //     content: '';
  //     display: block;
  //     width: 20px;
  //     height: 60px;
  //     position: absolute;
  //     top: 0;
  //     background: #fff;
  //   }

  //   &:before {
  //     left: -20px;
  //   }

  //   &:after {
  //     right: -20px;
  //   }
  // }

  @include breakpoint($desk) {
    border-bottom: 1px solid $default-border-color;
    background: white;

    & > div.main-menu-outter {
      margin-left: -20px;
      margin-right: -20px;
      position: relative;
      z-index: 0;

      & > div.main-menu-wrapper {
        display: table;
        width: 100%;

        & > ul.menu {
          display: table-row;

          & > li {
            display: table-cell;
            vertical-align: middle;

            &.weekly-offers {
              width: 100px;

              @include breakpoint($wide) {
                width: 150px;
              }

              & > a {
                @include pie-clearfix;
                color: $brand-secondary;
                width: 96px;

                @include breakpoint($wide) {
                  width: 150px;
                }

                span {
                  display: block;
                  float: left;
                  width: 70px;
                }
              }
            }

            &.black-friday {
              & > a {
                @include breakpoint($desk) {
                  background: black;
                  color: white;
                }
              }
            }

            & > a,
            & > .menu-item-title > a:not(.subopen) {
              display: block;
              text-align: center;
              height: 59px;
              text-decoration: none;
              font-size: 11px;
              color: #333333;
              @include letter-spacing(-10);
              font-weight: bold;
              outline: none;
              padding: 0 9px;

              @include breakpoint($wide) {
                padding: 0 18px;
              }

              &.over {
                color: #28bdb3;
              }

              & > span {
                display: block;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            &.expanded > a.over,
            &.expanded > .menu-item-title > a.over:not(.subopen) {
              background: $brand-primary;
              color: white;
              text-decoration: none;
            }

            &.expanded.over > div.menu-wrapper {
              display: block;
            }

            & > div.menu-wrapper {
              @include pie-clearfix;
              display: none;
              background: white;
              position: absolute;
              z-index: 499;
              top: 59px;
              left: 0;
              width: 100%;

              &:before {
                content: "";
                border-top: 1px solid $default-border-color;
                position: absolute;
                top: 0;
                left: 20px;
                right: 20px;
              }

              & > div.level-1-wrapper {
                @include pie-clearfix;
                width: 100%;
                padding: 0 20px 20px;

                &:before {
                  content: "";
                  background: #f5f5f5;
                  // @include gradient-horizontal(#fff, #f5f5f5, 15%);
                  width: 320px;
                  position: absolute;
                  z-index: -1;
                  top: 0;
                  left: 0;
                  bottom: 0;
                }


                & > h2 {
                  color: $text-color;
                  margin: 0;
                  font-size: 20px;
                  line-height: 22px;
                  width: 280px;

                  span {
                    padding: 14px 0;
                    display: block;
                  }

                  &:after {
                    content: '';
                    display: block;
                    width: 40px;
                    height: 4px;
                    background: $brand-primary;
                  }
                }

                & > ul.depth-2 {
                  display: block;
                  float: left;
                  width: 280px;
                  border-top: 1px solid $default-border-color;

                  & > li {
                    display: block;
                    border-bottom: 1px solid $default-border-color;

                    & > a,
                    & > .menu-item-title > a:not(.subopen) {
                      display: block;
                      color: #28bdb3;
                      font-size: 12px;
                      font-weight: bold;
                      padding: 10px 0;
                      position: relative;
                      @include letter-spacing(-10);
                      z-index: 2;
                      text-decoration: none;
                    }

                    &.over > a,
                    &.over > .menu-item-title > a:not(.subopen) {
                      color: black;
                    }

                    &.over > a:after,
                    &.over > .menu-item-title > a:not(.subopen):after {
                      content: "";
                      width: 0;
                      height: 0;
                      border-top: 10px solid transparent;
                      border-bottom: 10px solid transparent;
                      border-right: 10px solid white;
                      position: absolute;
                      z-index: 0;
                      right: -20px;
                      top: 50%;
                      transform: translateY(-50%);
                    }

                    & > ul.depth-3 {
                      display: none;
                      position: absolute;
                      z-index: 1;
                      top: 0;
                      left: 0;
                      padding: 55px 15px 20px 354px;
                      height: 100%;
                      width: 100%;
                      -webkit-column-count: 2;
                      -moz-column-count: 2;
                      -ms-column-count: 2;
                      -o-column-count: 2;
                      column-count: 2;

                      li {
                        -webkit-column-break-inside: avoid;
                        break-inside: avoid-column;
                        -webkit-margin-before: 0;
                        -webkit-margin-after: 0;

                        a {
                          line-height: 15px;
                          @include letter-spacing(-10);
                          font-weight: 500;
                          font-size: 12px;
                          color: #333333;
                          text-decoration: none;
                          padding: 10px 0;
                          display: block;
                          float: none;
                          border-bottom: 1px solid $default-border-color;

                          &:hover {
                            color: #28bdb3;
                          }
                        }

                        ul {
                          display: none!important;
                        }
                      }
                    }

                    &.over > ul.depth-3 {
                      display: block;
                    }
                  }
                }
              }

              &.has-bean > div.level-1-wrapper > ul.depth-2 > li > ul.depth-3 {
                padding-right: 350px;
              }
            }
          }
        }
      }
    }
  }
}

.menu-bean-wrapper {
  display: none;
  position: relative;
  z-index: 4;
  float: right;

  img {
    display: none;
  }

  @include breakpoint($desk) {
    display: block;


    img {
      display: inline;
    }
  }

  .menu-bean {
    display: none;
    background: #fff;
    width: 315px;
    border: 4px solid $brand-primary;
    position: relative;
    z-index: 0;
    margin: 0;

    &.active {
      display: block;
    }

    .contextual-links-wrapper,
    .block__title {
      display: none;
    }

    .bean-title {
      width: 100%;
      padding: 10px;
      position: absolute;
      bottom: 0;

      h2 {
        background: white;
        opacity: 0.9;
        position: relative;
        padding: 25px 15px;
        margin: 0;

        span {
          color: $text-color;
          opacity: 1;
          font-size: 14px;
        }
      }
    }
  }
}

.block--views-popular-brands-block {
  @extend .container;
  position: absolute;
  z-index: 0;
  left: -20px;
  background: white;
  line-height: 50px;
  overflow: hidden;
  transition: bottom 0.2s linear, height 2s linear;
  height: 50px;
  bottom: -50px;
  text-transform: uppercase;

  display: none;

  @include breakpoint($desk) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .scrolled:not(.page-checkout) & {
    bottom: 0;
  }

  .block__title {
    display: inline-block;
    color: $brand-secondary;
    margin: 0;
    font-size: 11px;
    width: 130px;
    white-space: nowrap;
  }

  .block__content {
    flex-grow: 1;

    .brand-name a {
      color: #999999;
      display: block;
      font-size: 11px;
      font-weight: 500;
      height: 100%;
      line-height: 15px;
      width: 100%;
    }
  }

  .view-popular-brands {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .view-content {
    display: inline-block !important;
    position: relative;
    height: 50px;

    @include breakpoint($desk) {
      margin: 0 35px !important;
      flex-grow: 1;
      max-width: 480px;
    }

    @include breakpoint($wide) {
      max-width: 740px;
    }
  }

  .brand-next {
    cursor: pointer;
    position: absolute;
    z-index: 5;
    top: 0;

    @include breakpoint($desk) {
      right: -15px;

      @include breakpoint($wide) {
        right: -25px;
      }
    }

    &:after {
      @extend %font-icon;
      @extend %font-icon-arrow-right;
      font-size: 13px;
      color: $text-color;
      width: 20px;
      font-weight: bold;
      vertical-align: middle;
    }
  }

  .brand-prev {
    cursor: pointer;
    position: absolute;
    z-index: 5;
    top: 0;

    @include breakpoint($desk) {
      left: -10px;

      @include breakpoint($wide) {
        left: -15px;
      }
    }

    &:after {
      @extend %font-icon;
      @extend %font-icon-arrow-left;
      font-size: 13px;
      color: $text-color;
      width: 20px;
      font-weight: bold;
      vertical-align: middle;
    }
  }

  .view-footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;

    a {
      &.ns-menu-link {
        font-size: 11px;
        font-weight: 500;
        line-height: 50px;
        color: $brand-primary;
        white-space: nowrap;
        margin-left: 15px;
      }

      &.ns-all-brands-link {
        font-size: 11px;
        font-weight: 500;
        line-height: 50px;
        white-space: nowrap;
        color: $brand-secondary;
        padding-right: 20px;
        position: relative;

        &:after {
          @extend %font-icon;
          @extend %font-icon-arrow-right-2;
          display: inline-block;
          font-weight: 500;
          position: absolute;
          top: 0;
          right: 5px;
          font-size: 9px;
          line-height: inherit;
        }

        &:hover:after {
          text-decoration: none;
        }
      }
    }
  }

  .brand-carousel {
    font-size: 0;
  }

  .views-row {
    display: inline-block;
    width: 80px;
    text-align: center;
    vertical-align: middle;

    @include breakpoint($desk) {
      width: 98px;

      @include breakpoint($wide) {
        width: 83px;
      }
    }
  }
}

// Mega menu animations and overlay
.mega-menu-overlay {
  background: black;
  z-index: 12;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-999999px);
  // transition: opacity 0.1s, transform 0s ease 0.5s;
  display: none;

  @include breakpoint($desk) {
    display: block;
  }

  body.mega-menu-active & {
    pointer-events: all;
    transform: translateX(0);
    // transition: opacity 0.1s ease 0.05s, transform 0s ease 0s;
    opacity: 0.5;
  }
}

li.weekly-offers > a:before {
  @extend %font-icon;
  @extend %font-icon-offers;
  font-size: 30px;
  margin-right: 10px;
  float: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  @include breakpoint($wide) {
    display: block;
  }
}
