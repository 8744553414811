
#zevioo-reviews,
.zevioo-rating {
  --font-family-helvetica: $font-family;
  --zeviooColor: $brand-primary;
  --darkGray: #333;
  --gray: #666;
  --softBlack: $brand-primary;
  --softGray: #f5f5f5;
  --starSelected: #ffb700;
}

.zevioo-rating {
  display: inline-block;
  margin-right: 10px;
  transform: translateY(-1px);

  .zevioo-rating-counter {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 700;

    &:before {
      content: "(";
      display: inline-block;
      margin-right: 2px;
    }

    &:after {
      content: ")";
      display: inline-block;
    }
  }

  .zevioo-stars-container {
    transform: translateY(1px);

    svg {
      stroke-opacity: 0;
      height: 15px;
      margin-top: 3px;
    }
  }


  .zevioo-stars-down svg {
    fill: var(--starSelected);
  }

  .zevioo-rating-value {
    display: inline-block;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 700;
    transform: translateY(2px);
  }
}

#zevioo-reviews {


  img.zevioo-logo {
    transform: translateY(-3px);
  }

  .zevioo-star-ratings-top {
    color: #ffb700;
  }

  .zevioo-rating__avg {
    .zevioo-avg-text,
    .zevioo-counter__text,
    span {
      color: #333;
    }

    .zevioo-avg-text {
      font-size: 20px;
    }
  }

  .zevioo-label-small {
    transform: translateY(-5px);
  }

  .stars-svg,
  .stars-svg__big {
    width: 20px;
    stroke-opacity: 0;
  }

  .quality-choosen svg,
  .star-choosen svg,
  .value-choosen svg {
    fill: var(--starSelected);
  }

  .zevioo-form-input {
    border-radius: 0;
    background: #fff;
    border: none;
    margin-top: 5px;
    outline: 0;

    &:focus {
      box-shadow: 0 0 0 1px rgba(#000, .2);
    }
  }

  select.zevioo-select__first {
    opacity: 1;
  }

  .zevioo-form {
    background: var(--softGray);
    padding: 20px 25px;
  }

  .zevioo-reviews-list .zevioo-single-review {
    border-radius: 0;
  }

  .zevioo-product-score-bar,
  .zevioo-helpful-title {
    display: block;
  }

  .zevioo-helpful {
    transform: translateY(10px);
  }

  .zevioo-submit__terms input[type="checkbox"] {
    width: auto;
  }
}


.node--product--teaser {
  .field-name-field-promotion-flag,
  .field--name-field-promotion-flag {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .block--front-page-promo-products &,
  .block--quicktabs-mega-offers-block & {
    background: white;
    padding: 15px 10px;
    margin: 0 10px 5px;
    margin-bottom: 20px;
  }

  .product-header {
    overflow: hidden;
    position: relative;

    a.product-image {
      margin-bottom: 10px;
      display: block;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &:hover a.product-image img {
      backface-visibility: hidden;
      transition: opacity 0.3s ease 0s;

      @include opacity(0.2);
    }

    .product-info {
      height: 100px;
      width: 100%;
      position: relative;
      z-index: 1;
      margin: 0 0 20px;

      .product-basic {
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        right: 0;

        a {
          text-decoration: none;
        }
      }
    }

    .product-bar {
      @include pie-clearfix;

      height: 40px;
      position: absolute;
      display: table;
      width: 100%;
      z-index: 0;
      top: -50px;
      left: 0;
      right: 0;

      .product-tags {
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        position: absolute;
        width: 100%;
        height: 100%;

        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          font-size: 0px;
        }

        li {
          list-style: none;
          display: inline-block;
          width: 40px;
          height: 40px;
          background: $brand-primary;

          @include border-radius(50%);

          margin: 0 5px 0 0;
          vertical-align: middle;
          color: white;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .field--name-field-rating {
        font-size: 0;
        display: table-cell;
        vertical-align: bottom;
        opacity: 1;
        text-align: right;
        padding-bottom: 3px;

        .field__items {
          display: inline-block;

          span {
            &:after {
              font-size: 13px !important;
            }
          }

          .star .off:after {
            color: #999999;
          }
        }
      }

      .commerce-product-extra-field-flag-wishlist {
        font-size: 0;
        display: table-cell;
        vertical-align: bottom;
        opacity: 1;
        text-align: right;
        min-width: 20px;

        .flag-wrapper {
          display: inline-block;
        }

        .flag-throbber {
          display: none;
        }

        a {
          backface-visibility: hidden;
          transition: opacity 0.2s ease;
          opacity: 0.8;
          display: block;
          width: 20px;

          &:after {
            @extend %font-icon;

            @extend %font-icon-wishlist-heart;

            font-size: 18px;
            color: #999999;
            text-decoration: none;
            padding-top: 2px;
          }

          &:hover {
            text-decoration: none;
            opacity: 1;
          }

          &.unflag-action:after {
            color: $brand-primary;
          }
        }
      }
    }

    h3 {
      margin: 0 0 10px;
      text-align: left;

      @include letter-spacing(-25);

      overflow: hidden;
      max-height: 55px;
      min-height: 55px;
      backface-visibility: hidden;
      transition: max-height 0.3s ease 0s;
      display: block;
      color: #333;
      font-size: 12px;
      font-weight: bold;
      line-height: 18px;
    }

    &:hover h3 {
      transition: max-height 0s ease 0s;
      max-height: 108px;
    }

    .product-extra {
      clear: both;

      @include pie-clearfix;

      height: 40px;
      display: table;
      width: 100%;
    }

    .commerce-product-field-commerce-price {
      text-align: right;
      display: table-cell;
      vertical-align: bottom;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          color: #28bdb3;
          font-weight: bold;
          font-size: 24px;
          line-height: 22px;

          &.list {
            color: #999999;
            font-size: 14px;
            line-height: 18px;
            text-decoration: line-through;
          }
        }
      }

      table {
        padding: 0;
        margin: 0;
        display: block;
        list-style: none;

        tbody {
          display: block;
        }

        tr {
          display: list-item;
          color: #28bdb3;
          font-weight: bold;
          font-size: 24px;
          line-height: 22px;

          &.commerce-price-savings-formatter-list {
            color: #999999;
            font-size: 14px;
            line-height: 18px;
            text-decoration: line-through;
          }
        }

        td {
          display: block;
          text-align: right;

          &.price-amount {
            color: inherit;
            font-size: inherit;
            padding: 0;
          }
        }
      }
    }

    .field--name-field-product-availability,
    .field-name-field-product-availability {
      text-align: left;
      display: table-cell;
      vertical-align: bottom;

      .field__item,
      span {
        font-size: 12px;
        display: block;
        line-height: 15px;
        font-weight: bold;
        color: $brand-primary;

        &.available:before {
          @extend %font-icon;

          @extend %font-icon-tick-availability;

          display: inline-block;
          font-size: 18px;
          font-weight: normal;
          color: $brand-primary;
          margin-right: 3px;
          height: 13px;
          vertical-align: bottom;
          position: relative;
          top: -1px;
        }

        &.express-availability {
          color: #28bdb3;
          cursor: help;
        }
      }

      &.not-available {
        span {
          color: #f24333;
        }
      }

      &.neutral-available {
        span {
          color: #FE941D;
        }
      }
    }

    .field--name-body,
    .field-name-body {
      clear: both;
      line-height: 20px;
      font-size: 14px;
      color: #333333;
      backface-visibility: hidden;
      transition: max-height 0.3s ease 0s;
      max-height: 0px;
      overflow: hidden;
      text-align: left;

      .field__items {
        padding: 10px 0 0;
      }
    }

    &:hover .field--name-body,
    &:hover .field-name-body {
      max-height: 90px;
    }

    a.product-body-link {
      text-decoration: none;
    }

    a.product-link {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }

  .product-footer {
    height: 50px;

    .form-submit {
      @include letter-spacing(-25);

      background: transparent;
      border: none;
      color: $brand-primary;
      font-size: $font-size-small;
      font-weight: bold;
      line-height: 46px;
      padding: 0 0 0 33px;
      position: relative;
      text-transform: uppercase;
      width: 100%;
      border-top: 1px solid #d9d9d9;
      transition: color 0.1s,  background-color 0.1s, border-top 0.1s;
      display: block;
      text-decoration: none;
      text-align: center;
      border-bottom: 4px solid #28bdb3;

      &:before {
        @extend %font-icon;

        position: absolute;
      }

      &:before {
        @extend %font-icon-basket;

        font-size: 25px;
        left: 22px;
        top: 9px;
      }

      &:hover {
        background-color: $brand-primary;
        color: #fff;
        border-top: 1px solid $brand-primary;
      }

      &.ajax-loading {
        background-color: $brand-primary;

        @extend %loading-spinner-white;

        background-position: 10px 50%;
        background-size: 40px;
        color: #fff;
        border-top: 1px solid $brand-primary;

        &:before {
          display: none;
        }
      }
    }
  }

  .zevioo-rating {
    .zevioo-stars-container {
      transform: translateX(-30px);
      pointer-events: none;
    }

    .zevioo-rating-value,
    .zevioo-rating-counter {
      display: none;
    }
  }
}

// Custom stock notifications css
.field--type-commerce-product-reference {
  position: relative;
  z-index: 1;
}

.node--product--full {
  a.stock-notification {
    text-align: center;
    display: block;
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 500;

    &:hover {
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }

  .product-media {
    position: relative;
    z-index: 1;
  }

  .product-info {
    position: relative;
    z-index: 2;
  }
}

.node--product--teaser {
  a.stock-notification {
    letter-spacing: -0.025em;
    background: transparent;
    border: none;
    color: #28bdb3;
    font-size: 14px;
    font-weight: bold;
    line-height: 46px;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    border-top: 1px solid #d9d9d9;
    transition: color 0.1s, background-color 0.1s, border-top 0.1s;
    display: block;
    text-decoration: none;
    text-align: center;
    border-bottom: 4px solid #28bdb3;

    &:hover {
      background-color: #28bdb3;
      color: #fff;
      border-top: 1px solid #28bdb3;
    }
  }

  .custom-stock-notifications-customer-form {
    position: absolute;
  }
}

.custom-stock-notifications-customer-form {
  display: none;
  position: fixed;
  box-shadow: 0 0 4px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.24);
  max-width: 380px;
  top: 50%;
  left: 50%;
  font-size: 16px;
  padding: 25px;
  background: #fff;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 449;

  div {
    display: block !important;
  }

  input.form-text {
    font-size: 16px;
    border: 1px solid #e6e6e6;
  }
}

.stock-pop-up {
  position: relative;
  text-align: left;

  h2 {
    margin: 0 0 10px;
    position: relative;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6e6e6;

    span {
      position: absolute;
      font-weight: normal;
      top: 0;
      right: 0;
      line-height: 10px;
      cursor: pointer;
    }
  }

  .form-submit {
    background-color: #28bdb3;
    border: none;
    color: white;
    padding: 15px;
    font-size: 16px;
    text-transform: uppercase;
    outline: none;
    border-radius: 0;
    width: 100%;
    font-weight: 500;

    &:hover {
      background-color: #24a89f;
    }
  }
}
