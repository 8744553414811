.shipping-goal,
.shipping-goal-reached {
  .has-no-line-items & {
    display: none;
  }

  border-radius: (2px);
  box-shadow: 0 0 4px rgba(#000, 0.12), 0 4px 6px rgba(#000, 0.24);
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 50px;
  max-width: 80%;
  padding: 20px 20px 25px;
  width: 500px;

  .explanation {
    padding: 0 10px;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 15px;

    span {
      color: $brand-primary;
      font-weight: bold;
      font-size: 23px;
    }
  }

  .bar {
    height: 10px;
    margin: 10px auto;
    background: #e6e6e6;
    position: relative;
    border-radius: 5px;
    width: 400px;
    max-width: 100%;
    font-size: 0;
    transform: translateZ(0);
    overflow: hidden;

    span {
      background: $brand-primary;
      height: 100%;
      width: 100%;
      display: inline-block;
      border-radius: 5px;
      transform: translateX(-100%);
      backface-visibility: hidden;
    }
  }

  .bar-text {
    position: relative;
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    @include pie-clearfix;

    span {
      // position: absolute;


      &.left-limit {
        float: left;
        color: $brand-primary;
      }

      &.current-value {
        color: $brand-primary;
      }

      &.right-limit {
        float: right;
        color: #e6e6e6;
      }
    }
  }

  .disclaimer {
    font-size: 0.7em;
    margin-top: 10px;
  }
}

.shipping-goal-reached {

  .bar {
    background: $brand-primary;
  }

  .bar-text  {
    span {
      &.right-limit {
        color: $brand-primary;
      }
    }
  }
}
