// Load custom fonts.
@font-face {
  font-family: "FuturaNeu";
  src: url("../fonts/pffuturaneu-book_3-webfont.eot");
  src: url("../fonts/pffuturaneu-book_3-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/pffuturaneu-book_3-webfont.svg#FuturaNeu") format("svg"),
    url("../fonts/pffuturaneu-book_3-webfont.woff") format("woff"),
    url("../fonts/pffuturaneu-book_3-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FuturaNeu";
  src: url("../fonts/pffuturaneu-medium-webfont.eot");
  src: url("../fonts/pffuturaneu-medium-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/pffuturaneu-medium-webfont.svg#FuturaNeu") format("svg"),
    url("../fonts/pffuturaneu-medium-webfont.woff") format("woff"),
    url("../fonts/pffuturaneu-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "FuturaNeu";
  src: url("../fonts/pffuturaneu-semibold_3-webfont.eot");
  src: url("../fonts/pffuturaneu-semibold_3-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/pffuturaneu-semibold_3-webfont.svg#FuturaNeu") format("svg"),
    url("../fonts/pffuturaneu-semibold_3-webfont.woff") format("woff"),
    url("../fonts/pffuturaneu-semibold_3-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}



body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  line-height: 1.25;
}

p {
  color: $text-color;
}

// Page title.
h1 {
  font-size: $font-size-h1;

  .node-type-article & {
    display: none;
  }
  .node--article--full &,
  .page-blog-archive & {
    color: $headings-color;
    display: block;
    font-size: 36px;
    line-height: 36px;
    margin: 0 0 13px;
    text-align: left;
  }

  .page-checkout &,
  .page-cart &,
  .page-user &,
  .page-wishlist &,
  .section-newsletter-thank-you &,
  .page-track & {
    margin: 0;
    text-align: center;
    font-weight: bold;
    color: $text-color;
  }

  .page-user & {
    border-bottom: solid 1px #e6e6e6;
    margin: 18px 0 48px;
    padding-bottom: 13px;
  }

  .page-checkout-complete & {
    margin-bottom: 30px;
  }

  .page-user-orders & {
    margin-bottom: 30px;
  }

  // For static pages
  .node-type-page &,
  .node-type-webform & {
    color: $headings-color;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 28px;
    margin-top: 0;
  }
}

h2 {
  color: $headings-color;
  font-size: $font-size-h2;
}

h3 {
  color: $headings-color;
  font-size: $font-size-h3;
}

h4 {
  color: $subheadings-color;
  font-size: $font-size-h4;
}

h5 {
  color: $subheadings-color;
  font-size: $font-size-h5;
}

h6 {
  color: $subheadings-color;
  font-size: $font-size-h6;
}

.hide-pseudo {
  &:after {
    display: none!important;
  }

  &:before {
    display: none!important;
  }
}
