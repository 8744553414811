.commerce_add_to_cart_confirmation_overlay {
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.messages--commerce-add-to-cart-confirmation {
  position: fixed;
  left: 50%;
  z-index: 10001;
  height: 830px;
  max-height: 100%;
  top: 10px;
  width: 95%;
  max-width: 890px;
  transform: translateX(-50%) translateZ(0);
  overflow-y: visible;
  padding-bottom: 55px;

  button.close {
    color: white;
    height: 16px;
    opacity: 1;
    position: relative;
    text-shadow: none;
    width: 16px;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 16px;
      @extend %font-icon;
      @extend %font-icon-close;
    }

    span {
      font-size: 0;
    }

    &:hover {
      color: darken(white, 5%);
    }
  }

  .message-inner {
    max-width: 890px;
    margin: 0 auto;
    margin-top: 35px;
    background: white;
    position: relative;
    padding-bottom: 30px;
    overflow-y: auto;
    max-height: 100%;

    span.close {
      display: none;
    }

    .added-product-title {
      color: $brand-primary;
      padding: 20px;
      text-align: center;
      background: $well-bg;
      font-weight: bold;
      font-size: 24px;
    }

    .view-id-confirm_message_product_display {
      padding-left: 0;

      .views-field-commerce-product {
        .content {
          @include clearfix;
          text-align: center;

          @include breakpoint($tab) {
            text-align: left;
          }

          .field_product_image {
            width: 325px;
            max-width: 100%;
            padding: 30px 25px 30px 30px;
            text-align: center;
            margin: 0 auto;

            @include breakpoint($tab) {
              height: 310px;
              float: left;
            }
          }

          .right {
            width: 340px;
            max-width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            margin: 30px auto 0 auto;

            @include breakpoint($tab) {
              margin: 30px 0 0 0;
              float: left;

              @include breakpoint($desk) {
                width: 350px;
              }
            }


            .sku {
              font-weight: 500;
              font-size: $font-size-small;

              .commerce-product-sku-label {
                display: inline-block;
                text-transform: uppercase;
              }
            }

            .title {
              color: $brand-primary;
              font-size: 20px;
              font-weight: 500;

              .commerce-product-title-label {
                display: none;
              }
            }

            .commerce_price {

              .field {
                font-size: 20px;
                &:before {
                  content: "ΤΙΜΗ WEB";
                  color: #999999;
                  font-size: $font-size-base;
                }

                table {
                  margin: 0 auto;

                  @include breakpoint($tab) {
                    margin: 0;
                  }
                }

                td {
                  padding: 0;
                  text-align: center;

                  @include breakpoint($tab) {
                    text-align: left;
                  }
                }

                .commerce-price-savings-formatter-list {
                  color: #999999;

                  td {
                    line-height: 1.6em;
                    text-decoration: line-through;
                    font-size: 20px;
                  }
                }

                .commerce-price-savings-formatter-savings {
                  display: none;
                }

                .commerce-price-savings-formatter-price {
                  font-weight: 500;

                  td {
                    line-height: 1em;
                    color: $brand-secondary;
                    font-size: 28px;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }

      .views-field-quantity,
      .views-field-commerce-total {
        display: none;
      }
    }

    .button-wrapper {
      @include clearfix;
      z-index: 10;
      position: relative;
      text-align: center;
      direction: rtl;
      margin-top: 10px;
      display: block;
      margin: 0 auto;

      @include breakpoint($tab) {
        float: right;
        padding-right: 40px;
        margin-top: -100px;

        @include breakpoint($desk) {
          padding-right: 0;
          text-align: right;
        }
      }

      .button.continue {
        display: block;
        margin: 10px auto;
        direction: ltr;
        font-weight: 500;
        font-size: 18px;
        width: 260px;
        @include breakpoint($tab) {
          display: inline-block;
          width: 235px;
        }

        span {
          @extend %btn-back;
          cursor: pointer;
          font-weight: 500;
          position: relative;
          padding-left: ($font-size-base + 14);

          &:before {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .button.checkout {
        display: block;
        margin: 10px auto;
        direction: ltr;
        font-weight: 500;
        font-size: 18px;
        width: 300px;

        @include breakpoint($tab) {
          display: inline-block;
          margin-left: 20px;
          margin-right: 40px;
        }

        a {
          @extend %btn-cart;
          font-weight: 500;
          display: block;
          text-align: center;
          letter-spacing: 1.5px;
          max-width: none;
          width: 300px;


          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }

    h2.related {
      color: $brand-primary;
      line-height: 70px;
      border-top: 1px solid $default-border-color;
      text-align: center;
      margin-bottom: 0;
      margin-top: 20px;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 10px;
    }
    .view-related-products {
      margin-bottom: -20px;

      @include breakpoint($desk) {
        margin-bottom: 0;
      }

      .view-content > div {
        display: inline;
      }
      @include clearfix;
      text-align: center;

      .node-teaser {
        text-align: left;
        padding-bottom: 40px;
        display: block;
        margin: 0 auto;
        width: 270px;
        height: 320px;
        min-height: 0;

        @include breakpoint($tab) {
          width: 220px;
          box-sizing: border-box;
          margin: 0;
          display: inline-block;
          vertical-align: top;
          border-right: 1px solid $default-border-color;

          @include breakpoint($desk) {
            margin-bottom: 30px;
            width: 270px;
          }
        }

        .product-title {
          height: auto;
          min-height: 60px;
          margin-bottom: 15px;
        }

        .product-image {
          padding-top: 10px;
        }

        .product-price-title {
          font-size: $font-size-small;

          @include breakpoint($tab) {
            font-size: 12px;

            @include breakpoint($desk) {
              font-size: $font-size-small;
            }
          }
        }

        .product-list-price {
          font-size: 20px;

          @include breakpoint($tab) {
            font-size: $font-size-base;
            line-height: 15px;

            @include breakpoint($desk) {
              font-size: 20px;

            }
          }
        }

        .product-discounted-price {
          font-size: 28px;

          @include breakpoint($tab) {
            font-size: 20px;

            @include breakpoint($desk) {
              font-size: 28px;
            }
          }
        }

        .product-comparison {
          display: none;
        }

        .commerce-add-to-cart {
          .form-item {
            display: none;
          }
        }
      }

      .views-row-last .node-teaser {
        border-right: none;
      }

      .separator {
        display: block;
        width: 270px;
        margin: 0 auto;
        height: 1px;
        border-top: 1px solid $default-border-color;

        @include breakpoint($tab) {
          display: none;
        }
      }
    }
  }
}

body.popup-is-open {
  overflow: hidden;
}

.free-shipping-wrapper {
  padding: 30px 20px 25px;
  text-align: left;
  color: $brand-primary;

  .free-shipping-label {
    padding-left: 5px;
    margin-right: 40px;
    font-weight: 500;
    font-size: 12px;

    .i18n-el & {
      margin-right: 60px;
    }
  }

  .free-shipping-bar {
    position: relative;
    height: 16px;
    background: #f1f1f1;
    margin-right: 27px;
    border-radius: 20px 0px 0px 20px;
    padding: 4px 5px;
    margin-top: 10px;
  }

  .free-shipping-bar-value-wrapper {
    border-radius: 8px;
    overflow: hidden;
    transform: translateZ(0);
  }

  .free-shipping-bar-value {
    height: 8px;
    background: $brand-primary;
    border-radius: 8px;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .free-shipping-bar-icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #f1f1f1;
    position: absolute;
    right: -28px;
    top: -12px;
    color: $brand-primary;

    &:before {
      @extend %font-icon;
      @extend %font-icon-truck;

      font-size: 18px;
      position: absolute;
      top: 50%;
      left: 47%;
      transform: translate(-50%, -50%);
    }
  }

  .free-shipping-bar-label {
      background: $contrast-color;
      color: #fff;
      padding: 3px 7px;
      display: inline-block;
      font-weight: bold;
      font-size: 12px;
      position: absolute;
      right: -31px;
      top: -39px;
      z-index: 2;
      border-radius: 2px;

      .i18n-el & {
        right: -43px;
      }

      &:before {
        width: 1px;
        height: 1px;
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -6px;
        border-color: $contrast-color;
        border-bottom-width: 0;
        border-left-width: 5px;
        border-right-width: 5px;
        border-top-width: 6px;
        border-style: solid;
        border-left-color: transparent;
        border-right-color: transparent;
    }
  }

  .free-shipping-sub-label {
    font-size: 10px;
    font-weight: bold;
    margin-top: 10px;
    padding-left: 3px;
  }

  &.unlocked,
  &.unlocked-final {
    .free-shipping-bar:after {
      width: 10px;
      height: 8px;
      content: '';
      background: $brand-primary;
      display: block;
      position: absolute;
      right: 5px;
      top: 4px;
      z-index: 2;
    }
    .free-shipping-bar-icon {
      color: #fff;

      &:before {
        z-index: 2;
      }

      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        margin-top: 5px;
        margin-left: 5px;
        background: $brand-primary;
        z-index: 1;
      }
    }
  }

  &.unlocked {
    .free-shipping-bar-icon {
      &:after {
        animation-name: unlocked-free-shipping;
        animation-duration: 0.1s;
      }
    }
  }
}

@keyframes unlocked-free-shipping {
  0%   {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: none;
  }
}

.bounce {
    animation-name: bounce;
    transform-origin: center bottom;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-10px);}
  60% {transform: translateY(-5px);}
}
