.sticky-header {
  width: 100%;
  position: relative;
  // top: 0;
  z-index: 3;
  background: $well-bg;
  padding-top: 0;
  backface-visibility: hidden;

  // transition: width 0.2s ease, height 0.2s ease;

  @include breakpoint($desk) {
    // height: 180px;
    z-index: 4;
    .scrolled:not(.page-checkout) & {
      height: 145px;
    }

    body.admin-menu & {
      // margin-top: 30px;
    }
  }


  // We need to not display the header until we are ready to
  // and we animate it when we display it.
  transition: opacity 0.2s linear;

  // &:not(.ready) {
  //   opacity: 0;

  //   * {
  //     transition: none!important;
  //   }
  // }
}

.page-checkout {
  .sticky-header {
    // position: absolute !important;

    @include breakpoint($desk) {
      // position: fixed;
    }
    .l-header{
      // display: none;
    }
  }
  .main-nav {
    // display: none;
  }
}

.l-pusher {
  background: $well-bg;

  @include breakpoint($tab) {

    @include breakpoint($desk) {
      // padding-top: 180px;
    }
  }
}

.l-page {
  overflow: hidden;
  background: white;
  .node-type-product & {
    // overflow: hidden;
  }
  .not-front & {
    padding-top: 0;

    @include breakpoint($desk) {
      // padding-top: 50px;
    }
  }
}

.l-content {
  background: white;
}

.l-branding {
  float: left;
  z-index: 11;
  padding: 0;
  position: relative;
  left: -5px;
  position: relative;
  height: 80px;

  @include breakpoint($tab) {
    top: 0;
    height: 130px;
  }

  @include breakpoint($desk) {
    transition: height 0.3s linear, width 0.3s linear;
    top: 48px;

    .scrolled:not(.page-checkout) & {
      height: 81px;
      top: 9px;
      left: 0px;

      img {
        width: 185px;
      }
    }
  }

  @include breakpoint($wide) {
    top: 38px;
    height: 110px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    width: 165px;
    transition: width 0.3s linear;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint($tab) {
      width: 265px;
    }

    @include breakpoint($desk) {
      top: auto;
      transform: none;
      max-width: 240px; // We need width: 250px on desk and 265px on wide
      max-height: 79px;     // We use max-width to avoid animation on window resize

    }

    @include breakpoint($wide) {
      max-width: none;
      max-height: 86px;
    }
  }

  .site-slogan {
    display: none;
  }

  .page-checkout-shipping &,
  .page-checkout-billing & {
    // float: none;
    // text-align: center;
  }
}

// Header region.
.l-header {
  position: relative;
  .l-header--above {
    width: 100%;
    // margin-top: 5px;
    height: 0;
    text-align: right;
    opacity: 1;
    transition: opacity 1s ease, height 0.3s linear;
    display: none;

    @include breakpoint($desk) {
      display: block;
      height: auto;
    }

    .scrolled:not(.page-checkout) & {
      height: 0;
      opacity: 0;
      margin: 0;
    }

    .block {
      display: inline-block;
      vertical-align: middle;
      margin: 0 14px;

      &:first-child,
      &:last-child {
        margin-right: 0;
      }

      &:last-child {
        @include breakpoint($wide) {
          margin-right: -5px;
        }
      }

      &.flash-offer {
        font-size: 13px;
        font-weight: 500;
        border-radius: 20px;
        line-height: 25px;
        background-color: $brand-secondary;
        color: #fff;
        padding: 0 15px;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: -4px;
        min-width: 265px;
        text-align: center;

        p,a {
          color: #fff;
          margin: 0;
        }
      }

      &.block--feature-blog-header-track-link {
        margin-right: 0;
      }

      .header-track-link {
        display: inline-block;
        text-decoration: none;
        position: relative;
        font-size: 13px;
        padding: 0 10px 0 30px;
        background-color: $brand-primary;
        color: #fff;
        border-radius: 20px;
        line-height: 25px;
        font-weight: 500;

        &:before {
          @extend %font-icon;
          @extend %font-icon-track;
          position: absolute;
          width: 20px;
          display: block;
          top: 0;
          left: 4px;
          line-height: 25px;
        }
      }

      &.header-blog-link {
        background-color: #f48477;
        border-radius: 0 0 7px 7px;
        vertical-align: top;
        padding: 1px 7px 5px;
        text-decoration: none;

        span {
          display: block;
          text-align: center;
          color: #fff;
          line-height: 1.1;
          font-size: 18px;

          small {
            font-size: 13px;
          }
        }
      }
    }

    .l-region--header-above {
      max-height: 100%;
      overflow: visible;
      font-size: 0;

      .scrolled:not(.page-checkout) & {
        overflow: hidden;
      }
    }

    p {
      margin: 0;
    }
  }

  .l-header--content {
    @include pie-clearfix;
    position: relative;
    z-index: 10;
    height: 80px;
    padding-top: 0;

    @include breakpoint($tab) {
      height: 130px;

      @include breakpoint($desk) {
        padding-top: 6px;
        height: 86px;

        .scrolled:not(.page-checkout) & {
          padding-top: 0px;
          height: 81px;
        }
      }
    }

    .l-region--header {
      float: right;
      font-size: 0px;
      text-align: right;

      .block {
        font-size: $font-size-small;
        vertical-align: middle;
      }
    }
  }
}

// Phone Block
.block-callus {
  display: none;
  position: relative;

  @include breakpoint($desk) {
    display: block;
  }

  p,
  a {
    background: $brand-primary;
    padding: 0 10px 0 9px;
    border-radius: 20px;
    line-height: 25px;
    font-weight: 500;
    color: white;
    margin: 0;
    font-size: 14px;
    width: 131.97px;
    white-space: nowrap;
  }

  .label {
    width: 16px;
    line-height: 1;

    &:before {
      @extend %font-icon;
      @extend %font-icon-phone-fill;
      font-size: 12px;
      padding-right: 5px;
    }
  }

 .l-region--header & {
  width: 172px;
  padding: 0 10px;

  @include breakpoint($wide) {
    padding: 0 17px;
  }

  &:after {
    content: '';
    display: block;
    height: 16px;
    width: 1px;
    background: $darker-border-color;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
 }
}

.block-about-us {
  @include breakpoint($desk) {
    padding-top: 3px;
    // width: 84px;
  }
}

// Wishlist Block
.block--feature-wishlist-wishlist-link {
  position: relative;

  @include breakpoint($desk) {
    display: inline-block;
    padding-top: 3px;
  }

  .block__title {
    display: none;
  }

  .block__content {
    a {
      color: $text-color;
      text-decoration: none;
      font-family: $font-family;
      color: #676767;
      font-size: 10px;
      white-space: nowrap;

      &:before{
        @extend %font-icon;
        @extend %font-icon-wishlist-heart;
        color: #999999;
        font-size: 11.5px;
        padding-right: 5px;
        width: 16.5px;
      }

      &:hover {
        text-decoration: underline;

        &:before {
          text-decoration: none;
        }
      }
    }
  }

  .l-region--header & {
    &:after {
      content: '';
      display: block;
      height: 16px;
      width: 1px;
      background: $darker-border-color;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    a {
      font-size: 0;
      padding: 0 10px;

      @include breakpoint($wide) {
        padding: 0 21px;
      }


      &:before {
        width: 37px;
        height: 37px;
        line-height: 40px;
        text-align: center;
        background: $brand-primary;
        color: white;
        border-radius: 50%;
        padding: 0;
        font-size: 15px;
      }
    }
  }
}

// About Us Block
.block-about-us {
  .block__title {
    display: none;
  }

  .block__content {
    a {
      color: $text-color;
      text-decoration: none;
      font-family: $font-family;
      color: #676767;
      font-size: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
// Language Block
.block--lang-dropdown {
  @include breakpoint(0 $desk) {
    padding: 0 10px;
    height: 50px;
  }

  @include breakpoint($desk) {
    margin-left: -7px!important;
  }


  .block__content{
    width: 100%;
    position: relative;
    display: block;
    border-bottom: 1px solid #d9d9d9;

    @include breakpoint($desk) {
      width: 105px;
      height: 25px;
      border: none;
    }

    .i18n-en & {
      min-width: 120px;
    }

    .form-item {
      height: 50px;
      border: none;
      display: block;

      * {
        padding: 0;
      }

      @include breakpoint($desk) {
        height: 25px;
        line-height: 25px;
      }
    }

    .language-icon {
      padding: 0;
      width: 23px;
      height: 14px;
      position: absolute;
      right: 16px;
      top: 13px;
      transform: translateY(-50%);
      pointer-events: none;

      @include breakpoint(0 $desk) {
        width: 18px;
        right: 18px;
        top: 18px;
        transform: none;
      }
    }

    .selector {
      span {
        font-size: 0;

        @include breakpoint(0 $desk) {
          &:after {
            content: '';
            width: 30px;
            height: 30px;
            border: 2px solid #28bdb3;
            border-radius: 50%;
            position: absolute;
            top: 9px;
            right: 12px;
          }
        }

        @include breakpoint($desk) {

          &:before {
            font-size: 10px;
            content: 'ΓΛΩΣΣΑ';
            display: block;
            height: 100%;
            position: relative;
            left: 18px;
            padding-top: 11px;
            top: 3px;
            color: #676767;

            .i18n-en & {
              content: 'LANGUAGE';
            }
          }

          &:after {
            top: 11px;
            right: 9px;
            color: #666666;
            font-size: 6px;
          }
        }
      }

      select {
        @include breakpoint(0 $desk) {
          width: 100% !important;
          background: transparent;
          color: $brand-primary;
          font-weight: 500;
          opacity: 1;
        }
      }
    }
  }
}

// Search block.
.block-search {
  display: inline-block;
  position: relative;
  padding: 0;
  float: none;

  @include breakpoint(0 $desk) {
    width: 100%;
    padding: 0 10px;
  }

  // @include breakpoint($tab) {
  //   top: 0;
  //   right: 11px;
  //   width: 50px;
  //   height: 50px;
  // }

  @include breakpoint($desk) {
    width: auto;
    height: auto;
    top: auto;
    right: auto;
    display: inline-block;
    padding: 23px 1px 19px 0;

    @include breakpoint($wide) {
      padding: 20px 25px 19px 0;

      .scrolled:not(.page-checkout) & {
        padding-top: 21px;
      }
    }



    &:after {
      content: '';
      display: block;
      height: 16px;
      width: 1px;
      background: $darker-border-color;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .block__content {
    border-bottom: 1px solid #d9d9d9;
    padding: 9px 0;
    white-space: nowrap;

    @include breakpoint($desk) {
      padding: 0;
      border: none;
    }
  }

  p {
    margin: 0;
    text-align: left;
    vertical-align: middle;
    position: relative;
    display: inline-block;

    @include breakpoint(0 $desk) {
      display: block;
    }

    &:last-child {
      padding-top: 4px;
      padding-left: 5px;
      position: absolute;
      top: 0;
      left: 0;

      @include breakpoint($desk) {
        position: static;
      }
    }

    span {
      font-size: 10px;
      padding-left: 4px;

      @include breakpoint($desk) {
        display: none;
      }

      @include breakpoint($wide) {
        display: block;
      }

      @include breakpoint(0 $desk) {
        display: block;
        position: absolute;
        top: 26px;
        left: 51px;
        font-size: 14px;
        color: #999;
        z-index: -1;
        font-weight: 500;
      }

      &.bold {
        padding-top: 2px;
        font-weight: 500;
        color: $brand-primary;
        font-size: 16px;
        display: none;

        @include breakpoint($wide) {
          display: block;
        }
      }
    }
  }

  a.findastic-external-speech-recognition-button {
    display: none;
    position: absolute;
    right: 5px;
    top: 1px;
    font-size: 20px;
    text-decoration: none;
    width: 30px;
    height: 35px;
    text-align: center;
    line-height: 37px;

    .speech-tooltip {
      display: inline-block;
      transform: translateX(-999999px);
      position: absolute;
      left: -50px;
      bottom: -27px;
      white-space: nowrap;
      font-weight: bold;
      font-size: 10px;
      font-family: $font-family;
      color: #fff;
      background: $brand-primary;
      padding: 10px;
      line-height: 1;
      border-radius: 2px;
      box-shadow: 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
      opacity: 0;
      transition: opacity 0.3s ease, transform 0s ease 0s;

      &:after {
        content: '';
        display: block;
        border-color: transparent transparent $brand-primary transparent;
        border-width: 0 5px 5px;
        border-style: solid;
        position: absolute;
        bottom: 100%;
        left: 60px;
      }
    }

    @include breakpoint($desk) {
      body.findastic-speech-enabled & {
        display: inline-block;
      }

      &:hover .speech-tooltip {
        transform: translateX(0);
        opacity: 1;
        transition: opacity 0.3s ease, transform 0s ease 0.3s;
      }
    }
  }

  a.findastic-overlay-search {
    font-size: 0;
    max-width: 340px;
    display: inline-block;

    @include breakpoint(0 $desk) {
      font-size: 0px;
      display: block;
      background: transparent;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2) inset;
      color: #999;
      cursor: text;
      height: 50px;
      position: relative;
      text-align: left;
      text-decoration: none;
      z-index: 2;
      border-radius: 3px;
      letter-spacing: -.3px;
    }

    @include breakpoint($desk) {
      font-size: 14px;
      background: white;
      border: 1px solid $darker-border-color;
      color: #999999;
      cursor: text;
      height: 36px;
      line-height: 34px;
      min-width: 250px;
      position: relative;
      text-align: left;
      text-decoration: none;
      z-index: 0;
      border-radius: 17px;
      letter-spacing: -0.3px;
      width: 260px;
      padding: 0 12px 0 28px;

      body.findastic-speech-enabled & {
        overflow: hidden;
      }
    }

    @include breakpoint($wide) {
      // padding: 0 50px 0 34px;
      width: 340px;
      white-space: nowrap;
    }

    &:before {
      @extend %font-icon;
      @extend %font-icon-search;
      // color: white;
      // background: $brand-primary;
      // height: 36px;
      // line-height: 36px;
      // width: 36px;
      // border-radius: 50%;
      // text-align: center;
      // font-size: 18px;

      color: $brand-primary;
      background-color: transparent;
      margin-top: 7px;
      margin-left: 8px;
      height: 36px;
      width: 36px;
      line-height: 36px;
      font-size: 18px;

      @include breakpoint($tab) {
        height: 46px;
        line-height: 46px;
        width: 46px;
        font-size: 22px;

      }

      @include breakpoint($desk) {
        font-size: 12px;
        height: auto;
        border-radius: 0;
        line-height: inherit;
        width: auto;
        margin-left: 0;
        margin-top: 0;
        background: transparent;
        color: $brand-primary;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }
    }

    &:hover {
      text-decoration: none;

      &:before {
        text-decoration: none;
      }
    }
  }

  .l-mobile-menu & {
    display: block;
    padding: 85px 20px 20px 20px;
    margin: 0;

    a.findastic-overlay-search {
      min-width: 0;
    }
  }
}

// Profile
.block--feature-default-profile {
  margin: 0;
  display: none;
  position: relative;
  overflow: hidden;
  width: auto;
  opacity: 1;
  transition: opacity 1s ease;

  @include breakpoint(0 $desk) {
    width: 100%;
    padding: 0 10px;
  }

  @include breakpoint($desk) {
    display: inline-block;

    &:after {
      content: '';
      display: block;
      height: 16px;
      width: 1px;
      background: $darker-border-color;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .block__content {
    font-size: 0px;
    position: relative;
    border-bottom: 1px solid #d9d9d9;

    @include breakpoint($desk) {
      position: static;
      text-align: center;
      padding: 0 10px;
      width: 209px;
      border-bottom: none;
    }

    @include breakpoint($wide) {
      padding: 0px 27px;
      width: 245px;
    }

    div.user-picture {
      display: none;
      height: 37px;
      margin: 0 13px 0 0;
      vertical-align: middle;
      width: 36px;
      @include border-radius(50%);

      img {
        @include border-radius(50%);
      }

      @include breakpoint($desk) {
        display: inline-block;
      }
    }

    a.profile-link {
      color: $text-color;
      display: inline-block;
      font-size: 10px;
      text-align: left;
      text-decoration: none;
      transition: color 0.3s;
      vertical-align: middle;
      white-space: nowrap;
      padding-top: 3px;

      strong {
        color: $brand-primary;
        display: none;
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 500;
        line-height: 20px;
        padding-top: 2px;

        @include breakpoint($desk) {
          display: block;
        }
      }

      &:hover {
        strong {
          color: darken($brand-primary, 8%);
        }
      }

      @include breakpoint(0 $desk) {
        font-size: 14px;
        font-weight: 500;
        line-height: 50px;
        color: #28bdb3;
        padding-top: 0;

        &:after {
          content: '';
          display: inline-block;
          background-image: url(../gravatar.svg);
          position: absolute;
          right: 12px;
          height: 30px;
          border-radius: 50%;
          width: 30px;
          top: 9px;
          background-position: 50% 50%;
        }
      }
    }
  }

  .l-mobile-menu & {
    display: block;
    padding: 65px 20px 0;
    margin: 0;

    .block__content {
      border-color: #d9d9d9;
      border-style: solid;
      border-width: 1px 0 1px 0;
      padding: 5px 0;

      .user-picture {
        float: right;
        height: 35px;
        width: 35px;
      }

      .profile-link {
        padding-left: 5px;
      }
    }
  }

  .scrolled:not(.page-checkout) & {
    width: 0;
    opacity: 0;
  }
}

// Cart block.
.block--feature-commerce-cart {
  display: inline-block;

  #commerce-cart-wrapper {
    @include pie-clearfix;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #d9d9d9;

    @include breakpoint($desk) {
      border: none;
      height: 80px;
    }

    a.shopping-cart-link {
      display: block;
      position: relative;

      @include breakpoint(0 $desk) {
        @include pie-clearfix;
      }

      @include breakpoint($desk) {
        @include vertical-align;
        position: relative;
      }

      span.title {
        float: left;
        display: inline-block;
        font-size: 14px;
        color: $brand-primary;
        font-weight: 500;
        line-height: 50px;

        small {
          float: right;
          font-size: 14px;
          color: #333;
          text-align: right;
          margin-left: 10px;
        }

        @include breakpoint($desk) {
          display: inline-block;
          float: none;
          color: $text-color;
          font-size: 10px;
          font-weight: 400;
          vertical-align: middle;
          padding-right: 3px;
          padding-top: 1px;
          line-height: 20px;

          small {
            color: $brand-primary;
            display: block;
            font-size: 20px;
            line-height: 20px;
            min-width: 100px;
            font-weight: 500;
            margin-right: -7px;
            text-overflow: ellipsis;
            min-height: 20px;

            /* Required for text-overflow to do anything */
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      span.icon {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        z-index: 0;
        float: right;
        width: 47px;
        height: 44px;
        top: 4px;
        margin-right: 4px;

        @include breakpoint($desk) {
          width: 47px;
          height: 44px;
          top: -48px;
          margin: 0 5px 0 5px;
          float: left;
        }

        @include breakpoint($wide) {
          margin: 2px -10px 0 5px;
          width: 52px;
        }

        &:after {
          @extend %font-icon;
          @extend %font-icon-basket;
          color: white;
          background: $brand-primary;
          border-radius: 50%;
          display: block;
          position: absolute;
          left: 9px;
          top: 6px;
          text-align: center;
          z-index: -1;
          font-size: 16px;
          height: 30px;
          line-height: 30px;
          width: 30px;

          @include breakpoint($desk) {
            height: 37px;
            line-height: 37px;
            width: 37px;
            font-size: 20px;
          }
        }

        strong {
          border-radius: 50%;
          background: #000;
          color: white;
          display: block;
          text-align: center;
          position: absolute;
          top: 2px;
          left: 6px;
          font-size: 10px;
          height: 18px;
          line-height: 19px;
          width: 18px;

          @include breakpoint($desk) {
            top: 0;
            left: 0;
            font-size: 15px;
            height: 25px;
            line-height: 25px;
            width: 25px;
          }
        }
      }
    }

    div.cart-empty-block {
      display: none;
    }

    div.cart-contents {
      @include single-box-shadow(0, 30px, 70px, 0, rgba(0, 1, 1, 0.4));
      background: #fff;
      position: absolute;
      top: 69px;
      right: 0;
      width: 290px;
      z-index: 499;
      display: none;

      @include breakpoint(0 $desk) {
        display: none !important;
      }

      @include breakpoint($desk) {
        border-top: 4px solid $brand-primary;
        right: -30px;
        top: 80px;
      }

      .minicart-table {
        max-height: 303px;
        overflow: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          background-color: transparent;
          width: 8px;  /* for vertical scrollbars */
          height: 8px; /* for horizontal scrollbars */
        }

        &::-webkit-scrollbar-thumb {
          background-color: #adadad;
          border-radius: 8px;

          &:hover {
            background-color: darken(#adadad, 10%);
          }
        }

        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }

        table {
          width: 100%;

          tr {
            transition: background 0.3s;

            &:hover {
              background: $well-bg;
            }

            td {
              text-align: left;
              position: relative;
              padding: 18px 35px 18px 0;

              &.views-field-field-product-image {
                padding: 5px;
                padding-right: 20px;

                img {
                  width: 95px;
                  min-width: 95px;
                }
              }

              &.views-field-commerce-total {
                a {
                  color: $text-color;
                  display: block;
                  font-size: 13px;
                  font-weight: 500;
                  height: 48px;
                  margin: 0;
                  margin-bottom: 2px;
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  text-transform: uppercase;
                  max-width: 135px;
                  @include letter-spacing(-25);
                }

                span.price {
                  font-size: $font-size-small;
                  line-height: 14px;
                  color: $brand-primary;
                  font-weight: bold;
                  float: right;
                }

                span.quantity {
                  float: left;
                  color: #999999;
                  line-height: 14px;
                  font-weight: bold;
                }

                button.delete-line-item {
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);

                  &:before {
                    color: $brand-primary;
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }

      .line-item-summary {
        margin: 0;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            padding: 0;
            margin: 0;

            a {
              color: $brand-primary;
              display: block;
              font-size: $font-size-small;
              font-weight: bold;
              line-height: 50px;
              position: relative;
              text-align: center;
              text-decoration: none;
              z-index: 0;
              background: white;
              transition: background 0.3s;
              border-top: 1px solid #d9d9d9;
              border-bottom: 4px solid $brand-primary;
              @include letter-spacing(-25);

              &:hover {
                background: $well-bg;
              }

              &:after {
                @extend %font-icon;
                @extend %font-icon-arrow-right-2;
                position: relative;
                z-index: 1;
                height: 14px;
                color: #28bdb3;
                font-size: 11.5px;
                display: inline-block;
                bottom: 1px;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }

    &.over {
      div.cart-contents {
        display: block;
      }
    }
  }
}

.mobile-findastic-overlay-search,
.mobile-cart-link {
  text-decoration: none;
  display: block;
  font-size: 0px;
  text-decoration: none;
  position: absolute;
  right: 46px;
  top: 22px;
  height: 36px;
  line-height: 36px;
  width: 36px;
  z-index: 100;
  background: $brand-primary;
  border-radius: 50%;
  text-align: center;

  @include breakpoint($tab) {
    width: 46px;
    height: 46px;
    line-height: 50px;
    top: 48%;
    right: 56px;
    transform: translateY(-50%);
  }

  @include breakpoint($desk) {
    display: none;
  }
}

.mobile-findastic-overlay-search {
  right: 46px + 36px + 10px;

  @include breakpoint($tab) {
    right: 56px + 46px + 10px;
  }

  &:before {
    @extend %font-icon;
    @extend %font-icon-search;

    color: #fff;
    background: $brand-primary;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    height: 37px;
    line-height: 37px;
    width: 37px;
    font-size: 20px;

    @include breakpoint($tab) {
      height: 46px;
      line-height: 46px;
      width: 46px;
      font-size: 26px;
    }
  }
}

.mobile-cart-link {
  strong {
    border-radius: 50%;
    background: #000;
    color: #fff;
    display: block;
    text-align: center;
    position: absolute;
    top: -5px;
    left: -5px;
    font-size: 15px;
    height: 25px;
    line-height: 25px;
    width: 25px;

    @include breakpoint($tab) {
      top: -8px;
      left: -4px;
      font-size: 17px;
      height: 28px;
      line-height: 28px;
      width: 28px;
    }
  }

  &:before {
    @extend %font-icon;
    @extend %font-icon-basket;
    color: #fff;
    background: $brand-primary;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    height: 37px;
    line-height: 37px;
    width: 37px;
    font-size: 20px;

    @include breakpoint($tab) {
      height: 46px;
      line-height: 46px;
      width: 46px;
      font-size: 26px;
    }
  }
}

// Mobile menu button
#mobile-menu {
  display: block;
  font-size: 0px;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 22px;
  height: 36px;
  line-height: 36px;
  width: 36px;
  z-index: 100;
  background: $brand-primary;
  border-radius: 50%;
  text-align: center;

  @include breakpoint($tab) {
    width: 46px;
    height: 46px;
    line-height: 50px;
    top: 48%;
    transform: translateY(-50%);

    @include breakpoint($desk) {
      display: none;
    }
  }

  &:before {
    @extend %font-icon;
    @extend %font-icon-burger;
    display: inline-block;
    line-height: 36px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: white;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    right: 0;
    left: 0;

    @include breakpoint($tab) {
      line-height: 52px;
      font-size: 26px;
      width: 26px;
    }
  }

  .page-checkout-shipping &,
  .page-checkout-billing & {
    display: none;
  }
}

.shrink-visible {
  @include breakpoint($desk) {
    .l-region--header & {
      overflow: hidden;
      width: 0;
      height: 0;
      opacity: 0;
      transition: opacity 1s ease;

      .scrolled:not(.page-checkout) & {
        display: inline-block;
        width: auto;
        height: auto;
        opacity: 1;
      }
    }
  }
}

.page-checkout {
  .l-header--content {
    height: 0px;
  }
}

.block--menu-menu-offers-menu{
  background: white;
  width: 100%;
  z-index: 10;
  position: relative;

  .block__title {
    display: none;
  }

  ul {

    @extend .container;
    font-size: 0;
    text-align: center;
    margin: 0 auto;

    li {
      display: inline-block;
      width: 50%;
      line-height: 60px;
      a {
        display: inline-block;
        vertical-align: middle;
        color: $brand-secondary;
        font-size: 11px;
        line-height: 1em;
        text-decoration: none;
        max-width: 200px;


        &:hover {
          text-decoration: none;

          &:before {
            text-decoration: none;
          }
        }

        @include breakpoint($tab) {
          max-width: 255px;
          font-size: 13px;
          vertical-align: middle;
        }

        &:before {
          @extend %font-icon;
          display: inline-block;
          vertical-align: top;
          position: relative;
          // top: 50%;
          // transform: translateY(-50%);
          font-size: 30px;
          margin-right: 12px;

          @include breakpoint($tab) {
            font-size: 36px;
          }
        }
        span {
          text-align: left;
          display: inline-block;
          vertical-align: top;
          font-weight: bold;
          width: 76px;
          position: relative;
          // top: 50%;
          // transform: translateY(-50%);
          line-height: 14px;
          letter-spacing: -0.01em;
        }
      }

      &.brands {
        text-align: center;
        a {
          text-align: left;
          @include breakpoint($tab) {
            // margin-left: 110px;
          }
          &:before {
            @extend %font-icon-brands;
          }
        }
      }

      &.weekly-offers {
        text-align: center;
        a {
          text-align: right;
          @include breakpoint($tab) {
            // margin-right: 110px;
          }
          &:before {
            top: auto;
            transform: translateY(0);
            @extend %font-icon-offers;
          }
        }
      }
    }
  }

  @include breakpoint($desk) {
    display: none;
  }
}

#block-feature-commerce-cart {
  padding: 0 10px;
  width: 100%;

  @include breakpoint($desk) {
    width: 163px;
    height: 80px;
  }

  @include breakpoint($wide) {
    width: 158px;
    height: 80px;
  }
}

@include breakpoint($desk) {
  #block-feature-default-profile {
    height: 37px;
  }

  #block-feature_default-profile-ajax-content {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-image: url(../gravatar.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 29px;
    top: 0;
    z-index: 100;

    @include breakpoint($wide) {
      left: 47px;
    }

    &:before {
      content: 'Ο ΛΟΓΑΡΙΑΣΜΟΣ ΣΑΣ';
      display: block;
      position: absolute;
      left: 45px;
      top: 3px;
      height: 16px;
      width: 110px;
      font-size: 10px;
      color: #333;
    }

    &:after {
      content: 'Πληροφορίες';
      display: block;
      position: absolute;
      left: 26.5px;
      top: 15px;
      height: 16px;
      width: 136px;
      color: $brand-primary;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: -1px;
      font-weight: 500;
      line-height: 20px;
      padding-top: 2px;
    }

    .i18n-en & {
      left: 59px;

      &:before {
        content: 'YOUR ACCOUNT';
        left: 33px;
      }

      &:after {
        content: 'Information';
        left: 19px;
      }
    }
  }
}

.fa.fa-microphone {
  &:before {
    @extend %font-icon;
    @extend %font-icon-microphone;
  }
}
