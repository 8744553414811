.flexslider {
  border-radius: 0;

  &.loading {
    // @extend %loading-spinner;
    // background-size: 60px 60px;
    // background-position: 50% 60%;

    ul {
      // opacity: 0;
    }
  }

  &.loaded {
    ul {
      // opacity: 1;
    }
  }

  .front & {
    margin-bottom: 0;
    // height: 185px;

    @include breakpoint($tab) {
      // height: 550px;

      @include breakpoint($desk) {
        &:before {
          content: '';
          display: block;
          padding-top: 44.57262716%;
        }
      }
    }

    .flexslider-views-slideshow-main-frame {
      margin-bottom: 0;

      @include breakpoint($desk) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      li {
        height: 100%;

        &.views-row-1 {
          display: block;

          .slideshow-wrapper {
            opacity: 1;
          }
        }

        // hide the previous slide if content is shortet than previous slide.
        // z-index is lower than other parts of the page so we can use a huge
        // box-shadow without hiding anything else.
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          height: 500px;
          width: 100%;
          background-color: $brand-primary;
        }

        @include breakpoint($desk) {
          // hide the previous slide image in the 50px bottom margin
          // while transitioning
          box-shadow: 0 50px 0 #fff;

          &:after {
            display: none;
          }
        }

        .bg-image-wrapper {
          width: 100%;
          position: relative;

          &:before {
            content: '';
            display: block;

            // height of .bg-image-wrapper will be 44.57262716% of it's width
            padding-top: 44.57262716%;
          }
        }

        .bg-image {
          background-size: cover !important;
          background-color: #fff;

          // Set dimentions to parent dimentions
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          a {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-size: 0;
          }

          @include breakpoint($tab) {
            // position: static;

            a {
              display: none;
            }
          }
        }

        @include breakpoint(max-width $desk - 1) {
          animation-duration: 0.8s;

          &.come-from-left {
            animation-name: come-from-left;
          }

          &.come-from-right {
            animation-name: come-from-right;
          }

          &.hide-to-left {
            animation-name: hide-to-left;
          }

          &.hide-to-right {
            animation-name: hide-to-right;
          }
        }

        &.transition-top {
          opacity: 1 !important;
          display: block !important;

          @include breakpoint($desk) {
            .bg-image {
              animation-name: move-top;
              animation-duration: 0.8s;
              // z-index: 1 !important;
            }

            .slideshow-wrapper {
              transform: translateY(2000px);
            }

            &.done {
              display: block !important;
              .slideshow-wrapper {
                transform: translateY(0);
                transition: all 0.5s ease;
                transition-delay: 0.5s;
              }
            }
          }
        }

        &.transition-top-out {
          display: block !important;
          opacity: 1 !important;

          @include breakpoint($desk) {
            .bg-image {
              animation-name: move-top-out;
              animation-duration: 3s;
              // z-index: 0 !important;
            }

            .slideshow-wrapper {
              transform: translateY(-2000px);
              z-index: 0 !important;
              transition: all 0.9s ease;
            }
          }
        }

        &.transition-bottom {
          display: block !important;
          opacity: 1 !important;

          @include breakpoint($desk) {
            .bg-image {
              animation-name: move-bottom;
              animation-duration: 0.8s;
              // z-index: 1 !important;
            }

            .slideshow-wrapper {
              transform: translateY(-2000px);
            }

            &.done {
              display: block !important;
              .slideshow-wrapper {
                transform: translateY(0);
                transition: all 0.5s ease;
                transition-delay: 0.5s;
              }
            }
          }
        }

        &.transition-bottom-out {
          display: block !important;
          opacity: 1 !important;

          @include breakpoint($desk) {
            .bg-image {
              animation-name: move-bottom-out;
              animation-duration: 3s;
              // z-index: 0 !important;
            }

            .slideshow-wrapper {
              transform: translateY(2000px);
              z-index: 0 !important;
              transition: all 0.9s ease;
            }
          }
        }
      }

      .field-content {
        > a {
          position: absolute;
          top: 0;
          height: 100%;
          margin: 0 auto;
          opacity: 0;
          left: 0;
          width: 100%;
          z-index: 5;
        }
      }
    }

    .slideshow-wrapper {
      background-color: transparent;
      padding-left: 10px;
      opacity: 0;
      transition: opacity 0.5 ease;

      @include breakpoint(max-width $desk - 1) {
        opacity: 1;
        background: $brand_primary;
      }

      @include breakpoint($tab) {
        padding-left: 20px;

        @include breakpoint($desk) {
          padding-left: 0;
        }
      }
    }

    .flex-active-slide {
      .slideshow-wrapper {
        opacity: 1;
      }
    }

    .flex-control-nav {
      @include vertical-align;
      right: -8px;
      margin: 0 auto;
      height: auto;
      z-index: 99999;
      width: 30px;
      bottom: auto;
      display: none;
      background: rgba(#28bdb3, 0.2);
      border-radius: 18px;

      @include breakpoint($tab) {
        display: block;
        left: 700px;
        top: 0;
        transform: translateY(0);
        margin-top: 10%;

        @include breakpoint($desk) {
          margin-top: 0;
          top: 50%;
          transform: translateY(-50%);
          left: 910px;
          right: 0;

          @include breakpoint($wide) {
            left: 1170px;
          }
        }
      }

      li {
        line-height: 10px;
        display: block;
        margin: 10px auto;
      }

      a {
        background: #ffffff;
        width: 10px;
        height: 10px;
        display: inline-block;
        @include box-shadow(none);

        &.flex-active {
          width: 12px;
          height: 12px;
          background: transparent;
          border: 2px solid #fff;
          box-sizing: content-box;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .flex-direction-nav {
      display: none;
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 0px;

      li {
        display: inline-block;
        margin: 0 20px;
        bottom: -69px;
        position: relative;

        &.flex-nav-next {
          position: relative;
          right: -8px;
        }

        a {
          left: 0;
          right: 0;
          display: inline-block;
          opacity: 1;
          position: relative;
          margin: 17px 0;
          height: 20px;
          width: 20px;
          text-decoration: none;

          &.flex-prev {
            &:before {
              @extend %font-icon;
              @extend %font-icon-arrow-left;
              font-size: 18px;
              color: #999999;
            }
          }

          &.flex-next {
            &:before {
              @extend %font-icon;
              @extend %font-icon-arrow-right;
              font-size: 18px;
              color: #999999;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    &:hover {
      li {
        a {
          &.flex-prev,
          &.flex-next {
            right: 0;
            left: 0;
            opacity: 1;
          }
        }
      }
    }
  }

  .section-brand & {
    background: none;
    position: relative;
    margin: 0 0 100px;


    display: none;

    @include breakpoint($desk) {
      display: block;
    }

    .brand-slide-wrapper {
      height: 500px;
      background-size: cover;
      position: relative;

      .brand-slide-container {
        @extend .container;
        height: 100%;

        @include breakpoint($desk) {
          padding: 28px 0 40px;

          // @include breakpoint($wide) {
          //   padding: 28px 135px 40px;
          // }
        }

        .details-wrapper {
          width: 100%;
          position: relative;
          height: 85%;
          margin-top: 7.5%;

          @include breakpoint($tab) {
            height: 100%;
            box-shadow: 0 0 12px 1px;
            margin-top: 0;

            @include breakpoint($desk) {
              // width: 50%;
              // position: absolute;
            }
          }


          .box {
            display: none;

            @include breakpoint($tab) {
              display: block;
              width: 400px;
              height: 400px;
              border: 2px solid #fff;
              display: block;
              position: relative;
              top: 15px;
              left: 15px;
            }
          }

          .details {
            @include vertical-align;
            vertical-align: middle;
            padding: 15px;

            @include breakpoint($tab) {
              position: static;
              transform: none;
            }


            .details-image {
              float: left;
              margin: 20px 20px 0;
              max-width: 100px;

              @include breakpoint($tab) {
                @include vertical-align;
                max-width: none;
                float: none;
                margin: 0 52px 0 68px;
                margin-right: 52px;
                margin-top: -2px;
              }
            }

            .details-description {
              @include breakpoint($tab) {
                @include vertical-align;
                overflow: hidden;
                margin-left: 269px;
                margin-right: 55px;
                line-height: 21px;
                padding: 25px 0;
                max-height: 340px;
                overflow: auto;

                &::-webkit-scrollbar {
                  width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                  background: #666;
                  border-radius: 5px;
                }
              }

              p {
                color: #fff;
                font-size: 14px;
                @include letter-spacing(-20);
                margin-bottom: 17px;
                margin-top: 12px;

                @include breakpoint($tab) {
                  font-size: 16px;
                }
              }
            }
          }

          > a {
            position: absolute;
            bottom: 25px;
            right: 0;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            @include letter-spacing(100);
            font-size: 12px;
            white-space: nowrap;

            &:after {
              @extend %font-icon;
              @extend %font-icon-arrow-right;
              margin: 0 22px 0 11px;
              text-decoration: none;
            }
          }
        }
      }

      .video-overlay {
        height: 100%;
        width: 100%;
        display: table;
        position: absolute;
        z-index: 1;
        background-size: cover;

        @include breakpoint($desk) {
          background-size: contain;
        }


        .play-button {
          text-align: center;
          display: table-cell;
          vertical-align: middle;

          div {
            border-radius: 50px;
            background: #28bdb3;
            width: 80px;
            height: 80px;
            display: inline-block;
            cursor: pointer;

            &:hover {
              border: 2px solid white;
              box-sizing: content-box;
            }

            &:after,
            &:before {
              //@extend %font-icon, .font-icon-play;
              content: "";
              width: 0;
              height: 0;
              border: 9px solid transparent;
              border-left: 13px solid #28bdb3;
              display: block;
              position: relative;
              top: 6px;
              left: 37px;
            }

            &:before {
              border: 13px solid transparent;
              border-left: 18px solid #fff;
              top: 28px;
              left: 35px;
            }
          }
        }
      }

      .brand-slide-video-container {
        height: 100%;
        width: 100%;
        position: relative;

        .video-wrapper {
          @extend .container;
          height: 100%;
          position: relative;
          background: #000;
          // background-clip: content-box;
        }
      }
    }

    .flex-control-nav {
      position: absolute;
      bottom: 0;
      padding-bottom: 12px;
      z-index: 10;

      li {
        margin: 0 2px;
        vertical-align: middle;

        a {
          background: #fff;
          border: 2px solid transparent;
          background-clip: content-box;
          width: 10px;
          height: 10px;
          box-sizing: content-box;

          &.flex-active {
            background: transparent;
            border-color: #fff;
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .flex-direction-nav {
      @extend .container;

      li {
        @extend .container;
        position: absolute;
        top: 50%;
        margin: 0 !important;

        &.flex-nav-prev {
          margin: 0 0 0 -20px !important;
        }

        .flex-prev:before {
          @extend %font-icon;
          @extend %font-icon-arrow-left;
          color: #fff;
          text-decoration: none;
          left: 0;
        }

        .flex-next:before {
          @extend %font-icon;
          @extend %font-icon-arrow-right;
          color: #fff;
          text-decoration: none;
          right: 0;
        }
      }
    }
  }
}


@keyframes come-from-right {
  0%   { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

@keyframes hide-to-right {
  0%   { transform: translateX(0); }
  100% { transform: translateX(100%); }
}

@keyframes come-from-left {
  0%   { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

@keyframes hide-to-left {
  0%   { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}


@keyframes move-top {
  0%   { transform: translateY(1500px); }
  40%   { transform: translateY(1500px); }
  100% { transform: translateY(0); }
}

@keyframes move-top-out {
  0%   { transform: translateY(0); }
  10%   { transform: translateY(0); }
  100% { transform: translateY(-500px); }
}

@keyframes move-bottom {
  0%   { transform: translateY(-1500px); }
  40%   { transform: translateY(-1500px); }
  100% { transform: translateY(0); }
}

@keyframes move-bottom-out {
  0%   { transform: translateY(0); }
  10%   { transform: translateY(0); }
  100% { transform: translateY(500px); }
}

