.l-region--highlighted {
  position: relative;

  .view-slideshow {
    position: relative;
    z-index: 3;

    .view-footer {
      position: fixed;
      bottom: 2px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 50px;
      z-index: 999;
      display: none;

      @include breakpoint($wide) {
        .show-sliedeshow-arrow-down & {
          display: inline-block;
        }
      }

      .scroll-out {
        height: 30px;
        width: 50px;
        text-align: center;
        padding-top: 5px;
        cursor: pointer;

        &:after {
          @extend %font-icon;
          @extend %font-icon-arrow-down;
          font-size: 23px;
          color: white;
        }
      }
    }
  }
}

.front {
  .block--feature-default-front-page-sections {
    display: none;

    @include breakpoint($desk) {
      display: block;
    }
  }

  .node--page--full {
    display: none;
  }

  &.node-type-page {
    .l-branding {
      h1 {
        margin-bottom: 0;
        margin-top: 13px;

        img {
          position: static;
          transform: none;
          top: auto;
        }

        @include breakpoint($tab) {
          margin-top: 21px;

          @include breakpoint($desk) {
            margin-top: 0;
          }
        }
      }
    }
  }

  #findastic-wrapper {
    z-index: 1;
    background: white;
  }

  .l-region--highlighted {
    z-index: 0;

    .block--menu-menu-offers-menu {
      z-index: 20;
    }

    .block-slideshow {
      z-index: 0;

      @include breakpoint($tab) {

        @include breakpoint($desk) {
          margin-top: 0;
        }
      }
    }
  }

  .l-content {
    main > .tabs--primary {
      transform: translateZ(500px);
      background: white;
      margin: 0;
      padding: 20px;
      z-index: 1;
    }
  }

  .owl-carousel {
    @include breakpoint($desk $wide) {
      // width: 921px;
    }
  }
}

.block--feature-default-front-page-track,
.block-frontpage-newsletter {
  backface-visibility: hidden;
  background: $brand-primary;
  z-index: 1;

  .container {
    font-size: 0;

    @include breakpoint($tab) {
      height: 200px;
    }
  }

  .left {
    display: block;
    font-size: 16px;
    @include breakpoint($tab) {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      width: 450px;
      padding-left: 63px;
      padding-right: 20px;

      @include breakpoint($desk) {
        width: 50%;
        padding-right: 0;
        padding-left: 60px;

        @include breakpoint($wide) {
          padding-left: 160px;
        }
      }
    }
  }

  .right {
    display: block;
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 40px;

    @include breakpoint($tab) {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      width: 278px;
      padding-top: 75px;
      padding-bottom: 0;


      @include breakpoint($desk) {
        width: 50%;
        padding-left: 160px;
      }
    }

  }

  .tracking-field {
    position: relative;
  }

  h2,
  .h2 {
    color: white;
    font-size: 20px;
    position: relative;
    text-align: right;
    max-width: 300px;
    margin-bottom: 10px;
    margin-top: 38px;

    .i18n-en & {
      font-size: 18px;
    }

    @include breakpoint($tab) {
      text-align: left;
      margin-top: 47px;
      margin-bottom: 10px;
      font-size: 30px;
      max-width: none;
      letter-spacing: -1px;

      @include breakpoint($desk) {
      }
    }
    &:after {
      content: '';
      display: block;
      width: 40px;
      background: white;
      margin-top: 35px;
      height: 2px;

      @include breakpoint($tab) {
        margin-top: 20px;
      }
    }

    &:before {
      @extend %font-icon;
      @extend %font-icon-mail;
      position: absolute;
      top: 0;
      font-size: 35px;
      left: 0;

      @include breakpoint($tab) {
        left: -60px;

        @include breakpoint($desk) {
        }
      }
    }
  }

  p {
    color: white;
    font-weight: 500;
    letter-spacing: -0.2px;

    @include breakpoint($tab) {
      letter-spacing: -0.5px;
    }
  }

  form {
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    z-index: 1;

    input.form-text {
      border: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 46px;
      height: 50px;
      margin: 0;
      max-width: 100%;
      outline: none;
      padding-left: 10px;
      padding-right: 90px;
      width: 100%;

      @include breakpoint($tab) {
        font-size: 14px;

        @include breakpoint($desk) {
          padding-left: 20px;
        }
      }

      &:focus {
        outline: none;
      }
    }

    button.form-submit {
      background: none;
      border: none;
      color: $brand-primary;
      font-weight: 500;
      height: 40px;
      position: absolute;
      right: 0;
      transition: color 0.1s;
      z-index: 0;
      top: 0;
      font-size: 14px;
      line-height: 49px;
      outline: none;
      white-space: nowrap;

      &:after {
        @extend %font-icon;
        @extend %font-icon-arrow-right-2;
        padding-left: 10px;
        position: relative;
        font-weight: bold;
        font-size: 12px;
        top: -1px;
      }

      @include breakpoint($tab) {
        font-size: 12px;
        line-height: 47px;

        &:after {
          font-size: 11px;
          top: 0;
        }
      }

      &:hover {
        color: darken($brand-primary, 8%);
      }
    }
  }
}


.block--feature-default-front-page-track {
  .container {
    height: auto;

    @include breakpoint($tab) {
      height: 130px;
    }
  }

  .right {
    padding-top: 42px;

    .floating-label > label.error {
      display: none !important;
    }

    input.error {
      border: 1px solid red;
    }
  }

  h2 {
    text-align: left;
    padding-left: 80px;

    @include breakpoint($tab) {
      padding-left: 0;
      margin-top: 46px;
    }

    &:before {
      @extend %font-icon;
      @extend %font-icon-track;
      height: 50px;
      width: 50px;
      display: block;
      top: -11px
    }

    &:after {
      content: none;
    }
  }
}

#block-views-front-mobile-banners-block {
  width: 100%;
  text-align: center;

  @include breakpoint($desk) {
    display: none;
  }

  .views-row {
    + .views-row {
      margin-top: 5px;
    }

    a {
      display: block;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;

      &:before {
        content: '';
        display: block;
        padding-top: 75%;
      }

      @include breakpoint($desk) {
        background-image: none !important;
      }
    }
  }
}


#block-views-slideshow-block-1 {
  display: none;

  @include breakpoint(max-width $desk) {
    .front & .flexslider .flexslider-views-slideshow-main-frame li .bg-image {
      background-image: none !important;
    }
  }

  @include breakpoint($desk) {
    display: block;
  }
}

.view-front-page-blog-blocks {
  background-color: #fff;
  display: none;

  @include breakpoint($desk) {
    display: block;
    padding: 20px 0;
  }

  @include breakpoint($wide) {
    padding: 35px 0;
  }

  .view-content {
    @include pie-clearfix;

    margin-left: -10px;
    margin-right: -10px;

    @include breakpoint($wide) {
      margin-left: -(35px / 2);
      margin-right: -(35px / 2);
    }
  }

  .views-row {
    float: left;
    width: (100% / 3);
    font-size: 0;
    padding: 0 10px;

    @include breakpoint($wide) {
      padding: 0 (35px / 2);
    }

    .field--name-field-link a {
      display: inline-block;
      font-size: 0;
      background-repeat: no-repeat;
      background-size: cover;

      width: 293px;
      height: 237px;

      @include breakpoint($wide) {
        width: 370px;
        height: 300px;
      }
    }
  }
}
