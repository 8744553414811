@mixin container-fixed($gutter: $grid-gutter-width) {
  margin-right: auto;
  margin-left: auto;
  padding-left:  floor(($gutter / 2));
  padding-right: ceil(($gutter / 2));
  @include pie-clearfix;
}

@mixin vertical-align {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin fullscreen($from-top) {
  height: 100vh;
  margin-top: -$from-top;
  padding-top: $from-top;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder {@content}
  &::-moz-placeholder {@content}
  &:-ms-input-placeholder {@content}
}

@mixin letter-spacing ($photoshop_number) {
  letter-spacing: #{$photoshop_number/1000}em;
}

@mixin stroke($width, $color) {
    $width: $width + 0;
    $shadow: 0 0 0 transparent;
    $i: 0;
    $w: 1;

    @while ($i < $width) {
        $i: $i + 1;
        $j: 0;
        $w: $w + 2;

        @for $r from 1 through $w {
            @for $c from 1 through $w {
                $x: $c - ceil($w / 2);
                $y: $r - ceil($w / 2);

                $shadow: #{$shadow}, #{$x}px #{$y}px 0 $color;
            }
        }
    }

    text-shadow: $shadow;
}

@mixin inputbox {
  position: relative;
  z-index: 0;

  & > label {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    font-weight: normal;
    font-size: 0.8em;
    margin-top: 0.8em;
    padding: 0 1em;
    line-height: 1.1em;
    height: 1em;
    user-select: none;
    pointer-events: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > input.form-text,
  & > select.form-select {
    -webkit-appearance: none;
    -webkit-font-smoothing: inherit;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 1.6em 0.8em 0.4em;
    outline: none;
    line-height: 1.2em;
    height: auto;
  }
}

@mixin inputbox-moving() {
  & > label.option {
    display: block !important;
    position: absolute;
    top: 14px;
    left: 0;
    transform-origin: 0;
    @include transition(transform 0.2s ease);
    overflow: visible;
    font-weight: normal;
  }
  & > input.form-text {
    border-bottom: none !important;
    @include placeholder {
      color: transparent;
    }
  }

  span.line {
    font-size: 0;
    height: 1px;
    display: block;
    position: absolute;
    width: 100%;
    top: 44px;
    background: #aaa;
    &:before,
    &:after {
      height: 1px;
      background: #000;
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      @include transform(scale(0,1));
      @include transition(transform 0.2s ease);
    }

    &:before {
      left: 50%;
      transform-origin: 0;
    }

    &:after {
      right: 50%;
      transform-origin: 100%;
    }
  }

  & > input.form-text:focus,
  &.filled > input.form-text{
    & ~ label.option {
      @include transform(translateY(-17px) scale(0.9));
    }

    & ~ span span.line {
      &:before,
      &:after {
        @include transform(scale(1));
      }
    }
  }
}

@mixin clearfix {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}


@mixin contain-top-margin {
  &:before {
    content: ' ';
    display: table;
  }
}

@mixin contain-bottom-margin {
  &:after {
    content: ' ';
    display: table;
  }
}
