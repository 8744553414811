$nav_bg: #fff;
$nav_bg_linear_gradient_top: #dbdbdb;
$nav_bg_linear_gradient_bottom: #d2d2d2;
$nav_font_size: 14px;
$nav_line_height: 22px;
$nav_color: #f5f5f5;
$nav_text_color: #333;
$nav_padding_side: 20px;
$nav_li_border_top: #fff;
$nav_li_border_bottom: #d9d9d9;
$nav_text_shadow: none;

//0 1px #fff;

$header_color: $nav_color;
$header_height: 60px;
$header_font_size: 2em;
$header_title_width: 80%;
$header_arrow_color: $nav_color;
$header_arrow_width: 10%;
$header_arrow_icon_width: 10px;

$footer_bg: $nav_bg;
$footer_color: $nav_color;
$footer_font_size: 1.25em;
$footer_height: 40px;

$search_height: 50px;
$counter_color: $nav_color;

@mixin mmenu-linear-gradient($from, $to) {
  /* Old browsers */
  background: $from;

  /* FF3.6+ */
  background: -moz-linear-gradient(top, $from 0%, $to 100%);

  /* Chrome,Safari4+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $from), color-stop(100%, $to));

  /* Chrome10+,Safari5.1+ */
  background: -webkit-linear-gradient(top, $from 0%, $to 100%);

  /* Opera 11.10+ */
  background: -o-linear-gradient(top, $from 0%, $to 100%);

  /* IE10+ */
  background: -ms-linear-gradient(top, $from 0%, $to 100%);

  /* W3C */
  background: linear-gradient(to bottom, $from 0%, $to 100%);

  /* IE6-9 */
  filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 )";
}

@mixin mmenu-opacity($value) {
  $IEValue: $value * 100;

  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue + ")";
  filter: alpha(opacity = $IEValue);
}

body {
  -webkit-user-select: initial !important;
  -moz-user-select: initial !important;
  -ms-user-select: initial !important;
  -o-user-select: initial !important;
  user-select: initial !important;
}

/* Blocker */
#mm-blocker {
  background: #000 !important;

  @include mmenu-opacity(0.5);
}

/* Menu nav */
.mmenu-nav:not(.mm-menu) {
  display: none;
}

.mm-menu {
  .mm-list {
    padding-top: 0;
  }
}

.mmenu-nav.mm-farmakop {
  // z-index more than zopim
  z-index: 16000002;
  background: $nav_bg;
  color: $nav_text_color;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);

  // font-family: Helvetica, sans-serif;
  font-size: $nav_font_size;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.5) !important;
  text-shadow: $nav_text_shadow;
  font-weight: 500;

  /* Icons */
  [class^="icon-"],
  [class*=" icon-"],
  .mmenu-block-icon {
    // display: table-cell;
    display: none;
    vertical-align: middle;
    font-style: normal;
    padding-right: 15px;
  }

  .mmenu-block-title {
    display: table-cell;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 10px;
    font-size: 16px;
  }

  .mmenu-menu-close {
    width: 36px;
    height: 36px;
    display: inline-block;
    background: #8cc53f;
    border-radius: 50%;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      @extend %font-icon;

      @extend %font-icon-close;

      position: absolute;
      top: 12px;
      left: 11px;
      color: #fff;
      font-size: 14px;
    }
  }

  /* Header */
  > .mm-header {
    border-color: rgba(0, 0, 0, 0.15) !important;
    color: $header_color;
    height: $header_height;
    line-height: $header_height;

    @include mmenu-linear-gradient($nav_bg_linear_gradient_top, $nav_bg_linear_gradient_bottom);

    .mm-title {
      font-size: $header_font_size;
      padding: 0 10%;
      width: $header_title_width;
      text-align: center;
    }

    .mm-prev {
      padding: 0;
      left: $nav_padding_side;
      right: $header_title_width + $header_arrow_width;
      top: 0;
      bottom: 0;
      width: auto;

      &:before {
        margin: 0;
        width: $header_arrow_icon_width;
        height: $header_arrow_icon_width;
        z-index: 10;
        border-color: $header_arrow_color;
      }
    }

    .mm-next {
      padding: 0;
      left: $header_title_width + $header_arrow_width;
      right: $nav_padding_side;
      top: 0;
      bottom: 0;
      width: auto;

      &:before {
        margin: 0;
        width: $header_arrow_icon_width;
        height: $header_arrow_icon_width;
        z-index: 10;
        border-color: $header_arrow_color;
      }
    }
  }

  /* List or Panel */
  > .mm-list {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0 10px;
    margin: 0;

    &.mm-subopened {}

    li {
      border-bottom: 1px solid $nav_li_border_bottom;

      &.mobile-mmenu-links {
        border-bottom: none;
      }

      &:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults) {
        &:after {
          border: none;
        }
      }

      > a {
        &:not(.mm-subopen):not(.mm-subclose):not(.mm-fullsubopen) {
          padding: 25px 0 25px 0;
          white-space: normal;
        }

        &.mmenu-mm-list {
          .mmenu-block-title {
            font-size: 14px;
            left: 0;
          }
        }

        &.mm-subopen {
          width: 54px;
          box-shadow: -5px 0 0 -4px #eee;

          + a.mmenu-mm-list {
            margin-right: 55px;

            .mmenu-block-title {
              padding-right: 60px;
            }
          }
        }

        &:before {
          display: none;
        }

        &:after {
          border: none;
        }
      }

      &:after {
        margin-left: 0 !important;
        border-color: rgba(0, 0, 0, 0);
      }

      &.mm-selected > a,
      &.mm-selected > span {
        background: inherit;
      }

      &.mmenu-expanded {
        border-bottom: none;

        .mmenu-block-collapsed {
          padding: 15px 20px !important;
          font-size: 1.25em;
        }

        .mmenu-block-expanded {
          height: 82px;
          padding: 5px 75px 5px 10px !important;
          background: $nav_color;
          margin-right: -10px;
          margin-left: -10px;
          position: relative;
        }
      }

      &.mm-subtitle {
        height: 82px;
        padding: 5px 75px 5px 10px !important;
        background: $nav_color;
        margin-right: -10px;
        margin-left: -10px;
        position: relative;
        border-bottom: none;

        .mm-subclose {
          background: none;
          margin-top: 0;
          text-indent: 0;
          padding: 20px 75px 20px 0;
          color: #333;
          transform: translateY(-50%);
          position: absolute;
          top: 50%;
          left: 10px;
          right: 10px;
          font-size: 16px;

          &:after {
            content: '';
            display: inline-block;
            transform: rotate(-45deg);
            border-width: 2px 0 0 2px;
            border-color: $brand-primary;
            border-style: solid;
            position: absolute;
            right: 18px;
            width: 13px;
            height: 13px;
            color: $brand-primary;
          }
        }
      }
    }

    > .mm-list {
      margin: 0;
      padding: 0;
    }
  }

  /* Has search */
  &.mm-hassearch {
    > .mm-header {
      top: 0;
    }

    > .mm-panel {
      padding-top: ($header_height + $search_height) !important;
    }

    > .mm-search {
      height: $search_height;
      top: $header_height;
      border-bottom: 1px solid $nav_li_border_bottom;
    }

    li.mm-noresults {
      padding: 40px 0;
      color: #c4ccda;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
    }
  }

  &.mm-hasheader {
    > .mm-panel {
      padding-top: $header_height;
    }
  }

  &.mm-hasfooter {
    > .mm-panel {
      padding-bottom: $footer_height;
    }
  }

  /* Counter */
  em.mm-counter {
    color: $counter_color;
    right: 35px;
  }

  .mm-subopen,
  .mm-subclose {
    padding-left: 25px;

    &:before {
      border-color: $counter_color;
      border-left: none;
    }

    &:after {
      @extend %font-icon;

      @extend %font-icon-plus;

      transform: none;
      color: $brand-primary;
      width: 16px;
      height: 16px;
      margin-bottom: -11px;
    }
  }

  em.mm-counter + a.mm-subopen + a,
  em.mm-counter + a.mm-subopen + span {
    margin-right: 65px;
    padding: 10px $nav_padding_side;
  }

  &.mm-vertical {
    li.mm-opened {
      > ul {
        padding: 0 0 0 ($nav_padding_side + 10);
        background: rgba(255, 255, 255, 0.05);

        li {
          border-left: none;

          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      > a.mm-subopen {
        background: rgba(255, 255, 255, 0.05);
        border-bottom: none;
        width: 40px;
      }

      > span.mmenu-block-collapsed {}
    }
  }

  /* Footer */
  > .mm-footer {
    background: $footer_bg;
    color: $footer_color;
    padding: 0;
    font-size: $footer_font_size;
    height: $footer_height;
    line-height: $footer_height;
    text-align: center;
    border-color: rgba(0, 0, 0, 0.15) !important;
  }

  /* Mmenu search form block */
  .search-form {
    margin-bottom: 0;

    .container-inline div,
    .container-inline label {
      display: block;
    }

    .form-item {
      margin: 0 0 10px 0;
      padding: 0;
    }

    label {
      margin-bottom: 10px;
    }

    .form-text {
      padding: 5px 0;
      width: 100%;
      border: none;
    }

    .form-submit {
      margin: 0;
    }
  }

  .block-search {
    display: block;
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    width: auto;
    height: auto;

    .block__content {
      height: 71px;
      background: #fff;
      position: relative;
      z-index: 0;
      padding: 0;

      p:first-child {
        width: 100%;
        padding: 10px 0;
        height: 71px;
      }

      a.findastic-overlay-search {
        font-size: 0px;
        display: block;
        background: transparent;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
        color: #999999;
        cursor: text;
        height: 50px;
        position: relative;
        text-align: left;
        text-decoration: none;
        z-index: 2;
        border-radius: 3px;
        letter-spacing: -0.3px;

        // margin-right: 10px;

        &:before {
          color: $brand-primary;
          background-color: transparent;
          margin-top: 7px;
          height: 36px;
          width: 36px;
          line-height: 36px;
          font-size: 18px;
        }
      }

      span {
        display: block;
        position: absolute;
        top: 27px;
        left: 40px;
        font-size: 14px;
        color: #999999;
        z-index: -1;

        &.bold {
          display: none;
        }
      }
    }
  }

  .block--feature-commerce {
    @extend %mobile-menu-user-actions;

    padding: 0;
    margin: 0;
    width: 100%;

    .block__content {
      padding: 0;
      height: 100%;
      width: 100%;
      display: inline-block;
    }

    .commerce-cart-wrapper {
      height: auto;

      .cart-empty-block {
        display: none;
      }

      a.shopping-cart-link {
        span.title {
          float: left;
          display: inline-block;
          font-size: 14px;
          color: $brand-primary;
          font-weight: 500;
          line-height: 50px;

          small {
            float: right;
            font-size: 14px;
            color: $text-color;
            text-align: right;
            margin-left: 10px;
          }
        }

        span.icon {
          display: inline-block;
          position: relative;
          vertical-align: middle;
          z-index: 0;
          margin: 0 45px 0 0;
          float: right;
          margin: 0 0 0 0;
          width: 40px;
          height: auto;
          top: 5px;
          right: 2px;

          // @include breakpoint($tab) {
          //   width: 60px;
          //   height: 57px;
          //   top: -31px;
          //   margin: 0 60px 0 0;
          // }

          strong {
            border-radius: 50%;
            background: #000;
            color: white;
            display: block;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 10px;
            height: 18px;
            line-height: 19px;
            width: 18px;

            // @include breakpoint($tab) {
            //   top: -5px;
            //   left: 4px;
            //   font-size: 17px;
            //   height: 28px;
            //   line-height: 28px;
            //   width: 28px;
            // }

          }

          &:after {
            @extend %font-icon;

            @extend %font-icon-basket;

            color: white;
            background: $brand-primary;
            border-radius: 50%;
            display: block;
            position: absolute;
            text-align: center;
            z-index: -1;
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            width: 30px;
            right: 10px;
            top: 6px;

            // @include breakpoint($tab) {
            //   height: 46px;
            //   line-height: 46px;
            //   width: 46px;
            //   font-size: 26px;
            // }

          }
        }
      }

      .cart-contents {
        display: none;
      }
    }
  }

  .block--feature-default-profile {
    @extend %mobile-menu-user-actions;

    width: 100%;
    margin: 0;
    padding: 0;

    &:after {
      display: none !important;
    }

    .block__content {
      padding: 0;
      display: inline-block;
      height: 100%;
      position: relative;
      border: 0;
      width: 100%;
      text-align: left;

      .profile-link {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        line-height: 50px;
        color: $brand-primary;
        padding: 0;

        &:after {
          content: '';
          display: inline-block;
          background-image: url(../gravatar.svg);
          position: absolute;
          right: 12px;
          height: 30px;
          border-radius: 50%;
          width: 30px;
          top: 9px;
          background-position: 50% 50%;
        }

        strong {
          display: none;
        }
      }

      .user-picture {
        display: none;
        position: absolute;
      }
    }
  }

  .block--header-track-link,
  .block--header-blog-link {
    @extend %mobile-menu-user-actions;

    width: 100%;
    margin: 0;
    padding: 0;

    .block__content {
      padding: 0;
      display: inline-block;
      height: 100%;

      a {
        font-size: 14px;
        line-height: 50px;
        height: 50px;
        font-weight: 500;
        color: $brand-primary;
      }
    }
  }

  .block--header-blog-link {
    .block__content {
      a {
        color: #f48477;
      }
    }
  }

  .block--header-track-link {
    .block__content {
      a:before {
        @extend %font-icon;
        @extend %font-icon-track;
        color: #fff;
        display: block;
        position: absolute;
        top: 10px;
        right: 12px;
        font-size: 14px;
        line-height: 30px;
        color: #fff;
        background-color: $brand-primary;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 50%;
        padding: 0;
      }
    }
  }

  .block--feature-wishlist {
    @extend %mobile-menu-user-actions;

    width: 100%;
    margin: 0;
    padding: 0;

    .block__content {
      padding: 0;
      display: inline-block;
      height: 100%;

      a {
        font-size: 14px;
        line-height: 50px;
        height: 50px;
        font-weight: 500;
        color: $brand-primary;

        &:before {
          @extend %font-icon;
          @extend %font-icon-wishlist-heart;
          position: absolute;
          top: 10px;
          right: 12px;
          font-size: 14px;
          line-height: 30px;
          color: #fff;
          background: $brand-primary;
          width: 30px;
          height: 30px;
          text-align: center;
          border-radius: 50%;
          padding: 0;
        }
      }
    }
  }

  .block--lang-dropdown {
    @extend %mobile-menu-user-actions;

    width: 100%;
    margin: 0;
    padding: 0;
    margin-left: 0 !important;

    .block__content {
      padding: 0;
      display: inline-block;
      height: 100%;
      width: 100%;

      .form-item {
        line-height: 50px;
        height: 50px;

        .list-page-select {
          width: 100% !important;

          select {
            width: 100% !important;
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
          }
        }
      }

      .selector {
        span {
          font-size: 16px;
          line-height: 50px;
          margin-left: 0 !important;
          height: 100% !important;

          &:before {
            display: none;
          }

          &:after {
            content: '';
            width: 30px;
            height: 30px;
            border: 2px solid $brand-primary;
            border-radius: 50%;
            position: absolute;
            top: 9px;
            right: 12px;
          }
        }

        select {
          background: transparent;
          color: $brand-primary;
          opacity: 1;
          width: 100% !important;
        }
      }

      .language-icon {
        width: 20px;
        height: 12px;
        right: 17px;
        top: 24px;
      }
    }
  }
}
