.promoted-title {

  margin-top: 50px;
  margin-bottom: 25px;
  margin-left: 0px;
  text-align: left;
  padding: 0 10px;

  @include breakpoint($tab) {
    text-align: center;
    padding: 0;
    margin-top: 35px;
    margin-bottom: 50px;

    @include breakpoint($desk) {
      margin-top: 70px;
    }
  }

  h1 {
    @include letter-spacing(-10);
    color: $text-color;
    font-size: 20px;
    margin-bottom: 22px;

    @include breakpoint($tab) {
      font-size: 30px;
    }
  }

  #divider {
    width: 40px;
    border-bottom: 2px solid #dcdcdc;
    height: 0;
    margin: 15px auto;
  }

  h3 {
    color: $text-color;
    font-weight: 500;
    @include letter-spacing(-25);
    line-height: 24px;
    font-size: 16px;
    margin: 0;

    @include breakpoint($tab) {
      margin: 0 15px;
      font-size: 18px;
    }
  }
}

.block--feature-default-promoted-category-banners {
  @extend .container;
  margin: 0 auto 35px;
  text-align: center;
  position: relative;
  overflow: hidden;

  @include breakpoint($tab) {
    margin: 0 auto 20px;

    @include breakpoint($desk) {
      margin: 0 auto 80px;
    }

  }

  .owl-wrapper .owl-item {
    min-height: 249px;

    .bean-promoted-category {
      min-height: 249px;

      .content {
        min-height: 249px;

        .main-banner {
          min-height: 249px;
        }
      }
    }
  }

  .owl-controls {
    margin-top: 30px;

    .owl-page > span {
      border: 1px solid #fff;
      background-color: #d9d9d9;
      opacity: 1;
    }
    .owl-page.active > span{
      background-color: #fff;
      border: 2px solid $brand-primary;
      height: 14px;
      width: 14px;
      position: relative;
      top: 1px;
    }
  }

  .bean-promoted-category {
    display: inline-block;
    padding: 0 18px;
    margin-bottom: 30px;
    vertical-align: top;

    &:hover {
      text-decoration: none;
    }

    .main-banner {
      img {
        margin-bottom: 5px;
      }
    }

    .field--name-field-product-title  {
      min-height: 36px;
      .field__item {
        font-size: 14px;
        font-weight: 500;
        padding: 0 15px;
        text-align: left;
        text-decoration: none;
        width: 255px;
        min-height: 36px;

        color: $text-color;

      }
    }
  }
}

.views-field-contextual-links {
  position: relative;

  .views-label-contextual-links {
    display: none;
  }
}
