.l-breadcrumb {
  .breadcrumb {
    @extend .container;

    background: none;
    padding-top: 20px;
    padding-bottom: 25px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;

    .node-type-faq & {
      margin-bottom: 20px;
    }

    a {
      color: $breadcrumb-color;
      text-transform: capitalize;
      cursor: pointer;

      &.active {
        color: $breadcrumb-active-color;
        pointer-events: none;
      }
    }

    .breadcrumb-separator {
      font-size: 0;

      &:before {
        font-size: $font-size-small;
        content: $breadcrumb-separator;
      }
    }

    .section-blog & {
      padding-right: 150px;

      @include breakpoint($desk) {
        padding-right: 0;
      }
    }

    .section-stores & {
      display: none;
    }

    .node-type-page &,
    .node-type-webform & {
      margin-bottom: 8px;
    }

    .page-blog &,
    .page-events &,
    .page-ask-the-experts &,
    .node-type-article &,
    .page-events &
    .node-type-ask-the-experts & {
      width: 100%;

      // margin-top: 80px;
      @include breakpoint($tab) {
        // margin-top: 130px;
      }


      @include breakpoint($desk) {
        width: 920px;

        // margin-top: 0;
        @include breakpoint($wide) {
          width: 1180px;
        }
      }
    }
  }
}

.cart-breadcrumb {
  height: 25px;
  margin: 60px auto 40px;
  padding: 0;
  width: 280px;
  text-transform: uppercase;
  font-weight: 500;
  unicode-bidi: bidi-override;
  direction: rtl;

  @include breakpoint($tab) {
    width: 515px;
  }


  &.options-3 li {
    width: 33%;
  }

  li {
    vertical-align: top;
    color: $link-disabled-color;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    width: 25%;
    font-size: 12px;
    position: relative;

    @include breakpoint($tab) {
      font-size: $font-size-small;
    }


    a {
      color: $link-disabled-color;
      pointer-events: none;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 4px;
      background: $link-disabled-color;
      position: absolute;
      right: 50%;
      top: -20px;
      z-index: 1;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $link-disabled-color;
      position: absolute;
      top: -23px;
      z-index: 2;
      right: 50%;
      transform: translateX(50%);
      box-shadow: 0 0 0 4px #fff;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }

    &.active {
      &,
      ~ li {
        &:before,
        &:after {
          background-color: $brand-primary;
        }

        &,
        a {
          color: $brand-primary;
          pointer-events: auto;
        }
      }

      &:after {
        width: 7px;
        height: 7px;
        top: -22px;
        box-shadow: 0 0 0 4px #fff, 0 0 0 7px $brand-primary;
      }
    }
  }
}

.not-front {
  .l-breadcrumb {
    // padding-top: 80px;
    @include breakpoint($tab) {
      // padding-top: 130px;
    }


    @include breakpoint($desk) {
      // padding-top: 0px;
      margin-top: 60px;
    }
  }
}

.page-blog {
  .l-breadcrumb {
    @include breakpoint($desk) {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
}
