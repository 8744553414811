// Footer wrapper
.l-footer {
  background: #f5f5f5;
  padding-bottom: 50px;
  padding-top: 25px;
  border-top: 1px solid #fdfdfd;
  font-weight: 500;
  font-size: 0;
  position: relative;
  z-index: 1;
  transform: translateZ(0);

  @include breakpoint($tab) {
    padding-top: 55px;
  }

  > .container {
    position: relative;
    z-index: 2;
  }

  .l-region {
    font-size: 16px;
  }

  .block,
  .tpl-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint($tab) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .block__title {
    font-size: 16px;

    @include breakpoint($desk) {
      font-size: 20px;
    }
  }
}

// Before Footer {
.l-region--footer_above-custom-before {
  @include breakpoint($tab) {
    height: 180px;

    @include breakpoint($wide) {
      display: inline-block;
      vertical-align: top;
      height: 220px;
      width: 275px;
    }
  }
}

// Abobe Footer
.l-region--footer-above {
  @include breakpoint($tab) {
    display: inline-block;
    vertical-align: top;
    width: 295px;

    @include breakpoint($wide) {
      width: 905px;
      font-size: 0;

      .block {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .newsletter-heading {
    &, & p {
      color: $brand-primary;
    }
  }
}

// Footer
.l-region--footer {
  @include breakpoint($tab) {
    display: inline-block;
    width: 420px;
    vertical-align: top;
    margin-top: 79px;

    @include breakpoint($tab) {
      margin-top: 44px;
      padding-left: 92px;

      @include breakpoint($desk) {
        padding-left: 0;

        @include breakpoint($wide) {
          position: absolute;
          top: 84px;
          right: 252px;
        }
      }
    }
  }
}

// After Footer
.l-region--footer-custom-after {
  position: relative;
  max-width: 300px;
  margin: 0 auto;

  @include breakpoint($tab) {
    position: static;
  }
}

/* Blocks */

// Custom footer logo block
.l-footer .logo {
  width: 265px;
  margin-bottom: 35px;

  @include breakpoint($tab) {
    width: 320px;

    @include breakpoint($desk) {
      width: 223px;

      @include breakpoint($wide) {
        width: 260px;
      }
    }
  }

  img {
    width: 100%;
    position: relative;
    left: -5px;
  }
}

// Contact information block
.block.block-contactinfo {
  width: 200px;
  margin-bottom: 45px;
  @include breakpoint($tab) {
    margin-top: 60px;

    @include breakpoint($desk) {
      margin-top: 0;
      margin-left: 30px;
      position: absolute;
      top: 0;
      left: 317px;

      @include breakpoint($wide) {
        position: static;
        margin-left: 50px;
      }
    }
  }

  .block__title {
    text-align: center;
    letter-spacing: 0.5px;

    @include breakpoint($tab) {
      text-align: left;

      @include breakpoint($desk) {
        margin-top: 0;
        letter-spacing: 0;
      }
    }

  }

  .block__content {
    padding: 0 0 0 3px;
    font-size: $font-size-small;

    h3 {
      color: $text-color;
      font-size: 20px;
      font-weight: 500;
      margin: 15px 0 0 0;

      @include breakpoint($wide) {
        margin: 22px 0 0 0;
      }

      &:before {
        @extend %font-icon;
        @extend %font-icon-phone;
        font-size: 20px;
        padding-top: 1px;
        margin-right: 10px;
        margin-top: -3px;
        vertical-align: middle;
        color: white;
        border-radius: 50%;
        background: $brand-primary;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}

// Footer menu block
.block.block--menu-menu-footer-menu {
  width: 300px;
  // height: 240px;
  margin-bottom: 40px;
  z-index: 3;

  @include breakpoint($tab) {
    width: 315px;
    // height: 180px;
    top: 0;
    right: 20px;
    position: absolute;

    @include breakpoint($wide) {
      position: static;
      float: left;
    }
  }
  ul {
    font-size: 0;
    width: 100%;
    z-index: 3;

    &.depth-1 {
      @include breakpoint($wide) {
        margin-left: 10px;
      }
    }
  }

  li.expanded {
    font-size: 20px;
    letter-spacing: -0.025em;
    line-height: 1em;
    display: inline-block;
    vertical-align: top;

    a {
      text-transform: none;
      font-weight: bold;

      &:hover {
        text-decoration: none;
        color: $brand-primary;

        @include breakpoint($desk) {
          color: #1b7e77;
        }
      }
    }

    ul {
      margin-top: 30px;
    }

    &:first-child {
      padding-left: 10px;
      width: 51%;
    }

    &.last {
      width:49%;
      padding-left: 20px;
    }
  }

  li.leaf {
    // line-height: 1em;
    font-size: 14px;
    margin-bottom: 22px;
    line-height: 16px !important;

    @include breakpoint($tab) {
      margin-bottom: 13px;
      line-height: 11px;
    }

    a {
      letter-spacing: -0.01em;
      color: $text-color;
      font-weight: 500;

      &:hover {
        color: $text-color;
        @include breakpoint($desk) {
          color: $brand-primary;
        }
      }
    }
  }
}

// Newsletter block
.block.block-newsletter {
  width: 300px;
  max-width: 100%;
  margin-bottom: 30px;
  @include breakpoint($tab) {
    // margin-top: 30px;
    @include breakpoint($desk) {
      margin-top: 130px;

      @include breakpoint($wide) {
        margin-top: 0;
        margin-left: 50px;
        width: 284px;
      }
    }
  }

  .block__content {
    @include pie-clearfix;
    h3 {
      color: $brand-primary;
      font-weight: bold;
      margin: 0;
      letter-spacing: -0.025em;
      font-size: 25px;
      position: relative;
      z-index: 1;

      @include breakpoint($tab) {
        font-size: 20px;
        letter-spacing: -0.3px;
      }
    }
    p {
      color: $brand-primary;
      margin: 12px 0 19px;
      font-size: 14px;
      font-weight: 500;
      padding-left: 1px;

      @include breakpoint($tab) {
        margin: 5px 0 14px;
        font-size: 13px;
      }
    }

    form {
      display: block;
      margin: 0 auto;
      max-width: 380px;
      position: relative;
      z-index: 0;
      z-index: 1;

      @include breakpoint($tab) {
        max-width: 100%;
      }

      input.form-text {
        border: 1px solid #d9d9d9;
        margin: 0;
        max-width: 100%;
        padding-right: 100px;
        padding-left: 10px;
        line-height: 46px;
        height: 50px;
        font-weight: 500;
        width: 100%;
        font-size: 16px;

        @include breakpoint($tab) {
          font-size: 14px;
          width: 380px;
        }
      }

      button.form-submit {
        background: none;
        border: none;
        color: $brand-primary;
        font-weight: bold;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        transition: color 0.1s;
        z-index: 0;
        font-size: 14px;
        line-height: 49px;
        outline: 0;
        white-space: nowrap;

        &:after {
          @extend %font-icon;
          @extend %font-icon-arrow-right-2;
          padding-left: 10px;
          position: relative;
          font-size: 12px;
          top: -1px;
        }

        @include breakpoint($tab) {
          font-size: 12px;
          line-height: 47px;

          &:after {
            font-size: 11px;
            top: 0;
          }
        }

        &:hover {
          color: darken($brand-primary, 8%);
        }
      }
    }
  }
}

// Social block
.block.block-social {
  text-align: center;
  margin-bottom: 40px;
  // padding-right: 130px;

  @include breakpoint($tab) {
    padding-right: 0;
    padding-left: 0;
    padding-top: 14px;
    text-align: left;
    display: inline-block;
    margin-left: -190px;

    @include breakpoint($desk) {
      padding: 0;
      position: relative;
      top: -75px;
      margin-left: 31px;

      @include breakpoint($wide) {
        margin-left: 0;
        position: static;
        padding-right: 0;
        padding-left: 112px;
        padding-top: 1px;
        margin-top: 15px;
      }
    }
  }

  .block__title {
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    @include breakpoint($tab) {
      margin-top: 45px;

      @include breakpoint($desk) {
        margin-top: 0;
      }
    }
  }

  .block__content {
    font-size: $font-size-small;

    p {
      line-height: 51px;
      margin: 2px 0 10px;

      @include breakpoint($wide) {
        margin: 6px 0 10px;
      }

      strong {
        font-weight: 500;
      }
    }

    a {
      @include hide-text;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 37px 37px;
      display: inline-block;
      height: 37px;
      opacity: 0.9;
      transition: opacity 0.2s;
      width: 37px;
      margin-right: 5px;
      position: relative;
      border-radius: 50%;

      &:before {
        color: #fff;
        text-indent: initial;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.facebook {
        background: #3b5998;

        &:before {
          @extend %font-icon;
          @extend %font-icon-facebook;
        }
        // background-image: url(../images/facebook.png);
      }

      &.twitter {
        background: #55acee;

        &:before {
          @extend %font-icon;
          @extend %font-icon-twitter;
        }
        // background-image: url(../images/twitter.png);
      }

      &.foursquare {
        // background-image: url(../images/foursquare.png);
      }

      &.instagram {
        background: #007bb5;

        &:before {
          @extend %font-icon;
          @extend %font-icon-instagram;
        }
        // background-image: url(../images/instagram.svg);
      }

      &.google {
        background: #dd4c40;
        margin-left: 4px;

        &:before {
          @extend %font-icon;
          @extend %font-icon-google-plus;
        }
        // background-image: url(../images/google.png);
      }

      &.youtube {
        background: #bb0000;

        &:before {
          @extend %font-icon;
          @extend %font-icon-youtube;
        }
        // background-image: url(../images/youtube.png);
      }

      &:hover {
        opacity: 1;
      }
    }

    .desktop-social {
      display: none;

      @include breakpoint($tab) {
        display: block;
      }
    }

    .mobile-social {
      display: block;

      @include breakpoint($tab) {
        display: none;
      }
    }
  }
}

// Custom footer grecablock
.tpl-block.greca {
  margin-bottom: 0;
  width: 250px;
  text-align: center;

  @include breakpoint($tab) {
    text-align: left;
    position: absolute;
    top: 325px;
    left: 330px;

    @include breakpoint($desk) {
      top: 220px;
      left: 20px;

      @include breakpoint($wide) {
        width: 114px;
        top: 232px;
        left: 659px;
      }
    }
  }

  img {
    max-width: 114px;
    padding-bottom: 15px;
  }
}

// Custom footer peak performance block
.tpl-block.peak-performance {
  text-align: center;
  width: 250px;

  @include breakpoint($tab) {
    text-align: left;
    position: absolute;
    top: 325px;
    left: 565px;

    @include breakpoint($desk) {
      top: 230px;
      left: 255px;

      @include breakpoint($wide) {
        // width: 114px;
        top: 225px;
        left: 910px;
      }
    }

  }

  img {
    max-width: 200px;
  }
}

// Custom footer copyright block
.tpl-block.copyright {
  font-size: 10px;
  color: #999999;
  margin: 0;
  text-align: center;
  margin-bottom: 5px;

  @include breakpoint($tab) {
    bottom: 25px;
    right: 203px;
    position: absolute;

    @include breakpoint($wide) {
      right: 98px;
      top: 133px;
    }
  }
}

// Custom footer netstudio block
.tpl-block.netstudio {
  font-size: 12px;
  text-align: center;

  @include breakpoint($tab) {
    bottom: 20px;
    right: 20px;
    position: absolute;

    @include breakpoint($wide) {
      top: 337px;
      right: 165px;
    }
  }


  a:before {
    @extend %font-icon;
    @extend %font-icon-netstudio-star;
    padding-right: 3px;
    text-decoration: none;
  }
}

// Custom footer cartoon block
.tpl-block.cartoon {
  position: absolute;
  height: 230px;
  width: 94px;
  right: 0;
  top: -130px;
  display: inline-block;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 22px;
    background: #000;
    position: absolute;
    left: 9px;
    bottom: 0px;
    border-radius: 50%;
    opacity: 0.1;
    z-index: -1;
  }

  @include breakpoint($tab) {
    display: none;

    @include breakpoint($wide) {
      display: inline-block;
      height: 260px;
      width: 107px;
      top: -80px;
    }
  }
}

// Custom footer cards block
.tpl-block.cards {
  position: relative;
  margin-bottom: 15px;
  display: inline-block;
  width: 300px;
  height: 42px;
  text-align: center;

  @include breakpoint($tab) {
    margin-bottom: 0;
    position: absolute;
    width: 126px;
    height: 82px;
    right: 445px;
    bottom: 253px;
    margin-left: -4px;
    margin-top: -4px;
    text-align: left;

    &:after {
      display: none;
    }
  }

  @include breakpoint($desk) {
    left: 192px;
    right: auto;
    bottom: 231px;
  }

  @include breakpoint($wide) {
    left: 170px;
    top: 150px;
    bottom: auto;
  }

  .cards-card {
    display: inline-block;
    width: 59px;

    + .cards-card {
      margin-left: 10px;
    }

    @include breakpoint($tab) {
      margin-left: 4px;
      margin-top: 4px;

      + .cards-card {
        margin-left: 4px;
      }
    }
  }
}

.tpl-block.ccenter {
  text-align: center;
  width: 200px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 50px;

  @include breakpoint($tab) {
    text-align: left;
    position: absolute;
    top: 156px;
    left: 20px;
  }

  @include breakpoint($desk) {
    top: 140px;
    left: 20px;
  }

  @include breakpoint($wide) {
    left: 20px;
    top: 150px;
  }

  img {
    width: 100px;
    margin-top: 5px;
  }
}

.go-top {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 999;

  a {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    background: $brand-primary;
    border-radius: 50%;
    text-decoration: none;
    box-shadow: 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);

    &:before {
      @extend %font-icon;
      @extend %font-icon-arrow-up;

      position: absolute;
      text-decoration: none;
      font-size: 9px;
      color: #fff;
      top: 16px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  .show-go-top & {
    display: block;

    @include breakpoint($desk) {
      display: none;
    }
  }
}

.footer-moto {
  font-size: 12px;
  color: $text-color;
  display: inline-block;
  margin-top: 6px;

  @include breakpoint($tab) {
    margin-top: 3px;
  }

  @include breakpoint($desk) {
    margin-top: 6px;
  }
}

