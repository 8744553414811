// Sidebar filters
.l-region--blockgroup-findastic-filters {
  width: 260px;
  background: #fff;

  @include breakpoint($desk) {
    border: 1px solid $default-border-color;
    box-shadow: 0px 15px 35px 0px rgba(0, 1, 1, 0.15);
  }
}

.block--findastic {
  @include contain-top-margin;

  .block__title {
    font-size: 12px;
    font-weight: 500;
    padding: 17px 20px 13px 10px;
    margin: 0;
    color: #333333;
    position: relative;
    cursor: pointer;
    z-index: 1;
    text-transform: uppercase;
    line-height: 20px;

    @include breakpoint($desk) {
      transition: box-shadow 0.05s ease 0.25s;
    }


    &:after {
      @extend %font-icon;

      @extend %font-icon-plus;

      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 14px;
      color: $brand-primary;

      @include breakpoint($desk) {
        right: 8px;
        font-size: 12px;
      }
    }


    &:hover {
      color: $brand-primary;
    }
  }

  &.block--findastic-search .block__title:after {
    display: none !important;
  }

  .block__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease;
    position: relative;
    border-bottom: 1px solid #e6e6e6;

    @include contain-bottom-margin;

    & ::-webkit-scrollbar {
      width: 6px;

      /* for vertical scrollbars */
      height: 6px;

      /* for horizontal scrollbars */
    }

    & ::-webkit-scrollbar-thumb {
      background-color: #666;
      border-radius: 8px;

      &:hover {
        background-color: darken(#666, 10%);
      }
    }

    & ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  &.active {
    .block__title {
      color: #fff;
      background-color: $brand-primary;

      @include breakpoint($desk) {
        transition: box-shadow 0.05s ease 0s;
        color: $brand-primary;
        box-shadow: 0 4px 0 $brand-primary;
        background-color: #fff;
      }


      &:after {
        @extend %font-icon;

        @extend %font-icon-minus;

        top: 24px;
        color: #fff;

        @include breakpoint($desk) {
          color: $brand-primary;
        }
      }
    }

    .block__content {
      max-height: 1000px;
      transition: max-height 0.3s ease 0.05s;

      // @include breakpoint($desk) {
      //   &:after {
      //     content: '';
      //     position: absolute;
      //     top: 0;
      //     width: 259px;
      //     height: 360px;
      //     background: linear-gradient(to bottom,rgba(255,255,255,0) 150px,white 100%,white 100%);
      //     pointer-events: none;
      //   }
      // }

    }
  }

  ul.findastic-facet {
    border: none;
    margin: 0;
    width: 100%;
    padding: 13px 0 20px 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
    overflow-y: auto;

    .findastic-facets-results-wrapper & {
      max-height: 150px;
    }

    @include breakpoint($desk) {
      background-color: #fff;
      border-bottom: 0;
      margin: 15px 11px 20px 10px;
      max-height: 325px;
      padding: 0;
      width: 254px;

      .findastic-facets-results-wrapper & {
        max-height: 150px;
      }
    }


    li a {
      background: none;
      color: #ccc;
      font-size: 11px;
      font-weight: 500;
      line-height: 1.3em;
      padding: 3px 0 3px 30px;
      position: relative;
      display: block;

      &.active {
        color: black;
      }

      &:before {
        content: '';
        display: inline-block;

        @extend %font-icon;

        width: 16px;
        height: 16px;
        border: 1px solid #d9d9d9;
        background: #fff;
        border-radius: 2px;
        font-size: 9px;
        padding: 3px 0 0 2px;
        position: absolute;
        left: 1px;
        top: 50%;
        transform: translatey(-50%);
        text-decoration: none;
      }

      &.active:hover {
        text-decoration: none;
        color: $brand-primary;
      }

      &.checked {
        color: $brand-primary;

        &:before {
          @extend %font-icon-tick;

          background: $brand-primary;
          color: #fff;
          border: 1px solid $brand-primary;
          font-size: 8px;
        }
      }

      label {
        color: inherit;
        cursor: pointer;
      }
    }
  }
}

.block--findastic-price {
  .block__title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    box-shadow: none !important;
    cursor: default;
    border: none;
    z-index: 0;
    margin-left: 20px;
    text-transform: uppercase;

    &:after {
      display: none;
    }
  }

  &.active {
    .block__title {
      &:after {
        display: none;
      }
    }
  }

  .block__content {
    background-color: #fff;
    max-height: 1000px;
    overflow: visible;
    border-bottom: 1px solid #e6e6e6;
    transition: max-height 0.3s ease 0s;
  }
}

.findastic-slider {
  margin: 43px 0 33px 0;

  &.findastic-search-slider {
    margin-top: 80px;
  }

  @include pie-clearfix;

  .l-sidebar & {
    margin-top: 55px;
  }

  .ui-slider-horizontal {
    background: #d9d9d9;
    border: none;
    height: 8px;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 180px;
  }

  .ui-slider-range {
    background: #64bd4a;
    background: -moz-linear-gradient(left, $brand-primary 0%, $brand-secondary 100%);
    background: -webkit-linear-gradient(left, $brand-primary 0%, $brand-secondary 100%);
    background: linear-gradient(to right, $brand-primary 0%, $brand-secondary 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$brand-primary', endColorstr='$brand-secondary',GradientType=1 );
  }

  .ui-slider-handle {
    top: -6px;
    width: 20px;
    height: 20px;
    border: 5px solid $brand-primary;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    cursor: ew-resize;
    outline: none;
    z-index: 1;
  }
}

.findastic-slider-to,
.findastic-slider-from {
  cursor: pointer;
  cursor: ew-resize;
  display: inline-block;
  float: none !important;
  font-family: "PFSquareSansPro", "Helvetica", sans-serif;
  font-size: 0;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  top: -43px;
  background: #fff;
  border: 1px solid #d9d9d9;
  transform: translateX(-50%);
  white-space: nowrap;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -7px;
    width: 0px;
    height: 0px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: none;
  }

  &:after {
    border-top: 8px solid #d9d9d9;
    bottom: -8px;
    z-index: 1;
  }

  &:before {
    border-top: 8px solid #fff;
    bottom: -7px;
    z-index: 2;
  }

  strong {
    font-size: 12px;
    font-weight: 500;
    color: $text-color;

    &:after {
      content: '\00a0\20AC';
      display: inline-block;
    }
  }
}

.findastic-load-more-wrapper {
  display: none;
}

// Filters tags
.findastic-filters {
  margin: 30px 0;
  padding: 0;
  list-style: none;
  font-size: 0;

  li {
    display: inline-block;
    font-size: $font-size-base;
    margin-right: 5px;
    margin-bottom: 5px;

    a {
      padding: 2px 30px 2px 10px;
      border-radius: 4px;
      display: block;
      background: $well-bg;
      color: $text-color;
      font-size: $font-size-small;
      text-decoration: none;
      position: relative;

      &:after {
        @extend %font-icon;

        @extend %font-icon-close;

        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 10px;
      }

      &.findastic-clear-all {
        color: #28bdb3 !important;
      }
    }
  }
}
