ul.tabs--primary {
  overflow: hidden;
  text-align: center;
  font-size: 0;
  margin: 35px 0 25px;

  @include breakpoint($desk) {
    margin: 35px 0;
  }

  @include breakpoint($mob) {
    padding-left: 0;
  }

  li {

    list-style: none;
    list-style-position: outside;
    display: inline-block;
    margin: 0 5px 10px;
    padding-left: 0;

    // @include breakpoint($mob $tab) {
    //   display: block;
    //   width: 60%;
    //   max-width: 170px;
    //   margin: 0 auto;
    //   margin-top: 10px;
    // }

    @include breakpoint($tab) {
      margin: 0 5px 10px;
    }

    @include breakpoint($desk) {
      border-bottom: solid 1px $default-border-color;
      padding: 0 5px;
      margin: 0 0 10px;
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @include breakpoint($wide) {
        margin: 0;
      }
    }

    a {
      border-bottom: 5px solid transparent;
      color: $text-color;
      display: block;
      font-size: $font-size-base;
      margin: 0 0 0 -1px;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 0.2s;
      padding: 0 10px 10px;

      @include breakpoint($desk) {
        padding: 0 25px 15px;
      }

      padding: 0 10px 12px;
      font-weight: 500;
      font-size: $font-size-h6;
      color: #666666;

      &:hover {
        color: $link-color;
        border-bottom: 5px solid $brand-primary;
      }
    }


    &.active a {
      color: $link-color;
      border-bottom: 5px solid $brand-primary;
    }
  }

  .section-blog & {
    padding-right: 150px;

    @include breakpoint($desk) {
      padding-right: 0;
    }
  }
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav-tabs>li>a {
  margin-right: 2px;
  line-height: 1.25;
  border: 1px solid transparent;
  border-radius: 3px 3px 0 0;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}

ul.nav-tabs.nav {
  margin: 0 30px 20px 30px;
  display: flex;
  width: 100%;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;

  li {
    width: 33.333%;

    &.active {
      box-shadow: -1px 4px 0 -2px #fff;
    }

    a {
      padding-right: 5px;
      padding-left: 5px;
      overflow-x: hidden;
      font-size: $font-size-base;
      border-radius: 5px 5px 0px 0px;
      font-weight: 500;
    }
  }
}
