
@font-face {
  font-family: 'icons';
  src:
    url('#{$icomoon-font-path}/icons.woff2?8d9vg9') format('woff2'),
    url('#{$icomoon-font-path}/icons.ttf?8d9vg9') format('truetype'),
    url('#{$icomoon-font-path}/icons.woff?8d9vg9') format('woff'),
    url('#{$icomoon-font-path}/icons.svg?8d9vg9#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

%font-icon {
  display: inline-block;
  speak: none;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.font-icon { @extend %font-icon; }

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

%font-icon-track { content: unicode($font-icon-track); }
%font-icon-microphone { content: unicode($font-icon-microphone); }
%font-icon-all { content: unicode($font-icon-all); }
%font-icon-arrow-down-photo { content: unicode($font-icon-arrow-down-photo); }
%font-icon-arrow-down-thick { content: unicode($font-icon-arrow-down-thick); }
%font-icon-arrow-down { content: unicode($font-icon-arrow-down); }
%font-icon-arrow-left-photo { content: unicode($font-icon-arrow-left-photo); }
%font-icon-arrow-left { content: unicode($font-icon-arrow-left); }
%font-icon-arrow-right-2 { content: unicode($font-icon-arrow-right-2); }
%font-icon-arrow-right-photo { content: unicode($font-icon-arrow-right-photo); }
%font-icon-arrow-right { content: unicode($font-icon-arrow-right); }
%font-icon-arrow-up-photo { content: unicode($font-icon-arrow-up-photo); }
%font-icon-arrow-up-thick { content: unicode($font-icon-arrow-up-thick); }
%font-icon-arrow-up { content: unicode($font-icon-arrow-up); }
%font-icon-basket-thin { content: unicode($font-icon-basket-thin); }
%font-icon-basket { content: unicode($font-icon-basket); }
%font-icon-brands { content: unicode($font-icon-brands); }
%font-icon-bubble { content: unicode($font-icon-bubble); }
%font-icon-burger { content: unicode($font-icon-burger); }
%font-icon-calculator { content: unicode($font-icon-calculator); }
%font-icon-cart-circle { content: unicode($font-icon-cart-circle); }
%font-icon-cart-stripped { content: unicode($font-icon-cart-stripped); }
%font-icon-cart { content: unicode($font-icon-cart); }
%font-icon-close { content: unicode($font-icon-close); }
%font-icon-cup { content: unicode($font-icon-cup); }
%font-icon-delete { content: unicode($font-icon-delete); }
%font-icon-delivery { content: unicode($font-icon-delivery); }
%font-icon-description { content: unicode($font-icon-description); }
%font-icon-express { content: unicode($font-icon-express); }
%font-icon-facebook { content: unicode($font-icon-facebook); }
%font-icon-google-plus { content: unicode($font-icon-google-plus); }
%font-icon-info { content: unicode($font-icon-info); }
%font-icon-instagram { content: unicode($font-icon-instagram); }
%font-icon-instructions { content: unicode($font-icon-instructions); }
%font-icon-lock { content: unicode($font-icon-lock); }
%font-icon-mail { content: unicode($font-icon-mail); }
%font-icon-minus { content: unicode($font-icon-minus); }
%font-icon-netstudio-star { content: unicode($font-icon-netstudio-star); }
%font-icon-offers { content: unicode($font-icon-offers); }
%font-icon-pager-left { content: unicode($font-icon-pager-left); }
%font-icon-pager-right { content: unicode($font-icon-pager-right); }
%font-icon-phone-fill { content: unicode($font-icon-phone-fill); }
%font-icon-phone { content: unicode($font-icon-phone); }
%font-icon-plus { content: unicode($font-icon-plus); }
%font-icon-print { content: unicode($font-icon-print); }
%font-icon-profile { content: unicode($font-icon-profile); }
%font-icon-search { content: unicode($font-icon-search); }
%font-icon-share { content: unicode($font-icon-share); }
%font-icon-star { content: unicode($font-icon-star); }
%font-icon-stores { content: unicode($font-icon-stores); }
%font-icon-tick-availability { content: unicode($font-icon-tick-availability); }
%font-icon-tick { content: unicode($font-icon-tick); }
%font-icon-transport { content: unicode($font-icon-transport); }
%font-icon-truck { content: unicode($font-icon-truck); }
%font-icon-twitter { content: unicode($font-icon-twitter); }
%font-icon-wishlist-heart { content: unicode($font-icon-wishlist-heart); }
%font-icon-youtube { content: unicode($font-icon-youtube); }
%font-icon-zoom { content: unicode($font-icon-zoom); }

.font-icon-track { @extend %font-icon-track; }
.font-icon-microphone { @extend %font-icon-microphone; }
.font-icon-all { @extend %font-icon-all; }
.font-icon-arrow-down-photo { @extend %font-icon-arrow-down-photo; }
.font-icon-arrow-down-thick { @extend %font-icon-arrow-down-thick; }
.font-icon-arrow-down { @extend %font-icon-arrow-down; }
.font-icon-arrow-left-photo { @extend %font-icon-arrow-left-photo; }
.font-icon-arrow-left { @extend %font-icon-arrow-left; }
.font-icon-arrow-right-2 { @extend %font-icon-arrow-right-2; }
.font-icon-arrow-right-photo { @extend %font-icon-arrow-right-photo; }
.font-icon-arrow-right { @extend %font-icon-arrow-right; }
.font-icon-arrow-up-photo { @extend %font-icon-arrow-up-photo; }
.font-icon-arrow-up-thick { @extend %font-icon-arrow-up-thick; }
.font-icon-arrow-up { @extend %font-icon-arrow-up; }
.font-icon-basket-thin { @extend %font-icon-basket-thin; }
.font-icon-basket { @extend %font-icon-basket; }
.font-icon-brands { @extend %font-icon-brands; }
.font-icon-bubble { @extend %font-icon-bubble; }
.font-icon-burger { @extend %font-icon-burger; }
.font-icon-calculator { @extend %font-icon-calculator; }
.font-icon-cart-circle { @extend %font-icon-cart-circle; }
.font-icon-cart-stripped { @extend %font-icon-cart-stripped; }
.font-icon-cart { @extend %font-icon-cart; }
.font-icon-close { @extend %font-icon-close; }
.font-icon-cup { @extend %font-icon-cup; }
.font-icon-delete { @extend %font-icon-delete; }
.font-icon-delivery { @extend %font-icon-delivery; }
.font-icon-description { @extend %font-icon-description; }
.font-icon-express { @extend %font-icon-express; }
.font-icon-facebook { @extend %font-icon-facebook; }
.font-icon-google-plus { @extend %font-icon-google-plus; }
.font-icon-info { @extend %font-icon-info; }
.font-icon-instagram { @extend %font-icon-instagram; }
.font-icon-instructions { @extend %font-icon-instructions; }
.font-icon-lock { @extend %font-icon-lock; }
.font-icon-mail { @extend %font-icon-mail; }
.font-icon-minus { @extend %font-icon-minus; }
.font-icon-netstudio-star { @extend %font-icon-netstudio-star; }
.font-icon-offers { @extend %font-icon-offers; }
.font-icon-pager-left { @extend %font-icon-pager-left; }
.font-icon-pager-right { @extend %font-icon-pager-right; }
.font-icon-phone-fill { @extend %font-icon-phone-fill; }
.font-icon-phone { @extend %font-icon-phone; }
.font-icon-plus { @extend %font-icon-plus; }
.font-icon-print { @extend %font-icon-print; }
.font-icon-profile { @extend %font-icon-profile; }
.font-icon-search { @extend %font-icon-search; }
.font-icon-share { @extend %font-icon-share; }
.font-icon-star { @extend %font-icon-star; }
.font-icon-stores { @extend %font-icon-stores; }
.font-icon-tick-availability { @extend %font-icon-tick-availability; }
.font-icon-tick { @extend %font-icon-tick; }
.font-icon-transport { @extend %font-icon-transport; }
.font-icon-truck { @extend %font-icon-truck; }
.font-icon-twitter { @extend %font-icon-twitter; }
.font-icon-wishlist-heart { @extend %font-icon-wishlist-heart; }
.font-icon-youtube { @extend %font-icon-youtube; }
.font-icon-zoom { @extend %font-icon-zoom; }
